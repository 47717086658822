import React, { useState, useEffect } from 'react'
import { Input, Modal, message, Button, Switch, Select, Tooltip, Dropdown, Menu, Space } from 'antd'
import MarkAsDone from '../actions/MarkAsDone'
import GenerateTemplate from '../actions/GenerateTemplate'
import ChangeOnHoldState from '../actions/ChangeOnHoldState'
import RequestUpdateModal from '../actions/RequestUpdateModal'
import { updateLinkDesign, updatePrintFilesStatus } from '../../../../services/api/OrdersServices'
import { getSupplierType } from '../../../../services/api/ProductsServices'

const OrderActions = (props) => {
    const { orderId, reload, itemNumber, onFetchOrders, namespace, type, size, fulfillmentId, designFront,
        name, orderItemId, status, onChangeStatus, tagFalseDesign, designHood, designSleeves, designBack, suppFulfill, index, isPushedAt } = props
    const [visible, setVisible] = useState(false)
    const [visibleRequestUpdate, setVisibleRequestUpdate] = useState(false)
    const [urlDesign, setUrlDesign] = useState({
        front: designFront ? designFront : '',
        back: designBack ? designBack : '',
        hood: designHood ? designHood : '',
        sleeves: designSleeves ? designSleeves : '',
    })
    const [checked, setChecked] = useState(false)
    const [Type, setType] = useState(!!type ? type : '')
    const [Size, setSize] = useState(!!size ? size : '')
    const [listTypes, setListTypes] = useState([])

    const isEditDesign = Object.keys(urlDesign).some(item => !!urlDesign[item].trim())
    const options = listTypes.map((item, index) => <Select.Option key={index} value={item}>{item}</Select.Option>)

    const resetData = () => {
        setUrlDesign({
            front: designFront ? designFront : '',
            back: designBack ? designBack : '',
            hood: designHood ? designHood : '',
            sleeves: designSleeves ? designSleeves : '',
        })
        setType(!!type ? type : '')
        setSize(!!size ? size : '')
    }
    
    useEffect(() => {
        if(visible) {
            resetData()
            // fetchType()
        } 

    }, [visible])

    useEffect(() => {
        checked && fetchType()
    }, [checked])

    const fetchType = async (value) => {
        try {
            const data = {
                type: value
            }

            const response = await getSupplierType(suppFulfill, data)

            const { data: res, success } = response
            if (success) setListTypes(res)

        } catch (error) {
            message.error(error.message)
        }
    }

    const handleCancel = () => {
        setVisible(false)
        setType(!!type ? type : '')
        setSize(!!size ? size : '')
        setChecked(false)
    }

    const handleOpen = async() => {
        // await reload()
        setVisible(true)
    }

    const toggleRequestUpdateModal = () => {
        setVisibleRequestUpdate(!visibleRequestUpdate)
    }

    const handleChangeSide = (side, value) => {
        setUrlDesign({
            ...urlDesign,
            [side]: value
        })
    }

    const handleSaveEdit = async () => {
        try {
            const payload = { ...urlDesign }
            if (checked) {
                payload.type = Type
                payload.size = Size
            }
            else {
                payload.type = ''
                payload.size = ''
            }

            const { success, message: mess } = await updateLinkDesign(payload, orderId)
            if (!success) return message.error(mess)

            message.success('Update Customize Artwork Success!')
            handleCancel()
            setType(Type)
            setSize(Size)
            Modal.confirm({
                title: 'Do you want to retry generating this item?',
				okText: 'Yes',
				cancelText: 'No',
				onOk: () => handleRetryPrintFile(),
				onCancel: () => reload(),
            })
        } catch (error) {
            message.error(error.message)
        }
    }

    const handleRetryPrintFile = async() => {
        try {
            const {success, data, message: mess} = await updatePrintFilesStatus(fulfillmentId, orderId, 'retry')
			if(!success) {
				throw new Error(mess)
			}
			message.success('Print file retry success!!!')
        } catch (error) {
            Modal.error({
				title:'Print file retry error',
				content: error.message
			})
        } finally {
            reload()
        }
    }
    const orderTemplate = `${name}_${itemNumber}`

    const changeStatus = (value, id) => {
        onChangeStatus(value, id)
        message.success('Trạng thái đã thay đổi')
    }

    const handleCheck = () => {
        setChecked(!checked)
    }

    const handleChangeType = (value) => {
        if (value === "" || value === undefined) {
            setType('')
            setListTypes([])
        } else {
            setType(value)
        }
    }

    const handleSearchType = (value) => {
        setType(value)
        setTimeout(() => fetchType(value), 500)
    }

    const onChangeSize = (e) => {
        const { value } = e.target
        setSize(value)
    }

    const _showSubMenu = () => {
        // const {productionStatus, haveStatus, cancelStatus, tags} = this.props
        // const user = getLocalData('userRole')

        return (
            <Menu style={{width: '180px'}}>
                {
                    <Menu.Item onClick={handleOpen}>Edit design</Menu.Item>
                }

                {
                    // !isPushedAt &&
                    <Menu.Item onClick={toggleRequestUpdateModal}>Request update</Menu.Item>
                }
            </Menu>
        )
    }

    return (
        <div className='OrderActions'>
            <div className={`Actions mb-3`}>
                <MarkAsDone orderId={orderId} fulfillmentId={fulfillmentId} onSuccess={changeStatus} />
                <GenerateTemplate orderId={orderId} fulfillmentId={fulfillmentId} onChangeStatus={changeStatus} />
                <ChangeOnHoldState orderId={orderId} fulfillmentId={fulfillmentId} status={status} onChangeStatus={changeStatus} />
                {/* {index === 0 && <Tooltip title='Edit design' placement='bottom'>
                    <span className={`Icon cursor-pointer Edit p-2 ${tagFalseDesign ? 'FalseDesignAction' : ''}`} onClick={handleOpen}>
                        <i className='fa fa-pencil-square-o' />
                    </span>
                </Tooltip>} */}
                <div className='MoreActions Icon'>
                    <Dropdown overlay={_showSubMenu} trigger={['click']}>
                        <span className='Icon'><i className='fa fa-ellipsis-h'/></span>
                    </Dropdown>
                </div>
                {
                    // !isPushedAt &&
                    <RequestUpdateModal
                        visible={visibleRequestUpdate}
                        toggleModal={toggleRequestUpdateModal}
                        namespace={namespace}
                        title="Request update order"
                        value='design'
                        onFetchOrders={onFetchOrders}
                        orderTemplate={orderTemplate}
                        orderItemId={orderItemId}
                        reload={reload}
                        type={type}
                    />
                }

                <Modal
                    visible={visible}
                    title="Update customized artworks"
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="ok" type="primary" onClick={handleSaveEdit} disabled={!isEditDesign}>
                            OK
                        </Button>,
                    ]}
                >
                    <div className='UpdateDesign'>
                        <div className="itemSide mb-2">
                            <div>Front</div>
                            <Input onChange={e => handleChangeSide('front', e.target.value)} value={urlDesign.front} />
                        </div>
                        <div className="itemSide mb-2">
                            <div>Back</div>
                            <Input onChange={e => handleChangeSide('back', e.target.value)} value={urlDesign.back} />
                        </div>
                        <div className="itemSide mb-2">
                            <div>Sleeves</div>
                            <Input onChange={e => handleChangeSide('sleeves', e.target.value)} value={urlDesign.sleeves} />
                        </div>
                        <div className="itemSide mb-2">
                            <div>Hood</div>
                            <Input onChange={e => handleChangeSide('hood', e.target.value)} value={urlDesign.hood} />
                        </div>
                        <label>Type & Size</label>
                        <Switch checked={checked} style={{ margin: "15px" }} onChange={handleCheck}></Switch>
                        <br />
                        {checked === true && (
                            <div className='d-flex justify-content-center'>
                                <label>Type</label>
                                <Select
                                    value={Type || []}
                                    onChange={handleChangeType}
                                    onSearch={handleSearchType}
                                    showSearch
                                    style={{
                                        width: "100%",
                                        margin: "0 10px 0 10px"
                                    }}
                                    placeholder='Search to Select'
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    notFoundContent={null}
                                    allowClear
                                >
                                    {options}
                                </Select>
                                <label>Size</label>
                                <Input
                                    value={Size || ""}
                                    onChange={onChangeSize}
                                    style={{
                                        width: "30%",
                                        margin: "0 0 0 10px"
                                    }}
                                >
                                </Input>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default OrderActions