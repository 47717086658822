import React, {Component} from 'react'
import {searchWebhookJobs, searchWebhookJobsV2} from '../../../services/api/WebhookServices'
import WebhookTable from './WebhookTable';
import FilterOptions from '../../shared/FilterOptions'
import {JOB_STATUS, JOB_TYPES} from '../helper/jobFilters'
import {Pagination} from 'antd'
import SearchGroupMultiple from '../../shared/SearchGroupMultiple';
import FilterDateCustomFormat from '../../shared/FilterDateCustomFormat'
import { getLocalData } from '../../../services/StorageServices';

class WebhookPage extends Component {
    state = {
        webhook: null,
        webhookItems: [],
        page: 1,
        limit: 20,
        filter: {
            status: 'pending',
            type: '',
            orderNumber: [],
            created:{},
            send_time:{}
        },
        pages: 1,
        total: 0,
        error: '',
        loading: false,
    }

    componentDidMount() {
        this._fetchWebhook()
    }

    _fetchWebhook = async () => {
        this.setState({
            loading: true,
        })

        const {page: pageNo, limit, filter} = this.state
        const {created, send_time, ...others} = filter
        try {

            const payload = {
                limit: limit,
                page: pageNo,
                ...others,
            }

            if(created && created.from && created.to){
                payload.created = created
            }

            if(send_time && send_time.from && send_time.to){
                payload.send_time = send_time
            }

            // const {data, success, message} = await searchWebhookJobs({
            // const {data, success, message} = await searchWebhookJobsV2({
            //     limit: limit,
            //     page: pageNo,
            //     ...others,
            // })

            const {data, success, message} = await searchWebhookJobsV2(payload)

            if (!success) {
                throw new Error(message)
            }
            const {pages, page, total} = data
            return this.setState({
                webhookItems: data.jobs,
                pages: pages,
                page: page,
                total: total,
                error: '',
                loading: false,
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    _handleChangeFilterState = (paramFilter) => {
        this.setState(({filter}) => {
            return {
                filter: {
                    ...filter,
                    ...paramFilter,
                },
                page: 1,
            }
        }, this._fetchWebhook)
    }

    _handlePageChange = (current) => {
        this.setState({page: current}, this._fetchWebhook)
    }

    _handleShowSizeChange = (current, size) => {
        this.setState({
            limit: size,
            page: 1,
        }, this._fetchWebhook)
    }

    render() {
        const {limit, total, webhookItems, page, filter, loading} = this.state
        const {status, type, orderNumber, created, send_time} = filter
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        
        return (
            <div className="WebhookPage">
                <div className="d-flex justify-content-between align-items-end mb-3">
                    <h1 className="PageTitle mb-0 mr-auto">Webhook Jobs</h1>
                </div>

                <div className="SectionInner">
                    <div className="row align-items-end mb-3">
                        <div className="col-xl-4 mb-3 mb-xl-0">
                            <FilterOptions
                                options={JOB_STATUS}
                                selectedKey={status}
                                defaultOption='pending'
                                field='status'
                                heading='Status:'
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>

                        <div className="col-xl-8">
                            <FilterOptions
                                options={JOB_TYPES}
                                selectedKey={type}
                                field='type'
                                defaultOption=''
                                heading='Type:'
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>
                    </div>

                    <div className='d-md-flex justify-content-between align-items-end mb-3'>
                        <div className='filter-box d-md-flex'>
                            <SearchGroupMultiple
                                options={[{title: 'Number', key: 'orderNumber', value: orderNumber}]}
                                onSearch={this._handleChangeFilterState}
                            />

                            <FilterDateCustomFormat
                                heading='Created at:'
                                field='created'
                                value={created}
                                classDrop={'Left646'}
                                dateFormat='YYYY-MM-DD'
                                onDatesChange={this._handleChangeFilterState}
                            />

                            <FilterDateCustomFormat
                                heading='Send time:'
                                field='send_time'
                                value={send_time}
                                classDrop={'Left646'}
                                dateFormat='YYYY-MM-DD'
                                onDatesChange={this._handleChangeFilterState}
                            />
                        </div>

                        <div className='TotalItems ml-md-3 font-weight-bold'>
                            {total} items
                        </div>
                    </div>

                    <WebhookTable
                        webhookItems={webhookItems}
                        loading={loading}
                        filterStatus={status}
                        onFetchWebhook={this._fetchWebhook}
                        permissionsData={permissionsData}
                    />

                    <Pagination
                        current={page}
                        total={total}
                        pageSize={limit}
                        className='text-right'
                        showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                        onChange={this._handlePageChange}
                        onShowSizeChange={this._handleShowSizeChange}
                    />
                </div>
            </div>
        )
    }
}

export default WebhookPage
