import React, { useState } from 'react'
import { markIssuesPartnerRefunded } from '../../../../services/api/PackageIssuesServices'
import { Modal, Progress } from "antd"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

function BulkMarkIssuePartnerRefunded(props) {
    const { handleUnShowModal, listSelecteds, isOpen, listData, refresh, onChangeLoad } = { ...props }
    const [loading, setLoading] = useState(false)
    const [listProcessing, setListProcessing] = useState(listData.filter(item => listSelecteds.includes(item._id)))
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])

    const _hanldeFetchMark = async (id) => {
        setLoading(true)
        try {

            const { success, message } = await markIssuesPartnerRefunded(id)

            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }

    }


    const _handleMark = async () => {

        setListSuccess([])
        setListError([])
        setListProcessing(listProcessing.map(item => {
            if (item.success) delete item.success
            if (item.error) delete item.error
            return item
        }))

        let processed = []


        try {
            await Promise.all(listProcessing.map(async item => {
                const response = await _hanldeFetchMark(item._id)

                const { success, message } = response

                if (!success) return processed.push({ ...item, error: message, processing: false })
                return processed.push({ ...item, success: true, processing: false })

            }))

            let newListSuccess = []
            let newListError = []

            processed.map(process => {
                if (process.success === true) {
                    return newListSuccess.push(process)
                }

                if (process.error.length > 0) {
                    return newListError.push(process)
                }
            })

            setListSuccess(newListSuccess)
            setListError(newListError)
            refresh()
            onChangeLoad()
            return setListProcessing(processed)

        } catch (e) {
            return { success: false, message: e.message }
        } finally {
            setLoading(false)
        }
    }

    const _handleClick = e => {
        e.preventDefault()
        _handleMark()
    }

    return (
        <div className=' BulkMarkIssueRefunded'>
            <Modal title="Mark issues as partner refunded"
                visible={isOpen}
                onOk={_handleClick}
                onCancel={() => handleUnShowModal('partner_refunded')}
                okText="Mark"
                confirmLoading={loading}
                width={800}
            >
                <h6>List processing <span>{listSelecteds.length}</span> issues</h6>
                {
                    (listSuccess.length > 0 || listError.length > 0) &&
                    <div className="Results d-flex align-items-center mb-3">
                        <div className="ResultSuccess mr-2">
                            Success: <span className='text-success font-weight-bold'>{listSuccess.length}</span> issues
                        </div>
                        <div className="ResultError">
                            Error: <span className='text-danger font-weight-bold'>{listError.length}</span> issues
                        </div>
                    </div>
                }

                <div className="ListProcessing" style={{ maxHeight: 500, overflow: 'auto' }}>
                    <ul>
                        {
                            !!listProcessing && listProcessing.length > 0 && listProcessing.map(item => (
                                <li key={item._id} className='mb-3'>
                                    <div className="d-flex justify-content-between">
                                        <span className='mr-2 font-weight-bold'>{item.reference_number}</span>
                                        {
                                            item.success &&
                                            <span className="Success text-success mr-3"><CheckCircleOutlined /> Success</span>
                                        }
                                    </div>
                                    {
                                        !!item.error &&
                                        <div className="d-flex justify-content-between">
                                            <div className="Error text-danger"><ExclamationCircleOutlined /> {item.error}</div>
                                            <span className="Error text-danger mr-3"><ExclamationCircleOutlined /> Error</span>
                                        </div>
                                    }
                                    <Progress
                                        percent={100}
                                        size="small"
                                        status={`${(item.processing === false && item.success === true) ? 'success' : ((item.processing === false && !!item.error) ? 'exception' : 'active')}`}
                                        showInfo={false}
                                    />
                                </li>
                            )
                            )
                        }
                    </ul>
                </div>
            </Modal>
        </div>
    )
}

export default BulkMarkIssuePartnerRefunded