import React, {Component} from 'react'
import {Checkbox, Dropdown, Menu} from 'antd'
import PropTypes from 'prop-types'
import {DownOutlined} from '@ant-design/icons'
import MenuBulkJobActions from './MenuBulkJobActions'

class WebhookTableHead extends Component {
    _handleChange = e => {
        this.props.onToggleSelectAll(e.target.checked)
    }

    _renderMenu = () => {
        const {isChecked, onToggleSelectAll, ...rest} = this.props

        return <MenuBulkJobActions {...rest}/>
    }

    _menu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
                    1st menu item
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
                    2nd menu item
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
                    3rd menu item
                </a>
            </Menu.Item>
        </Menu>
    )

    render() {
        const {permissionsData}= this.props
        return (
            <thead className="WebhookTableHead thead-light">
            <tr>
                {permissionsData.includes('ffm_webhook_job_update') &&
                    <th className='WebhookSelection'>
                        <Dropdown.Button overlay={this._renderMenu} trigger={['click']} icon={<DownOutlined />}
                                        placement='bottomLeft'>
                            <Checkbox checked={this.props.isChecked} onChange={this._handleChange}/>
                        </Dropdown.Button>
                    </th>
                }
                <th className='OrderNumber'>Order</th>
                <th className='WebhookAddress'>Date</th>
                <th className='WebhookStatus'>Status</th>
                <th className='WebhookType'>Type</th>
                <th className='WebhookTotalSent'>Total Sent</th>
                <th className='WebhookSendTime'>Send Time</th>
                <th className='WebhookMessage'>Error</th>
                {permissionsData.includes('ffm_webhook_job_update') &&
                    <th className='WebhookActions'>Actions</th>
                }
            </tr>
            </thead>
        )
    }
}

WebhookTableHead.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    selectedItems: PropTypes.array.isRequired,
    onToggleSelectAll: PropTypes.func.isRequired,
    onFetchWebhook: PropTypes.func.isRequired,
    filterStatus: PropTypes.string.isRequired,
}

export default WebhookTableHead
