import React, {useState, useEffect} from 'react'
import {message, Pagination, Spin} from "antd"
import OrderProfitTable from './OrderProfitTable'
import OrderProfitFilter from './Filter/OrderProfitFilter'
import {getListOrderProfit} from '../../../services/api/SuppliersServices'
import {getListSuppliersV2} from '../../../services/api/SuppliersServices'
import ExportOrderProfit from './Actions/ExportOrderProfit'
import PackageDetail from './PackageDetail'
import UseDebounce from '../helpers/UseDebounce'

function OrderProfitPage() {
    const [listOrderProfit, setListOrderProfit] = useState('')
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [supplier, setSupplier] = useState('')
    const [listSupplier, setListSupplier] = useState([])
    const [orderId, setOrderId] = useState('')
    const [createdStart, setCreatedStart] = useState('')
    const [createdEnd, setCreatedEnd] = useState('')
    const [pages, setPages] = useState(1)
    const [limit, setLimit] = useState(20)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [showExportModal, setShowExportModal] = useState('')
    const [showPackageDetailModal, setShowPackageDetailModal] = useState(false)
    const [packageId, setPackageId] = useState('')
    const [isArchive, setIsArchive] = useState('')

    const getListSupplier = async () => {
        try {
            const {data, success, message} = await getListSuppliersV2()
            if (success) {
                setListSupplier(data.suppliers)
            } else {
                console.log(message)
            }
        } catch (error) {

        }
    }

    const fetchOrderProfit = async () => {
        setLoading(true)
        setError('')
        const payload = {
            is_archive: isArchive,
            page,
            limit,
        }
        if (supplier) payload.supplier = supplier
        if (orderId) payload.order_id = orderId
        if (createdStart && createdEnd) payload.fulfilled_at = {
            from: createdStart,
            to: createdEnd,
        }

        try {
            const {data, success, message: mess} = await getListOrderProfit({...payload})
            if (success) {
                const {pages, total, orders} = data
                setListOrderProfit(orders)
                setPages(pages)
                setTotal(total)
            } else {
                message.error(mess)
            }

            setLoading(false)
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const debounceSearch = UseDebounce(orderId, 500)

    const toggleExportModal = (type) => () => {
        setShowExportModal(type)
    }

    const togglePackageDetailModal = (value) => {
        if (!showPackageDetailModal) {
            setPackageId(value)
        } else {
            setPackageId('')
        }
        setShowPackageDetailModal(!showPackageDetailModal)
    }

    const handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const handleChangeSupplier = (value) => {
        setSupplier(value)
        setPage(1)
    }

    const handleArchive = (value) => {
        setIsArchive(value)
        setPage(1)
    }

    const handleChangeOrderId = (e) => {
        const value = e.target.value
        setOrderId(value)
        setPage(1)
    }

    const handleChangeCreated = (momentArray, dateStringArray) => {
        setCreatedStart(dateStringArray[0])
        setCreatedEnd(dateStringArray[1])
        setPage(1)
    }


    useEffect(() => {
        fetchOrderProfit()
        getListSupplier()
    }, [supplier, debounceSearch, createdStart, createdEnd, isArchive, limit, page])

    return (
        <div className="OrderProfitPage">
            <div className="PageHeading d-flex align-items-center justify-content-between border-bottom">
                <h1 className="PageTitle mt-2 mb-0">Order Profit</h1>
                <div className="RightTop">
                    <button
                        onClick={toggleExportModal('orders')}
                        disabled={loading}
                        className="btn btn-primary"
                        style={{background: '#477BF6'}}
                    >Export Orders
                    </button>
                    <button
                        onClick={toggleExportModal('packages')}
                        disabled={loading}
                        className="btn btn-primary ml-2"
                        style={{background: '#477BF6'}}
                    >Export Packages
                    </button>
                    <ExportOrderProfit
                        showExportModal={showExportModal}
                        toggleExportModal={toggleExportModal}
                        supplier={supplier}
                        orderId={orderId}
                        createdStart={createdStart}
                        createdEnd={createdEnd}
                        isArchive={isArchive}
                    />
                </div>
            </div>
            <div className="SectionInner mt-4">
                {
                    <div className="filter align-items-center mt-2">
                        <OrderProfitFilter
                            supplier={supplier}
                            listSupplier={listSupplier}
                            orderId={orderId}
                            createdStart={createdStart}
                            createdEnd={createdEnd}
                            loading={loading}
                            handleChangeSupplier={handleChangeSupplier}
                            handleChangeOrderId={handleChangeOrderId}
                            handleChangeCreated={handleChangeCreated}
                            handleArchive={handleArchive}
                            isArchive={isArchive}
                        />
                    </div>
                }
                {
                    <div>
                        <div className="Total mb-3 d-flex justify-content-end">
                            {
                                total > 0 && <div>
                                    <span>{total}</span> items
                                </div>
                            }
                        </div>
                        <Spin spinning={loading} tip="Data Loading...">
                            <OrderProfitTable
                                listOrderProfit={listOrderProfit || []}
                                loading={loading}
                                reload={fetchOrderProfit}
                                togglePackageDetailModal={togglePackageDetailModal}
                            />
                            <PackageDetail
                                showPackageDetailModal={showPackageDetailModal}
                                togglePackageDetailModal={togglePackageDetailModal}
                                packageId={packageId}
                            />
                            <Pagination
                                pageSizeOptions={['10', '20', '50', '100']}
                                onChange={handlePageChange}
                                className="text-right"
                                pageSize={limit}
                                showSizeChanger
                                current={page}
                                total={total}
                            />
                        </Spin>
                    </div>
                }
            </div>
        </div>
    )
}

export default OrderProfitPage