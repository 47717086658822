import React from 'react'

const TagRequestUpdate = (props) => {
    const { orderId, index, tag } = props

    return (
        <span key={index} className={`badge badge-pill badge-primary ${tag}`} id={"Tooltip-Tag-" + orderId}>
            {tag === 'waiting-update-design' ? 'request-design' : tag === 'waiting-update-address' ? 'request-address' : tag === 'force-updated-design' ? 'force-updated' : tag}
        </span>
    )
}

export default TagRequestUpdate