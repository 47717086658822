import React, {Component} from 'react'
import PropTypes from "prop-types"
import classNames from 'classnames'
import SelectStoreMode from "./SelectStoreMode"
import {FormGroup, Input, Label} from "reactstrap"
import {Select} from "antd";
import {listCurrencies} from "../../../statics/storeModes"
import NumberFormat from "react-number-format"

const {Option} = Select

class ProductEditor extends Component {
    _handleChangeData = (field, value) => {
        const { handleChangeData } = this.props

        if (typeof handleChangeData === 'function') {
            if (field === "production_time_max" || field === "production_time_min" || field === "seller_production_time_max") {
                const newTime = parseInt(value)
                handleChangeData(field, newTime)
            } else if (field === "fast_production_time") {
                const newTime = parseFloat(value)
                handleChangeData(field, newTime)
            } else {
                handleChangeData(field, value)
            }
        }
}

    _onAddNew = () => {
        const {handSubmit} = this.props

        if (typeof handSubmit === 'function') {
            console.log('click')
            handSubmit()
        }
    }

    render() {
        const {
            validated,
            title,
            loading,
            description,
            sku_prefix,
            type,
            storeMode,
            error,
            productLabel,
            currency,
            production_time_min,
            production_time_max,
            seller_production_time_max,
            fast_production_time,
        } = this.props

        return (
            <div className="SectionInner ProductInfo">
                <div className="form-group">
                    <label htmlFor="productLine">Product Label <span className="text-danger">*</span></label>
                    <div className='d-flex align-items-center'>
                        <FormGroup check className='mr-3'>
                            <Label check>
                                <Input type="radio" checked={productLabel === 'product'} name="product"
                                       onChange={({target: {name}}) => this._handleChangeData('productLabel', name)}/> Product
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" checked={productLabel === 'branding'} name="branding"
                                       onChange={({target: {name}}) => this._handleChangeData('productLabel', name)}/> Branding
                            </Label>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Title <span className="text-danger">*</span></label>
                        <input type="text" value={title || ''}
                               className={classNames('form-control', (title.trim() === '' && validated) && 'input-error')}
                               onChange={({target: {value}}) => this._handleChangeData('title', value)}/>
                        {
                            (title.trim() === '' && validated) &&
                            <div className="text-danger mt-1 small">Title is require</div>
                        }
                    </div>
                    <div
                        className={classNames('form-group col-md-6 selectSupplier', ((currency === '' || currency === undefined) && validated) && 'select-error')}>
                        <label>Currency <span className="text-danger">*</span></label>
                        <Select size={'large'} defaultValue={currency}
                                onChange={(value) => this._handleChangeData('currency', value)}>
                            <Option value=''>--- Select Currency ---</Option>
                            {
                                listCurrencies.map((item, index) => {
                                        return <Option key={index} value={item.value}>{item.name}</Option>
                                    }
                                )
                            }
                        </Select>
                        {
                            (!!currency && currency.trim() === '' && validated) &&
                            <div className="text-danger mt-1 small">Currency is require</div>
                        }
                    </div>
                </div>
                <div className="row">
                    {
                        productLabel === 'product' ?
                            <div className="form-group col-md-6">
                                <label>Type</label>
                                <input type="text" className="form-control" value={type || ''}
                                       onChange={({target: {value}}) => this._handleChangeData('type', value)}/>
                            </div> :
                            <div className="form-group col-md-6">
                                <label>Type</label>
                                <Input
                                    type="select"
                                    // disabled
                                    className="form-control"
                                    value={type}
                                    onChange={({target: {value}}) => this._handleChangeData('type', value)}
                                >
                                    <option value='THANK_CARD'>THANK_CARD</option>
                                    <option value='BRAND_KIT'>BRAND_KIT</option>
                                </Input>
                            </div>
                    }
                    <SelectStoreMode onChange={this._handleChangeData} storeMode={storeMode}/>
                </div>

                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Production time min <span className="text-danger">*</span></label>
                        <NumberFormat
                            className={
                                classNames('form-control InputPrice', 
                                ((!production_time_min || production_time_min === 0) && validated) && 'input-error')
                            }
                            onChange={({target: {value}}) => this._handleChangeData("production_time_min",value)}
                            value={production_time_min || 0}
                            allowNegative={false}
                            suffix={production_time_min > 1 ? " days" : " day"}
                            // isAllowed={limitInputValue}
                        />
                        {
                            ((!production_time_min || production_time_min === 0) && validated) &&
                            <div className="text-danger mt-1 small">Production time min is require</div>
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label>Production time max <span className="text-danger">*</span></label>
                        <NumberFormat
                            className={
                                classNames('form-control InputPrice', 
                                ((!production_time_max || production_time_max === 0) && validated) && 'input-error')
                            }
                            onChange={({target: {value}}) => this._handleChangeData("production_time_max",value)}
                            value={production_time_max || 0}
                            allowNegative={false}
                            suffix={production_time_max > 1 ? " days" : " day"}
                            // isAllowed={limitInputValue}
                        />
                        {
                            ((!production_time_max || production_time_max === 0) && validated) &&
                            <div className="text-danger mt-1 small">Production time max is require</div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-md-6">
                        <label>SKU Prefix <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            value={sku_prefix || ''}
                            className={classNames('form-control', (sku_prefix.trim() === '' && validated) && 'input-error')}
                            onChange={({target: {value}}) => this._handleChangeData('sku_prefix', value)}
                        />
                        {
                            (sku_prefix.trim() === '' && validated) &&
                            <div className="text-danger mt-1 small">SKU Prefix is require</div>
                        }
                    </div>
                    <div className="form-group col-md-6">
                            <label>Production time max for Seller</label>
                            <NumberFormat
                                className={
                                    classNames('form-control InputPrice', 
                                    ((!seller_production_time_max || seller_production_time_max === 0) && validated) && 'input-error')
                                }
                                onChange={({target: {value}}) => this._handleChangeData('seller_production_time_max', value)}
                                value={seller_production_time_max || 0}
                                allowNegative={false}
                                suffix={seller_production_time_max > 1 ? " days" : " day"}
                            />
                            {
                            ((!seller_production_time_max || seller_production_time_max === 0) && validated) &&
                            <div className="text-danger mt-1 small">Production time max for seller is require</div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-md-6">
                            <label>Fast production time</label>
                            <NumberFormat
                                className={classNames('form-control InputPrice')}
                                onChange={({target: {value}}) => this._handleChangeData('fast_production_time', value)}
                                value={fast_production_time || 0}
                                allowNegative={false}
                                suffix={fast_production_time >= 2 ? " days" : " day"}
                            />
                    </div>
                    <div className="form-group col-md-6"></div>
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea
                        className="form-control"
                        rows="5"
                        value={description || ''}
                        onChange={({target: {value}}) => this._handleChangeData('description', value)}
                    />
                </div>
                <button
                    onClick={this._onAddNew}
                    disabled={loading}
                    className="btn btn-primary"
                >
                    {loading ? 'Loading...' : 'Submit'}
                </button>

                {
                    error && <div className="text-danger mt-3">{error}</div>
                }
            </div>
        )
    }
}

ProductEditor.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    sku_prefix: PropTypes.string,
    type: PropTypes.string,
    supplier: PropTypes.string,
    loading: PropTypes.bool,
    validated: PropTypes.bool,
    handSubmit: PropTypes.func.isRequired,
    handleChangeData: PropTypes.func.isRequired,
    storeMode: PropTypes.array,
    error: PropTypes.string,
    currency: PropTypes.string,
    isClone: PropTypes.bool
}

export default ProductEditor
