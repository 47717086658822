import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin, Tooltip, message } from "antd"
import { getListVariantForSupplier, updateProductVariantsSupplier, getStoreVariantForSupplier, removeStoreVariantForSupplier } from "../../../../services/api/ProductsServices"
import isMatchVariant from "../../../product/helpers/isMatchVariant"
import ProductVariantTable from "./ProductVariantTable"
import ProductVariantsFilter from "./ProductVariantsFilter"
import ProductVariantsAction from "./ProductVariantsAction"
import ProductVariantSupplier from "./ProductVariantSupplier"
import broker from "../ProductBroker"
import { QuestionCircleFilled } from '@ant-design/icons'
import { Link } from 'react-router-dom'

ProductVariants.propTypes = {
    suppliers: PropTypes.array,
    productId: PropTypes.string,
    listAtt: PropTypes.array,
    fetchProductDetail: PropTypes.func.isRequired
}

function ProductVariants(props) {
    const { suppliers, productId, listAtt, fetchProductDetail, productDetail, disableAll } = props
    const [selectedSupplier, setSelectedSupplier] = useState((!!suppliers && suppliers.length > 0) ? suppliers[0]._id : '')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [listVariants, setListVariants] = useState([])
    const [filter, setFilter] = useState({})
    const [originalListVariants, setOriginalListVariants] = useState([])
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [errorUpdate, setErrorUpdate] = useState('')
    const [duplicateVariants, setDuplicateVariants] = useState({})
    const [storeName, setStoreName] = useState([])

    const _handleChangeSupplierSelected = (id) => {
        setSelectedSupplier(id)
    }

    const _handleChangePrice = (price) => {
        const updateVariants = listVariants.map(variant => {
            const isMatch = isMatchVariant(filter, variant)
            if (!isMatch) return variant

            return {
                ...variant,
                base_cost: price,
            }
        })

        setListVariants(updateVariants)
    }

    const _handleChangePriceStore = (price, idStore) => {
        const updateVariants = listVariants.map(variant => {
            const isMatch = isMatchVariant(filter, variant)
            if (!isMatch) return variant

            return {
                ...variant,
                store: variant.store.map(store => {
                    if (idStore.includes(store.store._id)) {
                        return {
                            ...store,
                            price
                        }
                    }
                    return store
                }),
            }
        })

        setListVariants(updateVariants)
    }

    const _deleteStoreVariant = async (storeId) => {
        setLoading(true)
        setError('')
        let processed = []
        try {
            if (typeof storeId === 'object' && storeId.length > 1) {
                await Promise.all(storeId.map(async i => {
                    const response = await removeStoreVariantForSupplier(productId, i)
                    const { success, message } = response
                    if (!success) return processed.push({ id: i, error: message, processing: false })
                    return processed.push({ id: i, success: true, processing: false })
                }))
                let newListSuccess = []
                let newListError = []
                processed.map(process => {
                    if (process.success === true) {
                        return newListSuccess.push(process)
                    }

                    if (process.error.length > 0) {
                        return newListError.push(process)
                    }
                })
                if (newListSuccess.length !== (storeId.length)) {
                    throw new Error(`Delete error!`)
                }
                message.success('Delete store successfully.')
                _fetchProductVariants()
                _fetchStoreVariants()
            } else {
                const response = await removeStoreVariantForSupplier(productId, storeId)

                const { success, message: error } = response

                if (!success) {

                    return message.error(error)
                }

                message.success('Delete store successfully.')
                _fetchProductVariants()
                _fetchStoreVariants()
            }
        } catch (e) {
            setLoading(false)
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    const _onDiscardChangeState = () => {
        setListVariants(originalListVariants)
    }

    const _handleChangeFilter = (data) => {
        const updatedFilter = {
            ...filter,
            ...data
        }

        for (let key in updatedFilter) {
            if (!updatedFilter[key]) delete updatedFilter[key]
        }

        setFilter(updatedFilter)
    }

    const _handlePaste = (e, field, variantIndex, idStore) => {

        const copyText = e.clipboardData.getData("Text")
        const parseText = copyText.split(/[\r?\n]+/)
        const dimensionField = ["height", "length", "weight", "weight_by_v", "additional_weight", "width"]
        let count = 0
        const newVariants = listVariants.map((variant, index) => {
            if (index < variantIndex || count >= parseText.length) return variant
            if (isMatchVariant(filter, variant)) {
                if (dimensionField.includes(field)) {
                    return {
                        ...variant,
                        dimension: { ...variant.dimension, [field]: parseText[count++] }
                    }
                }
                if (field === 'store') {
                    return {
                        ...variant,
                        store: variant.store.map(i => {
                            if (i.store._id === idStore) {
                                return {
                                    ...i,
                                    price: parseText[count++]
                                }
                            }
                            return i
                        })
                    }
                }
                return {
                    ...variant,
                    [field]: parseText[count++]
                }
            }
            return { ...variant }
        })
        setListVariants(newVariants)
    }

    const _handleListVariantsState = (index, field, value, isDeleted = false) => {
        if (field === 'additional_price') {
            value = parseFloat(value)
        }

        if (isDeleted) {
            const newListVariants = listVariants.filter((_, idx) => idx !== index)

            return setListVariants(newListVariants)
        }

        const newListVariants = listVariants.map((variant, idx) => {
            if (idx === index) {
                return {
                    ...variant,
                    [field]: value,
                }
            }

            return variant
        })

        return setListVariants(newListVariants)
    }

    const _handleChangeVariantDimension = (index, field, value) => {
        let listVariantUpdated = []

        listVariants.map((variant, idx) => {
            if (idx === index) {
                return listVariantUpdated.push({
                    ...variant,
                    dimension: {
                        ...variant.dimension,
                        [field]: value
                    }
                })
            }

            return listVariantUpdated.push(variant)
        })
        setListVariants(listVariantUpdated)
    }

    const _handleChangeAllVariant = (variants) => {
        setListVariants(variants)
    }

    const _onUpdateVariants = async () => {
        try {
            setLoadingUpdate(true)
            setErrorUpdate('')

            const newListVariant = listVariants.map(item => {
                return {
                    ...item,
                    base_cost: parseFloat(item.base_cost),
                    dimension: {
                        ...item.dimension,
                        width: !!item.dimension ? parseFloat(item.dimension.width) : 0,
                        height: !!item.dimension ? parseFloat(item.dimension.height) : 0,
                        length: !!item.dimension ? parseFloat(item.dimension.length) : 0,
                        weight: !!item.dimension ? parseFloat(item.dimension.weight) : 0,
                        additional_weight: !!item.dimension ? parseFloat(item.dimension.additional_weight) : 0
                    },
                    prices: Array.isArray(item.store) ? [...item.store] : []
                }
            })

            const { success, message } = await updateProductVariantsSupplier(productId, selectedSupplier, newListVariant)

            if (!success) {
                setLoadingUpdate(false)

                return setErrorUpdate(message)
            }

            setLoadingUpdate(false)
            setErrorUpdate('')
            _fetchProductVariants()
        } catch (e) {
            setLoadingUpdate(false)
            setErrorUpdate(e.message)
        }
    }

    const _fetchProductVariants = async () => {
        setLoading(true)
        setError('')

        try {
            const response = await getListVariantForSupplier(productId, selectedSupplier)

            const { success, message: error, data } = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            setListVariants(data)
            setOriginalListVariants(data)
            setDuplicateVariants(findDuplicateVariant(data))
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const findDuplicateVariant = (variants) => {
        const groups = variants.reduce((m, v) => {
            delete v.isDuplicated
            const { partner_sku } = v

            if (!partner_sku) {
                return m
            }

            const vSku = `${partner_sku}`

            return {
                ...m,
                [vSku]: m[vSku] ? [...m[vSku], v] : [v]
            }
        }, {})

        return Object.keys(groups).reduce((m, partnerSku) => {
            return {
                ...m,
                ...(
                    groups[partnerSku].length > 1
                        ? {
                            [partnerSku]: groups[partnerSku].map(variant => {
                                variant.isDuplicated = groups[partnerSku].length
                                variant.groupDuplicated = groups[partnerSku][0].sku

                                return variant
                            })
                        }
                        : {}
                )
            }
        }, {})
    }

    const moveTo = (variant) => (e) => {
        e.preventDefault()

        document.getElementById(`product-variant-${variant._id}`).focus()
    }

    const highlightRow = (sku) => (e) => {
        const rows = document.getElementById('VariantsContainer').getElementsByTagName('tr')
        const rows2 = document.getElementsByClassName('VariantsHighlight')

            ;[...new Set(rows), ...new Set(rows2)].forEach((row) => {
                const group = row.getAttribute('data-group')
                let className = row.getAttribute('class')
                className = className ? `${className}`.replace('highlight', '') : ''

                if (sku && group === sku.groupDuplicated) {
                    className = `${className} highlight`
                }

                row.setAttribute('class', className.replace(/\s+/g, ' ').trim())
            })
    }

    const displayMessageDuplicateVariants = () => {
        const partnerSkus = Object.keys(duplicateVariants)

        if (!partnerSkus.length) {
            return ''
        }

        return <div className="WarningVariantsDuplicate rounded mb-4">
            <p className="text-danger mb-0">There are some variants has duplicated partner SKU</p>
            {
                partnerSkus.map(sku => {
                    return <div className="VariantsHighlight" key={sku}
                        data-group={duplicateVariants[sku][0].groupDuplicated}>
                        <p onMouseOver={highlightRow(duplicateVariants[sku][0])} onMouseLeave={highlightRow()}
                            className="mt-1" title="Click to edit partner sku for this variant">
                            <span>Partner SKU {sku}:</span>
                            {
                                duplicateVariants[sku].map((v, i) => {
                                    return <a href="" onClick={moveTo(v)} className="MoveToDuplicateSKU mr-1"
                                        key={v.sku}>{v.sku}</a>
                                })
                            }
                        </p>
                    </div>
                })
            }
        </div>
    }

    const _fetchStoreVariants = async () => {
        setLoading(true)
        setError('')

        try {
            const response = await getStoreVariantForSupplier(productId, selectedSupplier)

            const { success, message: error, data } = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            setStoreName(data)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        if (!!suppliers && suppliers.length > 0) {
            setSelectedSupplier(suppliers[0]._id)
        }
    }, [suppliers])

    useEffect(() => {
        if (selectedSupplier !== '') {
            _fetchProductVariants()
            _fetchStoreVariants()
        }

        const subscribe = broker.subscribe('GET_LIST_VARIANTS', _fetchProductVariants)

        return () => {
            broker.unsubscribe(subscribe)
        }
    }, [selectedSupplier])

    useEffect(() => {
        setDuplicateVariants(findDuplicateVariant(listVariants))
    }, [listVariants])

    const _renderPriceForSleeves = (name, price) => {
        return (
            <span className='ml-2'>
                (
                <Tooltip title={_renderTitleTooltip(name)} style={{ fontSize: '16px' }}>
                    ${price}
                </Tooltip>
                )
            </span>
        )
    }

    const _renderTitleTooltip = (name) => {
        return (
            <span style={{ fontSize: '15px' }}>
                This supplier has been set up the price for sleeve design, <Link style={{ fontWeight: 'bold', color: 'green' }} to={{ pathname: "/a/suppliers", search: `?supplier=${name}` }}>click here</Link> to see the detail!
            </span>
        )
    }
    return (
        <div className="ProductVariants mt-3">
            <div className="SectionInner">
                <div className="TopPageHeading">
                    <h3 className="SectionHeading mb-3">Variants</h3>
                    <ProductVariantSupplier
                        suppliers={suppliers}
                        productId={productId}
                        selectedSupplier={selectedSupplier}
                        fetchProductDetail={fetchProductDetail}
                        fetchListVariants={_fetchProductVariants}
                        fetchStoreVariants={_fetchStoreVariants}
                        disableAll={disableAll}
                        listStore={storeName}
                    />
                </div>
                {displayMessageDuplicateVariants()}

                {
                    !!error && error.length > 0 && <div className="text-danger">{error}</div>
                }

                <div className="VariantTab">
                    <div className="VariantTabHeader">
                        <ul>
                            {
                                !!suppliers && suppliers.length > 0 && suppliers.map((item, index) => (
                                    <li
                                        key={`filter_${index}`}
                                        className={`${item._id === selectedSupplier ? 'Active' : ''}`}
                                        onClick={() => _handleChangeSupplierSelected(item._id)}
                                    >
                                        {item.name}{item.need_charge_have_sleeves ? _renderPriceForSleeves(item.name, item.need_charge_have_sleeves) : ''}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="VariantTabBody">
                        <Spin spinning={loading} tip='Getting list variant from supplier...'>
                            <ProductVariantsFilter
                                listVariants={listVariants}
                                filter={filter}
                                attributes={listAtt}
                                onChangeFilter={_handleChangeFilter}
                            />

                            <ProductVariantTable
                                filter={filter}
                                storeName={storeName}
                                productId={productId}
                                attributes={listAtt}
                                suppliers={suppliers}
                                listVariants={listVariants}
                                deleteStoreVariant={_deleteStoreVariant}
                                duplicateVariants={duplicateVariants}
                                onChangePrice={_handleChangePrice}
                                onChangePriceStore={_handleChangePriceStore}
                                onChangeVariant={_handleListVariantsState}
                                onChangeAllVariant={_handleChangeAllVariant}
                                highlightRow={highlightRow}
                                onChangeVariantDimension={_handleChangeVariantDimension}
                                handlePaste={_handlePaste}
                                fetchListVariants={_fetchProductVariants}
                                fetchStoreVariants={_fetchStoreVariants}
                                productDetail={productDetail}
                                disableAll={disableAll}
                            />

                            <ProductVariantsAction
                                loading={loadingUpdate}
                                error={errorUpdate}
                                onUpdateVariants={_onUpdateVariants}
                                onDiscardChangeState={_onDiscardChangeState}
                                disableAll={disableAll}
                            />
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductVariants
