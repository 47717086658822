import { v4 as uuidV4 } from 'uuid'

/**
 * @property {String} _id
 * @property {Object[]} items
 * @property {String} supplier
 * @property {String} shippingCarrier
 * @property {Boolean} skipFulfill
 * @property {String} status
 */
class FFMPackage {
    constructor(options) {
        let { order } = { ...options }
        let { order_type } = { ...order }
        let slug = !!options.shippingPlan && options.shippingPlan.slug
        let shippingPlan = !!slug && ['standard','express','fast'].includes(slug) ? slug : 'standard'
        let shippingCarrierDefault = {
            'YC': 'Standard shipping',
            'DBUS': 'Standard shipping UPS',
            'DPCN': 'Standard Delivery',
            'SPUS': shippingPlan === 'fast' ? 'priority' : shippingPlan === 'express' ? 'express' : 'standard',
            'PYUS': shippingPlan === 'fast' ? 'priority' : shippingPlan,
            'FSUS': order_type === 'TIKTOK' ? 'Tiktok2Days' : shippingPlan === 'fast' ? 'Priority' : 'FirstClass',
            'MDUS': 'standard',
            'CC': 'standard',
        }
        let shippingCarrier = (!!shippingCarrierDefault[options.supplierSku] ? shippingCarrierDefault[options.supplierSku] : '')
        this._id = uuidV4()
        this.items = options.items || []
        this.supplier = options.supplier || options.supplierId || null
        this.shippingCarrier = options.shippingCarrier || shippingCarrier
        this.shippingPlan = options.shippingPlan
        this.isRequireShippingCarrier = false
        this.isAllowFulfill = true
        this.status = ''
        this.message = ''
    }

    isEmpty() {
        return !this.items.length
    }

    isFulfilled() {
        return this.status === 'fulfilled'
    }

    isProcessing() {
        return this.status === 'processing'
    }

    isError() {
        return this.status === 'error'
    }

    allowMoveItems() {
        return !this.isFulfilled() && !this.isProcessing()
    }

    allowDelete() {
        return this.isEmpty()
    }

    setStatus(status, message, errors =  []) {
        this.status = status
        this.message = message
        this.errors = errors
    }

    setRequireShippingCarrier(status) {
        this.isRequireShippingCarrier = status
    }

    allowFulfill() {
        return !this.isEmpty() &&
            !this.isFulfilled() &&
            !this.isError() &&
            this.supplier &&
            this.isAllowFulfill &&
            (!this.isRequireShippingCarrier || this.shippingCarrier)
    }

    /**
     * @param {Boolean} status
     */
    setAllowFulfill(status) {
        this.isAllowFulfill = status
    }

    /**
     * @param {String} supplier
     */
    setSupplier(supplier) {
        this.supplier = supplier
    }

    /**
     * @param {String} shippingCarrier
     */
    setShippingCarrier(shippingCarrier) {
        this.shippingCarrier = shippingCarrier
    }

    addItem(item) {
        let isMergeQuantity = false
        this.items.map(val => {
            if (val._id === item._id) {
                val.quantity += item.quantity
                isMergeQuantity = true
            }

            return val
        })

        if (!isMergeQuantity) {
            this.items.push(item)
        }
    }

    removeItem(itemId) {
        this.items = this.items.filter(item => item._id !== itemId)
    }

    splitQuantity(item, newQuantity)
    {
        let index = this.items.findIndex(val => val._id === item._id)
        if (index !== -1) {
            this.items[index] = Object.assign({}, item)
            this.items[index].quantity = newQuantity
            item.quantity = item.quantity - newQuantity

            if (item.quantity) {
                return item
            }
        }
    }
}

export default FFMPackage
