import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ProductVariantsHead from "./ProductVariantsHead"
import ProductVariantsRow from "./ProductVariantsRow"

ProductVariantTable.propTypes = {
    filter: PropTypes.object,
    attributes: PropTypes.array,
    listVariants: PropTypes.array,
    onChangePrice: PropTypes.func.isRequired,
    onChangeVariant: PropTypes.func.isRequired,
    onChangeAllVariant: PropTypes.func.isRequired,
    onChangeVariantDimension: PropTypes.func.isRequired,
    highlightRow: PropTypes.func.isRequired,
    selectedSupplier: PropTypes.string,
    onChangePriceStore : PropTypes.func.isRequired,
    deleteStoreVariant : PropTypes.func.isRequired,
    storeName: PropTypes.array,
    // onChangeAllAvailableTiktok: PropTypes.func.isRequired
}

function ProductVariantTable(props) {
    const [isShowMoreTable, setIsShowMoreTable] = useState(false)
    const {
        filter,
        onChangePrice,
        attributes,
        listVariants,
        duplicateVariants,
        onChangeVariant,
        onChangeAllVariant,
        onChangeVariantDimension,
        highlightRow,
        handlePaste,
        productId,
        fetchListVariants,
        fetchStoreVariants,
        productDetail,
        onChangePriceStore,
        storeName,
        deleteStoreVariant,
        disableAll,
    } = props

    const toggle = () => {
        setIsShowMoreTable(!isShowMoreTable)
    }

    return (
        <div className="ProductVariantsTable mb-4">
            <table className="table table-hover">
                <ProductVariantsHead
                    variants={listVariants}
                    attributes={attributes}
                    onChangePrice={onChangePrice}
                    onChangePriceStore={onChangePriceStore}
                    onChangeAllVariant={onChangeAllVariant}
                    filter={filter}
                    storeName={storeName}
                    deleteStoreVariant={deleteStoreVariant}
                    toggle={toggle}
                    isShowMoreTable={isShowMoreTable}
                    disableAll={disableAll}
                    fetchListVariants={fetchListVariants}
                    fetchStoreVariants={fetchStoreVariants}
                />
                <tbody id="VariantsContainer">
                {
                    !!listVariants && listVariants.length > 0 && listVariants.map((item, idx) => {
                        return <ProductVariantsRow
                            key={item._id}
                            filter={filter}
                            variants={listVariants}
                            variant={item}
                            duplicateVariants={duplicateVariants ? duplicateVariants[item.partner_sku] : false}
                            attributes={attributes}
                            variantIndex={idx}
                            totalVariants={listVariants.length || 0}
                            onChangeVariant={onChangeVariant}
                            onChangeAllVariant={onChangeAllVariant}
                            onChangeVariantDimension={onChangeVariantDimension}
                            highlightRow={highlightRow}
                            handlePaste={handlePaste}
                            productId={productId}
                            fetchListVariants={fetchListVariants}
                            productDetail={productDetail}
                            storeName={storeName}
                            isShowMoreTable={isShowMoreTable}
                            disableAll={disableAll}
                        />
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

export default ProductVariantTable
