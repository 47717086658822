import React, {Component} from 'react'
import {Link} from "react-router-dom"
import PropTypes from 'prop-types'

class OrderCode extends Component {
    render() {
        const {order, legacy, currentPage} = this.props
        const {id: code, namespace, _id: orderId} = order

        return (
            <td className="OrderCode">
                <Link to={{
                    pathname: `/a/orders/${orderId}`,
                    state: {fromOrdersPage: currentPage}
                }}>{code}</Link>
                <div>
                    <code>{namespace}</code>
                    {
                        legacy && <span className="text-monospace"><span className="mx-1">/</span>legacy</span>
                    }
                </div>
            </td>
        )
    }
}

OrderCode.propTypes = {
    order: PropTypes.object.isRequired,
    legacy: PropTypes.bool,
    currentPage: PropTypes.number.isRequired,
}

export default OrderCode
