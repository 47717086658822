import React, {Component} from 'react'
import classNames from 'classnames'
import {Link} from "react-router-dom"
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"
import {rejectFulfillOrder} from "../../../services/api/OrdersServices"
import broker from '../OrderBroker'
import { getLocalData } from '../../../services/StorageServices'
import { parseJwt } from '../../../helpers/parseToken'

class OrderActions extends Component {
    state = {
        showModal: false,
        error: "",
    }

    _handleToggleModal = e => {
        e.preventDefault()

        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleClickReject = e => {
        e.preventDefault()

        this._handleRejectOrderItem()
    }

    _handleRejectOrderItem = async () => {
        try {
            const {orderId} = this.props
            const {success, message} = await rejectFulfillOrder(orderId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            broker.publish("ORDER_REJECTED")

            this.setState({
                showModal: false,
                error: "",
            })

            this.props._fetchOrderItems()
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {fulfillable, orderId, fulfillmentStatus, tags, orderItems, orderType, customShippingCost} = this.props
        const {showModal, error} = this.state

        const disabledSplit = orderItems.filter(item => !!item.outOfStock)
        const noCustomBaseCost = orderItems.filter(item=> !item.productLineVariant.custom_base_cost )
        const noCustomCost = orderType==="FBA" && (!customShippingCost || noCustomBaseCost.length > 0)? true : false

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className="OrderActions text-right mt-5">
                {
                    permissionsData.hasOwnProperty('ffm_order_detail_reject_order') && fulfillmentStatus !== "partial" &&
                    <button className="btn btn-danger" onClick={this._handleToggleModal}>Reject
                        order</button>
                }

                {
                    permissionsData.hasOwnProperty('ffm_order_detail_split_packages') && (fulfillmentStatus !== 'onhold' && fulfillmentStatus !== 'cancelled') && (!tags || !tags.includes('waiting-update-design')) &&
                    <Link
                        to={`/a/orders/${orderId}/fulfillment`}
                        className={classNames("btn btn-primary ml-3", {disabled: !fulfillable || disabledSplit.length || noCustomCost})}
                    >
                        Split packages
                    </Link>
                }

                <Modal isOpen={showModal} toggle={this._handleToggleModal}>
                    <ModalBody>
                        Are you sure want to reject all order items? This action cannot be reversed.
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <button className="btn border" onClick={this._handleToggleModal}>Cancel</button>
                            <button className="btn btn-danger ml-2" onClick={this._handleClickReject}>Reject order
                            </button>
                        </div>

                        {
                            !!error && <div className="text-danger mt-3">{error}</div>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default OrderActions
