import React, {Component} from 'react'
import PropTypes from 'prop-types'

class BrandingItem extends Component {
    render() {
        const {item} = this.props
        const {product: {title: productTitle, preview, url}, quantity, productLineVariant, sku, variant: {sku: skuVariant}} = item
        const vSku = skuVariant || sku || ''

        return (
            <div className='BrandingItem OrderItem p-4'>
                <div className="row">
                    <div className="col-2">
                        <img src={preview} alt="Product thumbnail"/>
                    </div>

                    <div className="col-6">
                        <a href={url} target="_blank" rel="noopener noreferrer"
                           className="d-block mb-2">{productTitle}</a>

                        {
                            !!vSku &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">SKU:</span>
                                <span className='text-break'>{vSku}</span>
                            </div>
                        }

                        <div className="d-flex">
                            <span className="Label font-weight-500">Quantity:</span>
                            <span>{quantity}</span>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className='CustomVariant'>
                            <span className="Label mr-1">Type:</span>
                            <span
                                className='text-break'>{productLineVariant.hasOwnProperty('product') && productLineVariant.product.type}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BrandingItem.propTypes = {
    item: PropTypes.object
}

export default BrandingItem