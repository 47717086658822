import React, {useEffect, useState} from 'react'
import {Spin} from "antd"
import {Link} from "react-router-dom"
import {getMultransUSReport} from "../../../../services/api/OrdersServices"

function ReportPreTransit() {
    const [staticsPreTransitReport, setStaticsPreTransitReport] = useState({})
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    async function _fetchPreTransitReport() {
        try {
            setLoading(true)
            setError('')

            const response = await getMultransUSReport()

            const {success, data, message} = response

            if (!success) {
                setLoading(false)
                return setError(message)
            }

            setStaticsPreTransitReport(data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchPreTransitReport()
    }, [])

    return (
        <div className="ReportUSPreTransitDelay">
            <h3>Pre_transit (Multrans US) Delay Report</h3>
            <div className="text-danger">{error}</div>
            <Spin spinning={loading} tip='Getting statics...'>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th rowSpan={2}>Carrier</th>
                        <th className="text-center bg_1" colSpan={4}>Remaining days</th>
                        <th className="text-center bg_2" colSpan={4}>Pre_transit delay days</th>
                    </tr>
                    <tr>
                        <th className="text-right">>= 3 days</th>
                        <th className="text-right">2 days</th>
                        <th className="text-right">1 day</th>
                        <th className="text-right">Total</th>
                        <th className="text-right">1 day</th>
                        <th className="text-right">2 days</th>
                        <th className="text-right">>= 3 days</th>
                        <th className="text-right">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        staticsPreTransitReport.hasOwnProperty('carrier_alias') && <tr>
                            <td>{staticsPreTransitReport.carrier_alias}</td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=-3&delayed_days=-4&delayed_days=-5&delayed_days=-6`}>
                                    {staticsPreTransitReport.over_time_data['-3']}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=-2`}>
                                    {staticsPreTransitReport.over_time_data['-2']}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=-1`}>
                                    {staticsPreTransitReport.over_time_data['-1']}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=-1&delayed_days=-2&delayed_days=-3&delayed_days=-4&delayed_days=-5&delayed_days=-6`}>
                                    {staticsPreTransitReport.over_time_data['totalRemaining']}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=1`}>
                                    {staticsPreTransitReport.over_time_data['1']}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=2`}>
                                    {staticsPreTransitReport.over_time_data['2']}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=3&delayed_days=4&delayed_days=5&delayed_days=6`}>
                                    {staticsPreTransitReport.over_time_data['3']}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link target={'_blank'}
                                      to={`/a/orders-supplier?package_status=completed,pre_transit&suppliers=${staticsPreTransitReport.supplier.id}&delay=multrans_us_pre_transit_delay&delayed_days=1&delayed_days=2&delayed_days=3&delayed_days=4&delayed_days=5&delayed_days=6`}>
                                    {staticsPreTransitReport.over_time_data['totalDelay']}
                                </Link>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </Spin>
        </div>
    )
}

export default ReportPreTransit
