import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ListArtworkType} from '../../../../statics/artworkType'
import {ReactComponent as Danger} from '../../../../statics/assets/images/danger.svg'
import ArtworkImages from './ArtworkImages'
import RequestUpdateModal from '../request-update/RequestUpdateModal'
import {Modal, Input, Switch, Select, message, Tag, Tooltip} from 'antd'
import {getOrderItem, updateCustomizedArtworks} from '../../../../services/api/OrdersServices'
import {getSupplierType} from '../../../../services/api/ProductsServices'
import {getLocalData} from '../../../../services/StorageServices'
import { ReactComponent as IconRetry } from "../../helper/retry-cutting.svg"
import {parseJwt} from '../../../../helpers/parseToken'

const {Option} = Select

class OrderItemGroupArtworkItem extends Component {
    state = {
        background: 'black',
        isModalVisible: false,
        checked: false,
        customizedArtworks: {},
        listType: [],
        Type: '',
        size: '',
        selectedComplexEmbroidery: {}
    }

    timer = null


    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            customizedArtworks: {},
            Type: '', size: '', checked: false
        })
    }

    handleCheck = () => {
        this.setState({checked: !this.state.checked})
    }

    handleChangeType = (value) => {
        if (value === "" || value === undefined) return this.setState({listType: [], Type: ""})

        this.setState({Type: value})
    }

    fetchType = async (value) => {
        const {supplierCurrent} = this.props
        try {
            const data = {
                type: value
            }

            const response = await getSupplierType(supplierCurrent, data)

            const {data: res, success} = response
            if (success) return this.setState({listType: res})

        } catch (error) {
            message.error(error.message)
        }

        this.setState({listType: []})

    }

    handleSearchType = (value) => {
        this.setState({Type: value}, () => {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => this.fetchType(value), 500)
        })
    }

    handleGetOrderItem = async () => {
        const {orderId, orderItemId} = this.props
        const response = await getOrderItem(orderId, orderItemId)
        const {data} = response
        return data
    }

    onchangeCustomizedArtworks = (e) => {
        const {value, name} = e.target
        this.setState({
            ...this.state,
            customizedArtworks: {
                ...this.state.customizedArtworks,
                [name]: value
            }
        })
    }
    onChangeSize = (e) => {
        const {value, name} = e.target
        this.setState({...this.state.size, [name]: value})
    }

    handleOkClick = async () => {
        const {fulfillment_item_id} = this.props
        const {customizedArtworks, checked, Type, size} = this.state
        const data = {
            artworks: customizedArtworks,
        }
        if (checked) {
            data.type = Type
            data.size = size
        } else {
            data.type = ''
            data.size = ''
        }
        try {
            const response = await updateCustomizedArtworks(fulfillment_item_id, data)
            const {success} = response
            if (success) {
                this.setState({isModalVisible: false, customizedArtworks: {}, size: ''})
                return message.success("Update Customize Artwork Success!")
            }
        } catch (error) {
            message.error(error.message)
        }
    }

    _getTypeArtwork = (artwork) => {
        if (!!artwork.artworks) {
            const {level} = {...artwork.artworks[0]}

            return ListArtworkType.find(item => item.value === level)
        }
    }

    _checkValidate = (artwork, validates) => {
        if (artwork.hasOwnProperty('side_group')) {
            if (validates && validates.length > 0) {
                return validates.find(item => item.side_group === artwork.side_group)
            } else {
                return false
            }
        } else {
            return validates[0]
        }
    }

    _handleChangeColor = color => {
        this.setState({
            background: color
        })
    }

    _showStatusArtwork = (status, message) => {
        if (!status) return

        switch (status) {
            case "prepare":
                return <Tag style={{borderRadius: '8px'}} color="#3ba2b9">Prepare</Tag>
            case "cutting":
                return <Tag style={{borderRadius: '8px'}} color="#2196F3">Cutting</Tag>
            case "cut_failed":
                return (
                    <Tooltip title={message}>
                        <Tag style={{borderRadius: '8px', cursor: 'pointer'}} color="#F43838">Cut failed</Tag>
                    </Tooltip>
                )
            case "retry_cutting":
                return <Tag style={{borderRadius: '8px'}} color="#F5B40B">Retry cutting</Tag>
            default:
                return
        }
    }

    _handleRetryCutArtwork = () => {
        const {onRetryPrintFile} = this.props
        onRetryPrintFile("retry_cutting")
    }

    render() {
        const {
            artwork,
            validates,
            typeDisplay,
            piecesPerPack,
            packageName,
            orderItemId,
            fulfillmentStatus,
            tags,
            namespace,
            language,
            languageError,
            ff_processed_artworks_pf,
            fulfillment_item_id,
            selectedComplexEmbroidery,
            supplierCurrent,
            originFulfillItem
        } = this.props
        const {background, customizedArtworks, listType, Type, size} = this.state
        const {isModalVisible, checked} = this.state
        const {artworkQuantity = 0, artworks = [], processed_artworks_pf, message : messageCutArtwork = '', status = ''} = Object.assign({}, artwork)
        const validate = artwork && this._checkValidate(artwork, validates)
        const type = this._getTypeArtwork(artwork) || ''
        const options = listType.map((item) => <Option value={item}>{item}</Option>)
        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className={`OrderItemGroupArtworkFirst  ${typeDisplay === 'more' ? 'More' : ''} `}>
                {
                    typeDisplay !== 'more' && <div className="TypeArtwork">
                        {type.label}
                        {
                            artwork && artwork.hasOwnProperty('pack') && artwork.pack > 0 &&
                            <span className='ml-3'>{piecesPerPack}{'/' + artwork.pack}</span>
                        }
                    </div>
                }

                {
                    (validate && validate.validDimension === false) &&
                    <div className="ValidateDimension">
                        <><Danger/><span>{validate.errorValidate}</span></>
                    </div>
                }

                <div className="Actions">
                    {!!artworkQuantity && <div className="QuantityArtwork">
                        Qty: <span>{artworkQuantity}{(artworks[0] && artworks[0].pack) ? '/' + artworks[0].pack : ''}</span>
                    </div>}

                    <div className="ActionBackground">
                        <button
                            className={background === 'black' ? 'Active' : ''}
                            onClick={() => this._handleChangeColor('black')}
                        >
                            Black
                        </button>
                        <button
                            className={background === 'white' ? 'Active' : ''}
                            onClick={() => this._handleChangeColor('white')}
                        >
                            White
                        </button>
                    </div>

                    {permissionsData.hasOwnProperty('ffm_request_update_design') && fulfillmentStatus === 'unfulfilled' &&
                    (!tags || !tags.includes('waiting-update-design')) &&
                    <RequestUpdateModal
                        loading={false}
                        title="Request update order"
                        value="design"
                        packageName={packageName}
                        orderItemId={orderItemId}
                        namespace={namespace}
                        language={language}
                        languageError={languageError}
                    />
                    }
                </div>

                <div
                    className={['ArtworkListFirst', this.props.showEmbroiderySurchargeButton && 'EmbroiderySurchargeContainer'].filter(Boolean).join(' ')}>
                    <ul className="ArtworkListNew row ">
                        {artwork && artwork.artworks &&
                        artwork.artworks.length > 0 &&
                        artwork.artworks.map((item, index) => {
                            return <li className='col-6' key={item._id}>
                                <ArtworkImages embroiderySurcharge={item}
                                               selectedComplexEmbroidery={this.props.selectedComplexEmbroidery}
                                               selectComplexEmbroidery={this.props.selectComplexEmbroidery}
                                               showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                                               isAllowEmbroiderySurcharge={this.props.isAllowEmbroiderySurcharge}
                                               background={background}
                                               correctDimensions={
                                                   validate ? validate.correctDimensions : []
                                               }
                                               preview={item.preview}
                                               resize={item.resize}
                                               side={item.side}
                                               width={item.width}
                                               height={item.height}
                                               artworkID={item._id}
                                               orderItemId={orderItemId}
                                               ff_processed_artworks_pf={ff_processed_artworks_pf}
                                               processed_artworks_pf={processed_artworks_pf}
                                               fulfillment_item_id={fulfillment_item_id}
                                               fulfillItem={this.props.fulfillItem}
                                               originFulfillItem={originFulfillItem}
                                />
                            </li>
                        })
                        }
                    </ul>
                </div>
                <Modal
                    title={'Update customized artworks'}
                    visible={isModalVisible}
                    onCancel={this.handleCancel}
                    onOk={this.handleOkClick}
                >
                    <label>Design Front</label>
                    <Input
                        value={
                            !!customizedArtworks ? customizedArtworks.front : ''
                        }
                        name='front'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Design Back</label>
                    <Input
                        value={!!customizedArtworks ? customizedArtworks.back : ''}
                        name='back'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Design Sleeve</label>
                    <Input
                        value={!!customizedArtworks ? customizedArtworks.sleeves : ''}
                        name='sleeves'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Design Hood</label>
                    <Input
                        value={!!customizedArtworks ? customizedArtworks.hood : ''}
                        name='hood'
                        onChange={this.onchangeCustomizedArtworks}
                    ></Input>
                    <label>Type & Size</label>
                    <Switch checked={checked} style={{margin: "15px"}} onChange={this.handleCheck}></Switch>
                    <br/>
                    {checked === true && (
                        <div className='d-flex justify-content-center'>
                            <label>Type</label>
                            <Select
                                value={Type || []}
                                onChange={this.handleChangeType}
                                onSearch={this.handleSearchType}
                                showSearch
                                style={{
                                    width: "100%",
                                    margin: "0 10px 0 10px"
                                }}
                                placeholder='Search to Select'
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                notFoundContent={null}
                                allowClear
                            >
                                {options}
                            </Select>
                            <label>Size</label>
                            <Input
                                value={size || ""}
                                name='size'
                                onChange={this.onChangeSize}
                                style={{
                                    width: "30%",
                                    margin: "0 0 0 10px"
                                }}
                            >
                            </Input>
                        </div>
                    )}
                </Modal>
                {
                    !!status &&
                    <div className='status-artwork d-flex align-items-center'>
                        {this._showStatusArtwork(status, messageCutArtwork)}
                        {
                            status === "cut_failed" &&
                            <Tooltip title="Retry cut artwork">
                                <IconRetry
                                    width={20}
                                    height={20}
                                    className='cursor-pointer'
                                    onClick={() => this._handleRetryCutArtwork()}
                                />
                            </Tooltip>
                        }
                    </div>
                }
            </div>
        )
    }
}

OrderItemGroupArtworkItem.propTypes = {
    namespace: PropTypes.string,
    language: PropTypes.string,
    languageError: PropTypes.string,
    artwork: PropTypes.object,
    validates: PropTypes.array,
    orderItemId: PropTypes.string,
    packageName: PropTypes.string,
    typeDisplay: PropTypes.string,
    piecesPerPack: PropTypes.number,
    fulfillmentStatus: PropTypes.string,
    fulfillment_item_id: PropTypes.string
}

export default OrderItemGroupArtworkItem
