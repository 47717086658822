import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Checkbox, Modal, Select} from "antd"
import FormRefund from "./FormRefund"
import FormCharge from "./FormCharge"
import formatCurrency from "../../../../../helpers/common/formatCurrency"
import {getReasonTags} from "../../../../../services/api/OrdersServices"
import SelectShippingMethod from '../../../../order-fulfillment/components/packages/SelectShippingMethod'

const {Option} = Select
class ChangeSupplierModal extends Component {
    state = {
        pushPackage: this.props.pushPackage,
        disabled: false,
        showForm: true,
        valuePrice: '',
        reasonTag: '',
        reasonSeller: '',
        supporter: '',
        tags: []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pushPackage !== this.props.pushPackage) {
            this.setState({
                pushPackage: this.props.pushPackage
            })
        }
    }

    componentDidMount() {
        const {fulfilledPackage, supplierSelected, preference} = this.props
        const {supplier} = fulfilledPackage

        if ((preference !== undefined && preference.hasOwnProperty('thank_card') && (supplier.sku_prefix === '1C' || supplierSelected.sku_prefix === '1C')) || supplierSelected.sku_prefix === 'YC') {
            this.setState({
                pushPackage: false,
                disabled: true
            }, () => {
                const {onChangePush} = this.props

                if (typeof onChangePush === 'function') {
                    onChangePush(this.state.pushPackage)
                }
            })
        }

        this._fetchReasonTags()
    }

    _convertString = (string) => {
        const {fulfilledPackage} = this.props
        const {currency: rCurrency} = fulfilledPackage
        let result = string

        if (typeof string === 'string') {
            if (rCurrency === 'USD') {
                result = string.replaceAll(/[,]/g, '')
            } else {
                result = string.replaceAll(/[.]/g, '')
            }
        }

        return result
    }

    _resetValidate = (name, value) => {
        if (!!value) {
            this.setState({
                [name]: ''
            })
        }
    }

    _onChangeShowForm = (value) => {
        this.setState({
            showForm: value
        })
    }

    _handleChangePushPackage = (e) => {
        const value = e.target.checked

        this.setState({
            pushPackage: value
        })

        const {onChangePush} = this.props

        if (typeof onChangePush === 'function') {
            onChangePush(value)
        }
    }

    _fetchReasonTags = async () => {
        try {
            const {success, data} = await getReasonTags()

            if (!success) return

            this.setState({
                tags: data
            })
        } catch (e) {
            this.setState({
                validateTag: e.message
            })
        }
    }

    _checkValue = () => {
        const {tags} = this.state
        const {valuePrice, reasonTag, reasonSeller, supporter} = this.props
        const reasonFFM = tags.length > 0 && tags.find(item => item._id === reasonTag)

        if (valuePrice <= 0) {
            this.setState({
                valuePrice: 'This value must be larger 0'
            })
        }

        if (!reasonTag) {
            this.setState({
                reasonTag: 'This field can\'t be blank'
            })
        }

        if (!reasonSeller) {
            this.setState({
                reasonSeller: 'This field can\'t be blank'
            })
        }

        if (!!reasonFFM && reasonFFM.name === 'ffm' && !supporter) {
            this.setState({
                supporter: 'This field can\'t be blank'
            })
        }
    }

    _submitForm = async () => {
        await this._checkValue()

        const {valuePrice, reasonTag, reasonSeller, supporter, showForm} = this.state
        const {onChangeAndPush, supplierSelected, chargeOrRefund, onChargeOrRefund} = this.props

        if (!chargeOrRefund) {
            onChangeAndPush(supplierSelected._id)
        } else if (!valuePrice && !reasonTag && !reasonSeller && !supporter) {
            onChangeAndPush(supplierSelected._id)
        } else if (!showForm) {
            await onChargeOrRefund(false)
            await onChangeAndPush(supplierSelected._id)
        }
    }

    _detectButtonValue = () => {
        const {chargeOrRefund, fulfilledPackage, valuePrice} = this.props
        const {currency: rCurrency} = fulfilledPackage
        const prices = this._convertString(valuePrice)

        if (chargeOrRefund === 'charge') {
            return `& Request Charge ${formatCurrency(rCurrency, prices)}`
        }

        return chargeOrRefund === 'refund' ? `& Request Refund ${formatCurrency(rCurrency, prices)}` : ''
    }

    render() {
        const {
            visible,
            orderId,
            fulfilledPackage,
            loading,
            error,
            supplierSelected,
            errorChangeSupplier,
            onClose,
            invoiceRefund,
            chargeOrRefund,
            onChangeValueForm,
            order,
            shippingCarrier,
            onChangeShippingCarrier,
        } = this.props

        const {
            pushPackage,
            disabled,
            showForm,
            valuePrice: price,
            reasonTag,
            reasonSeller,
            supporter,
            tags
        } = this.state

        const refundReasonTag = !!tags && [
            ...tags.filter(item => item.type === 'refund').map(item=> ({
                description: item.description,
                name: item.name,
                _id: item._id
            }))]
        const chargeReasonTag = !!tags && [
            ...tags.filter(item => item.type === 'charge').map(item=> ({
                description: item.description,
                name: item.name,
                _id: item._id
        }))]

        return (
            <Modal
                className={`ModalChangeSupplier ${showForm ? 'HaveForm' : ''}`}
                width={837}
                visible={visible}
                title={`Package ${fulfilledPackage.name}`}
                onCancel={onClose}
                footer={[
                    <div key={1}
                         className="d-flex justify-content-between align-items-center">
                        <Button key="back" onClick={onClose}>
                            Cancel
                        </Button>
                        {
                            error ? (
                                <Button loading={loading} key="submit" type="primary"
                                        onClick={onClose}>
                                    Ok
                                </Button>
                            ) : (
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center mr-3'>
                                        <Checkbox onChange={this._handleChangePushPackage}
                                                  checked={pushPackage} disabled={disabled}/>
                                        <span className="ml-2">Push package?</span>
                                    </div>

                                    <Button
                                        loading={loading}
                                        key="submit"
                                        type="primary"
                                        onClick={this._submitForm}
                                    >
                                        Change Supplier{' '}
                                        {showForm && this._detectButtonValue()}
                                    </Button>
                                </div>

                            )
                        }
                    </div>
                ]}
            >
                {
                    (chargeOrRefund === 'charge' && showForm) ?
                        <FormCharge
                            showForm={showForm}
                            currency={fulfilledPackage.currency}
                            invoiceRefund={invoiceRefund}
                            orderId={orderId}
                            onChangeValueForm={onChangeValueForm}
                            validatePrice={price}
                            validateReasonTag={reasonTag}
                            validateReasonSeller={reasonSeller}
                            validateSupporter={supporter}
                            tags={chargeReasonTag}
                            resetValidate={this._resetValidate}
                            onChangeShowForm={this._onChangeShowForm}
                        /> :
                        (chargeOrRefund === 'refund' && showForm) &&
                        <FormRefund
                            showForm={showForm}
                            currency={fulfilledPackage.currency}
                            invoiceRefund={invoiceRefund}
                            orderId={orderId}
                            onChangeValueForm={onChangeValueForm}
                            validateReasonTag={reasonTag}
                            validateReasonSeller={reasonSeller}
                            validateSupporter={supporter}
                            tags={refundReasonTag}
                            resetValidate={this._resetValidate}
                            onChangeShowForm={this._onChangeShowForm}
                        />
                }
                <div>
                    Do you want to <strong>change</strong> supplier and <strong>push</strong> this package
                    to <strong>{supplierSelected.name}</strong> ? <br/>
                    If you <strong>don't</strong> want to push this package, please uncheck checkbox <strong>Push
                    package</strong>.
                </div>

                {["YC", "DBUS", "DPCN", "SPUS", "FSUS", "PYUS", "MDUS", "CC"].includes(supplierSelected.sku_prefix) && <div className="mt-3">
                    <span className='font-weight-bold mr-2'>Shipping method</span>
                    <SelectShippingMethod
                        value={shippingCarrier}
                        onChange={onChangeShippingCarrier}
                        placeholder="Select shipping method"
                        className="split-package__supplier-select split-package__supplier-is-carrier"
                        style={{ width: 255 }}
                        skuPrefix={supplierSelected.sku_prefix}
                    />
                </div>}

                {
                    errorChangeSupplier && <div className="mt-3 text-danger">{errorChangeSupplier}</div>
                }

                {
                    error && <div className="mt-3 text-danger">{error}</div>
                }
            </Modal>
        )
    }
}

ChangeSupplierModal.propTypes = {
    preference: PropTypes.object,
    visible: PropTypes.bool,
    fulfilledPackage: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    supplierSelected: PropTypes.object,
    errorChangeSupplier: PropTypes.string,
    orderId: PropTypes.string,
    refunded: PropTypes.object,
    onChargeOrRefund: PropTypes.func.isRequired
}

export default ChangeSupplierModal
