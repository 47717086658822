import React from 'react'
import { Modal } from "antd"
import { Link } from 'react-router-dom'


const ShippingsTableRow = (props) => {
    const { item, handleDelete, permissionsData } = props
    const { supplier, shipping_plan,
        shipping_zone, store, product_line,
        first_item_price,
        additional_item_price, _id
    } = Object.assign({}, item)


    const handleClickDelete = (e, supplier, shipping_plan, shipping_zone, store, product_line) => {
        e.preventDefault()
        Modal.confirm({
            title: <span className='text-danger fs-14'>Confirm Delete</span>,
            icon: '',
            content: 'Are you sure you want to delete?',
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk: () => handleDelete(supplier, shipping_plan, shipping_zone, store, product_line)
        })

    }
    const borderRight = permissionsData.includes('ffm_shipping_fee_update') && permissionsData.includes('ffm_shipping_fee_delete') ?
                        'border-right' : ''
    return (
        <tr>
            <td className="Supplier">{!!supplier ? supplier.name : ''}</td>
            <td className="Shipping_plan">{!!shipping_plan ? shipping_plan.title : ''}</td>
            <td className="Zone">{!!shipping_zone ? shipping_zone.title : ''}</td>
            <td className="Store">{(!store || store === "") ? "All" : store}</td>
            <td className="Product_title">{!!product_line ? product_line.title : ''}</td>
            <td className="First_item">{!!first_item_price ? first_item_price : 0}</td>
            <td className="Additional_item">{!!additional_item_price ? additional_item_price : 0}</td>
            <td className="Action">
                <Link to={`/a/shipping-fee/${_id}`} className={`text-primary ${borderRight} px-1 cursor-pointer`}>
                    {permissionsData.includes('ffm_shipping_fee_update') ? 'Edit' : 'Detail'}
                </Link>
                {permissionsData.includes('ffm_shipping_fee_delete') &&
                    <span className="text-danger mx-1 cursor-pointer"
                        onClick={(e) => handleClickDelete(e, supplier._id, shipping_plan._id, shipping_zone._id, store, product_line._id)}
                    >Delete</span>
                }
            </td>
        </tr>
    )
}

export default ShippingsTableRow