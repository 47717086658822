import React, {Component} from 'react'
import ProductLineVariantAttribute from "./ProductLineVariantData/ProductLineVariantAttribute"
import ProductLineVariantPrice from "./ProductLineVariantData/ProductLineVariantPrice"
import {updateOrderItemMapping} from "../../../../../services/api/ProductsServices"
import broker from '../../../OrderBroker'
import moment from "moment";
import {Tooltip, message, Modal, Button} from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons'

class ProductLineVariantsRow extends Component {

    _handleUpdateOrderItemMapping = async () => {
        try {
            const {variant, orderItemId, _handleUpdateStateOrderItem, artworks, _handleAlert} = this.props
            const {_id: variantId} = variant
            const artworkdata = (!!artworks && Object.keys(artworks).length>0) && 
            [...artworks[0].artworks.map(i=> ({
                _id: i._id,
                mime_type: i.mime_type
            }))]

            const {data, success, message : mess} = await updateOrderItemMapping(variantId, orderItemId, artworkdata)
            if (!success) {
                const regexTikTok = /supplier\s+.*\s+tiktok/i

                // if(!regexTikTok.test(mess)) {
                _handleUpdateStateOrderItem("error", mess)
                const content = <span className='d-block text-danger font-weight-bold'>{mess}</span>
                Modal.error({
                    title: 'Error',
                    content: content,
                    className: 'confirm-force',
                    maskStyle: {
                        zIndex: 1051,
                    },
                })
                // }else{
                //     _handleUpdateStateOrderItem("error", mess)
                //     const content = <div>
                //         <span className='d-block text-danger font-weight-bold'>{mess}</span>
                //         Do you want to force mapping this TikTok order with the variant which is from a not-support-tiktok-supplier?
                //     </div>
                //     Modal.confirm({
                //         title: 'Confirm',
                //         icon: <ExclamationCircleOutlined />,
                //         content: content,
                //         okText: 'Yes',
                //         cancelText: 'No',
                //         className: 'confirm-force',
                //         maskStyle : {
                //             zIndex: 1051,
                //         },
                //         onOk: async () => {
                //             const {data, success, message : mess} = await updateOrderItemMapping(variantId, orderItemId, artworkdata, true)
                //             if(!success) {
                //                 message.error(mess)
                //                 setTimeout(() => {
                //                     const messageElement = document.querySelector(`.ant-message`)
                //                     if (messageElement) {
                //                         messageElement.style.zIndex = '2000'
                //                     }
                //                 }, 100)
                //                 return
                //             }
                //             message.success("Force map single Tiktok successful")
                //             setTimeout(() => {
                //                 const messageElement = document.querySelector(`.ant-message`)
                //                 if (messageElement) {
                //                     messageElement.style.zIndex = '2000'
                //                 }
                //             }, 100)
                //             _handleUpdateStateOrderItem("showModal", false)
                //             _handleAlert(data[2].alert||'')
                //             broker.publish("PRODUCT_MAP_UPDATED")
            
                //             setTimeout(() => {
                //                 broker.publish("ARTWORK_GET")
                //             }, 1000)
                //         }
                //     })
                // }

            } else {
                _handleUpdateStateOrderItem("showModal", false)
                _handleAlert(data[2].alert||'')
                broker.publish("PRODUCT_MAP_UPDATED")

                setTimeout(() => {
                    broker.publish("ARTWORK_GET")
                }, 1000)
            }
        } catch (e) {
            this.props._handleUpdateStateOrderItem("error", e.message)
        }
    }

    _handleSelectVariant = (e) => {
        e.preventDefault()

        this._handleUpdateOrderItemMapping()
    }

    render() {
        const { variant, attributes, order_type } = this.props
        const disabledFulfillOrder = variant.is_disabled ? true : false
        const disabledFulfillOrderTikTok = !variant.is_disabled && variant.available_tiktok ? false : true
        const disabled = order_type === 'TIKTOK' ? disabledFulfillOrderTikTok : disabledFulfillOrder
        const tooltipDisabled = 'Off by user'

        const listStock = {
            haveDate: variant.restock_date !== '2222-02-22T22:22:22.222Z' ? `Restock date is ${moment(variant.restock_date).format('YYYY-MM-DD HH:mm ')}` : 'SKU is unavailable. No restock date',
            empty: (disabled && (variant.restock_date === undefined || variant.restock_date === '')) ? tooltipDisabled : ''
        }

        return (
            <Tooltip
                title={`${!!variant.restock_date ? listStock['haveDate'] : listStock['empty']}`}>
                <tr
                    className={`ProductLineVariantsRow ${disabled ? 'Disabled' : 'cursor-pointer'}`}
                    onClick={disabled ? undefined : this._handleSelectVariant}
                >

                    {
                        attributes.map(attribute => {
                            return <ProductLineVariantAttribute
                                key={attribute._id}
                                variant={variant}
                                attribute={attribute}
                            />
                        })
                    }
                    <ProductLineVariantPrice currency={variant.currency} price={variant.price ? variant.price.price : variant.base_cost}/>
                </tr>
            </Tooltip>
        )
    }
}

export default ProductLineVariantsRow
