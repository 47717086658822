import React, { useState } from 'react'
import { Button, Modal, notification } from "antd"
import { cancelOrderGTUS as _cancelOrderGTUS } from "../../../../services/api/ExportOrderServices"
import broker from "../../OrderBroker"

function CancelOrderGTUS(props) {
    const {cancelOrderGTUS, fulfillment, onClose} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const _onCloseModal = () => {
        if (typeof onClose === 'function') {
            onClose()
        }
    }

    const _onCancelOrder = async () => {
        setLoading(true)
        setError('')

        try {
            const response = await _cancelOrderGTUS(fulfillment._id)

            const {success, message} = response

            if (!success) {
                setLoading(false)
                setError(message)
                return
            }

            setLoading(false)
            if (typeof onClose === 'function') {
                onClose()
            }
            broker.publish("TRACKING_UPDATED")
            broker.publish('ORDER_REFRESHED_ORDER')
            broker.publish('ORDER_REFRESH_BRANDING')
            broker.publish('ORDER_RESTORE')

            notification.success({
                message: 'Cancel order success',
                description: 'Cancel order GTUS success.',
            })

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    return (
        <Modal
            className='CancelOrderGTUS'
            visible={cancelOrderGTUS}
            title="Cancel order GTUS"
            onOk={_onCancelOrder}
            onCancel={_onCloseModal}
            footer={[
                <div key="content" className='d-flex align-items-center justify-content-between'>
                    <Button key="back" onClick={_onCloseModal}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="danger" loading={loading} onClick={_onCancelOrder}>
                        Submit
                    </Button>
                </div>
            ]}
        >
            <p>Do you want to cancel <strong>{fulfillment.name}</strong>?</p>
            {
                !!error && error.length > 0 && <div className="text-danger">{error}</div>
            }
        </Modal>
    )
}

export default CancelOrderGTUS