import React, {Component} from 'react'
import {updateOrderShippingCost} from "../../../../services/api/OrdersServices"
import broker from '../../OrderBroker'
import formatCurrency from "../../../../helpers/common/formatCurrency"
import {ReactComponent as PriceInfo} from "../../../../statics/assets/images/icons8-info.svg"
import { Tooltip } from 'antd'

class OrderInvoicePreview extends Component {
    state = {
        error: "",
        editShippingCost: false,
        formShippingCost: '',
        saving: false
    }

    _handleClickToggleModal = e => {
        const {customShippingCost} = this.props
        this.setState({
            editShippingCost: !this.state.editShippingCost,
            formShippingCost: customShippingCost
        })
    }

    _handleChangeFormControl = e => {
        const {value} = e.target
        this.setState({
            formShippingCost: value
        })
    }

    _handleUpdateOrderShippingCost = async () => {
        try {
            const {formShippingCost} = this.state
            const {orderId} = this.props

            this.setState({
                saving: true,
            })

            const {success, message} = await updateOrderShippingCost(orderId, formShippingCost)

            if (!success) {
                return this.setState({
                    error: message,
                    saving: false,
                    editShippingCost: false
                })
            }

            broker.publish("ORDER_REFRESH_ORDER")

            return this.setState({
                error: "",
                saving: false,
                editShippingCost: false
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                saving: false,
                editShippingCost: false
            })
        }
    }

    _handleSaveChange = e => {
        e.preventDefault()
        this._handleUpdateOrderShippingCost()
    }

    render() {
        const {editShippingCost, formShippingCost, saving} = this.state
        const {customShippingCost, baseCost, currency, fulfillmentStatus, isMapped, isTotalBaseCostFromStore} = this.props

        if (fulfillmentStatus !== 'unfulfilled') {
            return null
        }

        if (!isMapped) {
            return null
        }

        const total = customShippingCost + baseCost

        return (
            <div className="OrderInvoice">
                <div className="card">
                    <div className="d-flex justify-content-between card-header font-weight-bold">
                        <span>Order Invoice Preview</span>

                        <span className="text-primary cursor-pointer ml-3" onClick={this._handleClickToggleModal}
                                                                        title="Edit Shipping Cost"><i className="ti-pencil"/></span>
                    </div>

                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-2 ShippingCost">
                            <div>Shipping cost</div>
                            {
                                editShippingCost ? <div className="form-edit-shipping-cost">
                                    <form className="d-flex" onSubmit={this._handleSaveChange}>
                                        <input type="number" value={formShippingCost} name="shoppingCost"
                                               className="form-control"
                                               id="shoppingCost"
                                               onChange={this._handleChangeFormControl}/>
                                        {
                                            saving ? <button className="btn btn-primary submit saving" type="submit" disabled>Saving...</button> : <button className="btn btn-primary submit" type="submit">Save</button>
                                        }
                                    </form>
                                </div> : <div className="d-flex align-items-center font-weight-500 shippingCostValue">{formatCurrency(currency, customShippingCost)}</div>
                            }
                        </div>

                        <div className="d-flex justify-content-between mb-3 FulfillmentCost">
                            <div>Base cost</div>
                            <div className="font-weight-500">
                                {formatCurrency(currency, baseCost)}
                                {
                                    isTotalBaseCostFromStore ? 
                                    <Tooltip title="Price apply for seller">
                                        <span><PriceInfo width="20px" height="20px" style={{position: 'relative', top: '4px', marginLeft: '4px'}}/></span>
                                    </Tooltip> : ''
                                }
                            </div>
                        </div>

                        <div className="d-flex justify-content-end border-top pt-3 align-items-baseline Total">
                            <div className="mr-3">Total ({currency})</div>
                            <div className="font-weight-500"
                                 style={{fontSize: "20px"}}>{formatCurrency(currency, total)}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderInvoicePreview
