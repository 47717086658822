import React from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {updateSupplier} from "../../../services/api/SuppliersServices"
import { Select, Tooltip, Row, Col } from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons'
import { ZONE } from './ListOptions'

class SuppliersModalEdit extends React.Component {
    state = {
        name: '',
        sku_prefix: '',
        need_gen_mockup: false,
        max_mapping: '',
        available_tiktok: false,
        shipbytiktok: false,
        need_charge_have_sleeves: '',
        is_active: true,
        isLoading: false,
        errorName: '',
        errorSku: '',
        error: '',
        zone: '',
        mapping_priority: '',
        errorMappingPriority: '',
        warehouse_name: '',
        label_handling_fee: '',
        additional_fee: ''
    }

    componentDidMount() {
        this._initSupplier()
    }

    _initSupplier = () => {
        const {supplier} = this.props
        const {name, is_active, sku_prefix, need_gen_mockup, max_mapping, available_tiktok, need_charge_have_sleeves, shipbytiktok, zone, mapping_priority, warehouse_name, label_handling_fee, additional_fee} = supplier

        this.setState({
            name: name || '',
            sku_prefix: sku_prefix || '',
            is_active,
            need_gen_mockup: need_gen_mockup || false,
            max_mapping: max_mapping || '',
            available_tiktok: available_tiktok || false,
            shipbytiktok: shipbytiktok || false,
            need_charge_have_sleeves: need_charge_have_sleeves || '',
            zone: zone || '',
            mapping_priority: mapping_priority || '',
            warehouse_name: warehouse_name || '',
            label_handling_fee: label_handling_fee || '',
            additional_fee: additional_fee || ''
        })
    }

    _handleChangeData = (field, value) => {
        this.setState(() => {
            return {
                [field]: value,
                errorName: (field === 'name' && !value.trim()) ? 'Name is required.' : '',
                errorSku: (field === 'sku_prefix' && !value.trim()) ? 'Sku prefix is required.': '',
                errorMappingPriority: field === 'mapping_priority' ? !!value && (!Number.isInteger(+value) || +value <= 0) ? 'Mapping priority must be a positive integer.' : (!!value && !value.trim()) ? 'Mapping priority is required.' : '' : '',
                error: ''
            }
        })
    }

    _handleChangeSelectData = (field, value) => {
        if(field === 'available_tiktok' && value === 'no'){
            this.setState(() => {
                return {
                    available_tiktok: false,
                    shipbytiktok: false,
                    warehouse_name: '',
                    label_handling_fee: '',
                    additional_fee: '',
                    error: ''
                }
            })
        }else if(field === 'shipbytiktok' && value === 'no'){
            this.setState(() => {
                return {
                    shipbytiktok: false,
                    label_handling_fee: '',
                    error: ''
                }
            })
        }else{
            this.setState(() => {
                return {
                    [field]: value === 'yes' ? true : false,
                    error: ''
                }
            })
        }
    }

    _handleClickSave = async () => {
        const {name, sku_prefix, need_gen_mockup, max_mapping, available_tiktok, need_charge_have_sleeves, shipbytiktok, warehouse_name, label_handling_fee, additional_fee, zone, mapping_priority} = this.state

        if(!name || !sku_prefix) return this.setState({
            errorName: (!name.trim()) ? 'Name is required.' : '',
            errorSku: (!sku_prefix.trim()) ? 'Sku prefix is required.': '',
            errorMappingPriority: (!mapping_priority.trim()) ? 'Mapping priority is required.' : '',
        })

        this.setState({isLoading: true})

        try {
            const {is_active} = this.state
            const {supplier: {_id: supplierId}, refresh, toggle} = this.props
            const payload = {
                name,
                sku_prefix,
                is_active,
                need_gen_mockup,
                max_mapping: max_mapping ? parseFloat(max_mapping) : 0,
                available_tiktok,
                need_charge_have_sleeves: need_charge_have_sleeves ? parseFloat(need_charge_have_sleeves) : null,
                shipbytiktok : available_tiktok ? shipbytiktok : false,
                zone: zone ? zone : '',
                mapping_priority: +mapping_priority,
                warehouse_name,
                label_handling_fee: label_handling_fee ? parseFloat(label_handling_fee) : null,
                additional_fee: additional_fee ? parseFloat(additional_fee) : null
            }
            const {success, message} = await updateSupplier(supplierId, payload)
            if (!success) {
                return this.setState({
                    error: message,
                    isLoading: false
                })
            }

            refresh()
            toggle()
        } catch (e) {
            this.setState({
                error: e.message,
                isLoading: false
            })
        }
    }

    render() {
        const {isOpen, toggle} = this.props
        const {name, sku_prefix, is_active, isLoading, error, errorName, errorSku, need_gen_mockup, max_mapping,
            available_tiktok, need_charge_have_sleeves, shipbytiktok, zone, mapping_priority, errorMappingPriority,
            warehouse_name, label_handling_fee, additional_fee} = this.state

        return (
            <div>
                <Modal isOpen={isOpen} toggle={toggle} className="ModalEditSupplier">
                    <ModalHeader toggle={toggle}>Edit Supplier</ModalHeader>
                    <ModalBody>
                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label htmlFor='Name'>Name</label>
                                    <input id='Name' className='form-control' type='text' name='name' value={name || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('name', value)} required />
                                    {errorName && <div className="text-danger mt-1">{errorName}</div>}
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label htmlFor='skuPrefix'>SKU Prefix</label>
                                    <input id='skuPrefix' className='form-control' type='text' name='sku_prefix' value={sku_prefix || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('sku_prefix', value)} required />
                                    {errorSku && <div className="text-danger mt-1">{errorSku}</div>}
                                </div>
                            </Col>
                        </Row>

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='mappingPriority'>Mapping Priority</label>
                                    <input id='mappingPriority' className='form-control' type='number' name='mapping_priority' value={mapping_priority || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('mapping_priority', value)} />
                                    {errorMappingPriority && <div className="text-danger mt-1">{errorMappingPriority}</div>}
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='maxMapping'>Max Mapping <Tooltip title="Max capacity per day"><QuestionCircleFilled /></Tooltip></label>
                                    <input id='maxMapping' className='form-control' type='number' name='max_mapping' value={max_mapping || ""}
                                        onChange={({ target: { value } }) => this._handleChangeData('max_mapping', value)} />
                                </div>
                            </Col>
                        </Row>

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='zone'>Zone</label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        className='customSelect'
                                        onChange={value => this._handleChangeData('zone', value)}
                                        options={ZONE}
                                        value={zone}
                                        allowClear
                                        id='zone'
                                    />
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='genMuckup'>Need Gen Mockup <Tooltip title="Must have mockup when pushing orders?"><QuestionCircleFilled /></Tooltip></label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        className='customSelect'
                                        onChange={value => this._handleChangeSelectData('need_gen_mockup', value)}
                                        options={[
                                            {
                                                value: 'no',
                                                label: 'No',
                                            },
                                            {
                                                value: 'yes',
                                                label: 'Yes',
                                            }
                                        ]}
                                        value={need_gen_mockup ? 'yes' : 'no'}
                                        id="genMuckup"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label htmlFor='Status'>Status</label>
                                    <select id='Status' name='is_active' className='form-control' value={is_active}
                                            onChange={({target: {value}}) => this._handleChangeData('is_active', value)}>
                                        <option value={true}>active</option>
                                        <option value={false}>inactive</option>
                                    </select>
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='needChargeHaveSleeves'>Need charge have sleeves <Tooltip title="US supplier's sleeves printing fee"><QuestionCircleFilled /></Tooltip></label>
                                    <div className='input-currency-box'>
                                        <span className='currency'>$</span>
                                        <input id='needChargeHaveSleeves' className='form-control' type='number' name='need_charge_have_sleeves' value={need_charge_have_sleeves || ""}
                                            onChange={({ target: { value } }) => this._handleChangeData('need_charge_have_sleeves', value)} />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className='justify-content-between'>
                            <Col span={11}>
                                <div className='form-group'>
                                    <label className='d-block' htmlFor='availableTiktok'>Available Tiktok <Tooltip title="Available for receiving Tiktok orders?"><QuestionCircleFilled /></Tooltip></label>
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        className='customSelect'
                                        onChange={value => this._handleChangeSelectData('available_tiktok', value)}
                                        options={[
                                            {
                                                value: 'no',
                                                label: 'No',
                                            },
                                            {
                                                value: 'yes',
                                                label: 'Yes',
                                            }
                                        ]}
                                        value={available_tiktok ? 'yes' : 'no'}
                                        id='availableTiktok'
                                    />
                                </div>
                            </Col>
                            <Col span={11}>
                                {
                                    available_tiktok &&
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='warehouseName'>Warehouse Name <Tooltip title="The info of warehouse"><QuestionCircleFilled /></Tooltip></label>
                                        <input id='warehouseName' className='form-control' type='text' name='warehouse_name' value={warehouse_name || ""}
                                            onChange={({target: {value}}) => this._handleChangeData('warehouse_name', value)} required/>
                                    </div>
                                }
                            </Col>
                        </Row>

                        {
                            available_tiktok &&
                            <Row className='justify-content-between'>
                                <Col span={11}>
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='shipByTikTok'>Ship by Tiktok</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            className='customSelect'
                                            onChange={value => this._handleChangeSelectData('shipbytiktok', value)}
                                            options={[
                                                {
                                                    value: 'no',
                                                    label: 'No',
                                                },
                                                {
                                                    value: 'yes',
                                                    label: 'Yes',
                                                }
                                            ]}
                                            value={shipbytiktok ? 'yes' : 'no'}
                                            id='shipByTikTok'
                                        />
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <div className='form-group'>
                                        <label className='d-block' htmlFor='additionalFee'>Additional Fee <Tooltip title="The additional fee of processing each item, current only applying for PYUS, SPUS & BFUS."><QuestionCircleFilled /></Tooltip></label>
                                        <input id='additionalFee' className='form-control' type='number' name='additional_fee' value={additional_fee || ""}
                                            onChange={({target: {value}}) => this._handleChangeData('additional_fee', value)}/>
                                    </div>
                                </Col>
                            </Row>
                        }

                        {
                            available_tiktok &&
                            <Row className='justify-content-between'>
                                <Col span={11}>
                                    {
                                        shipbytiktok &&
                                        <div className='form-group'>
                                            <label className='d-block' htmlFor='labelHandlingFee'>Label Handling Fee <Tooltip title="The fee of handling the label, current not applying for PYUS & BFUS. Leaving this field blank, system will auto charge $0.5 with TikTok orders."><QuestionCircleFilled /></Tooltip></label>
                                            <input id='labelHandlingFee' className='form-control' type='number' name='label_handling_fee' value={label_handling_fee || ""}
                                                onChange={({target: {value}}) => this._handleChangeData('label_handling_fee', value)}/>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        }

                        {error && <div className="text-danger mt-3">{error}</div> }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <button className='btn btn-secondary' onClick={toggle} disabled={isLoading}>Cancel</button>
                            <button className='btn btn-primary' onClick={this._handleClickSave} disabled={isLoading}>
                                Save Supplier
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

SuppliersModalEdit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    className: PropTypes.string,
    supplier: PropTypes.object.isRequired
}

export default SuppliersModalEdit
