import React, {Component} from 'react'
import PropType from 'prop-types'
import OrderCode from './OrdersTableData/OrderCode'
import OrderTime from './OrdersTableData/OrderTime'
import OrderAddress from './OrdersTableData/OrderAddress'
import OrderShippingPlan from './OrdersTableData/OrderShippingPlan'
import OrderTags from './OrdersTableData/OrderTags'
import OrderFulfillmentStatus from './OrdersTableData/OrderFulfillmentStatus'
import OrderPlatform from './OrdersTableData/OrderPlatform'
import OrderPaymentStatus from './OrdersTableData/OrderPaymentStatus'
import OrderNote from './OrdersTableData/OrderNote'
import MappingCondition from './OrdersTableData/MappingCondition'
import OrderArtwork from './OrdersTableData/OrderArtwork'
import OrderTracking from './OrdersTableData/OrderTracking'
import OrderCheckbox from './OrdersTableData/OrderCheckbox'
import OrderStoreMode from "./OrdersTableData/OrderStoreMode"
import classNames from 'classnames'

class OrdersTableRow extends Component {
    render() {
        const {order, currentPage, handleChangeFilterState} = this.props
        const {
            address, fulfillment_status, platform, note, artwork_status, tracking_status = '', is_legacy = false,
            is_archive, is_international, shipment_statuses, zone, packages
        } = order
        const addressValidate = Object.assign({}, address)

        return (
            <tr className={classNames('OrdersTableRow', {archived: is_archive, is_international}, fulfillment_status)}>
                <OrderCheckbox {...this.props}/>
                <OrderCode order={order} currentPage={currentPage} legacy={is_legacy}/>
                <OrderPlatform {...platform}/>
                <OrderTime order={order}/>
                <OrderAddress zone={zone} {...addressValidate}/>
                <OrderStoreMode storeMode={order.preference !== undefined && order.preference.storeMode}/>
                <OrderPaymentStatus order={order}/>
                <OrderFulfillmentStatus
                    fulfillmentStatus={fulfillment_status}
                    packages={packages}
                />
                <OrderShippingPlan order={order}/>
                <OrderTags order={order} handleChangeFilterState={handleChangeFilterState}/>
                <OrderNote note={note}/>
                <MappingCondition order={order}/>
                <OrderArtwork artwork={artwork_status}/>
                <OrderTracking shipment_statuses={shipment_statuses || []} trackingStatus={tracking_status}/>
            </tr>
        )
    }
}

OrdersTableRow.propTypes = {
    order: PropType.object,
    currentPage: PropType.number,
    handleChangeFilterState : PropType.func
}

export default OrdersTableRow
