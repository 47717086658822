import React, {Component} from 'react'
import {Select, Modal, message, Radio, AutoComplete, Checkbox, Tooltip} from "antd"
import StatusStyle from "../../../../shared/StatusStyle"
import {getLocalData} from "../../../../../services/StorageServices"
import classNames from 'classnames'
import {
    getReasonTags,
    submitRefundCharge,
    createChargeRefund,
} from "../../../../../services/api/OrdersServices"
import {getIssues} from '../../../../../services/api/PackageIssuesServices'
import formatThousandNumber from "../../../../../helpers/common/formatThousandNumber"
import broker from "../../../OrderBroker"
import {currency as currencies} from "../../../../../statics/currency"
import formatCurrency from "../../../../../helpers/common/formatCurrency"
import NumberFormat from 'react-number-format'


class RefundPopup extends Component {
    state = {
        loading: false,
        error: '',
        typeRefund: 'refund',
        currency: this.props.currency,
        refund: 0,//value refund input
        refundValue: 0,//value refund($)
        total: 0, //value fulfillment cost
        type: 'dollar',
        tags: [],
        reasonTag: '',
        reasonTagText: '',
        supporter: '',
        reason: '',
        validated: {
            vType: '',
            vRefund: '',
            vRasonTag: '',
            vReason: '',
            vSupporter: ''
        },
        issueOrders: []
    }

    componentDidMount() {
        const {
            invoiceRefund,
            typeRefund,
            reasonTag = '',
            reasonTagText = '',
            reason,
            refundChargeValue,
            buttonText
        } = this.props
        const total = parseFloat(invoiceRefund.maxRefund) + parseFloat(invoiceRefund.totalRefund)
        this.setState({
            refund: refundChargeValue !== undefined ? refundChargeValue : invoiceRefund.maxRefund,
            total: total,
            refundValue: refundChargeValue !== undefined ? refundChargeValue : invoiceRefund.maxRefund,
            typeRefund,
            reasonTag,
            reasonTagText,
            reason,
            buttonText
        })
        this._fetchReasonTags()
        this.fetchListIssueOrder()
    }

    _fetchReasonTags = async () => {
        try {
            const {success, data} = await getReasonTags()

            if (!success) return
            const reasonTag = this.props.reasonTagText && data.find(it => it.description === this.props.reasonTagText)

            this.setState({
                tags: data,
                ...(reasonTag && {
                    reasonTag: reasonTag._id
                })
            })
        } catch (e) {
            console.log(e.message)
        }
    }

    fetchListIssueOrder = async () => {
        const {code} = this.props
        const payload = {
            query: {
                solution: 'refund',
                resolution: 'not-yet-refunded',
                order_number: code
            },
            sort: "-updated_at",
        }

        try {
            const { success, data, message: mess } = await getIssues(payload)
            if (!success) {
                throw new Error(mess)
            }
            const newData = data.issues.map(i => ({...i, isCheck: true}))
            const issueSorts = newData.sort((a, b) => a.category === 'shipment' && b.category !== 'shipment' ? -1 : 1)
            this.setState({issueOrders: issueSorts})
        } catch (e) {
            console.log(e.message)
        }
    }

    _renderTitlePopup = () => {
        const {fulfillmentStatus, code} = this.props
        return <div className="CustomHeaderPopup">
            <label>Refund/Charge order</label>
            <div className="infoOrder d-flex align-items-center">#{code} <StatusStyle status={fulfillmentStatus}/></div>
        </div>
    }

    _handleChangeRefund = (e) => {
        const {value} = e.target
        const {type, total, validated, currency, typeRefund} = this.state
        const {refunded} = this.props
        let errorRefund = ''

        if (value === '') {
            errorRefund = `This value is not valid`
            return this.setState({
                refund: value,
                refundValue: "",
                validated: {
                    ...validated,
                    vRefund: errorRefund
                }
            })
        }

        if (typeRefund === 'refund') {
            const maxPercent = parseFloat(total) > 0 ? (parseFloat(total) - parseFloat(refunded)) / parseFloat(total) * 100 : 0
            const maxValue = parseFloat(total) - parseFloat(refunded)

            if (maxValue === 0) {
                errorRefund = `Order can not refund`
            } else {
                if (type === 'dollar') {
                    if (parseFloat(value.replace(/[,]/g, '')) > parseFloat(maxValue)) {
                        errorRefund = `This value must be less than ${formatCurrency(currency, maxValue)}`
                    }
                } else {
                    if (parseFloat(value.replace(/[,]/g, '')) > parseFloat(maxPercent)) {
                        errorRefund = `This value must be less than ${parseFloat(maxPercent).toFixed(2)}%`
                    }
                }
            }
        }

        if (parseFloat(value) === 0) errorRefund = 'This value must be larger 0'

        const valuePercent = parseFloat(total) / 100 * parseFloat(value.replace(/[,]/g, ''))

        this.setState({
            refund: value.replace(/[,]/g, ''),
            refundValue: type === 'dollar' ? formatThousandNumber(parseFloat(value.replace(/[,]/g, '')).toFixed(2)) : formatThousandNumber(parseFloat(valuePercent).toFixed(2)),
            validated: {
                ...validated,
                vRefund: errorRefund
            }
        })
    }

    _handleChangeType = (value) => {
        const {total, type, validated} = this.state
        const {refunded} = this.props
        const maxPercent = parseFloat(total) > 0 ? (parseFloat(total) - parseFloat(refunded)) / parseFloat(total) * 100 : 0
        const maxValue = parseFloat(total) - parseFloat(refunded)

        if (type !== value) {
            this.setState({
                type: value,
                refund: value === 'dollar' ? formatThousandNumber(parseFloat(maxValue).toFixed(2)) : maxPercent,
                refundValue: formatThousandNumber(parseFloat(maxValue).toFixed(2)),
                validated: {
                    ...validated,
                    vRefund: ''
                }
            })
        }
    }

    _changeTag = (value, e) => {
        const {validated} = this.state

        this.setState({
            reasonTag: e.key,
            reasonTagText: value,
            reason: e.value,
            validated: {
                ...validated,
                vRasonTag: value.trim() === '' ? `This field can't be blank` : '',
                vReason: ''
            }
        })
    }

    _changeType = (e) => {
        const {value} = e.target
        const {type, total, validated, currency, refund} = this.state
        const {refunded} = this.props
        let errorRefund = ''

        if (value === 'refund') {
            const maxPercent = parseFloat(total) > 0 ? (parseFloat(total) - parseFloat(refunded)) / parseFloat(total) * 100 : 0
            const maxValue = parseFloat(total) - parseFloat(refunded)

            if (maxValue === 0) {
                errorRefund = `Order can not refund`
            } else {
                if (type === 'dollar') {
                    if (parseFloat(refund) > parseFloat(maxValue)) {
                        errorRefund = `This value must be less than ${formatCurrency(currency, maxValue)}`
                    }
                } else {
                    if (parseFloat(refund) > parseFloat(maxPercent)) {
                        errorRefund = `This value must be less than ${parseFloat(maxPercent).toFixed(2)}%`
                    }
                }
            }
        }

        this.setState({
            typeRefund: value,
            reasonTag: '',
            reasonTagText: '',
            reason: '',
            validated: {
                ...validated,
                vType: value.trim() === '' ? `This field can't be blank` : '',
                vRefund: errorRefund,
                vRasonTag: '',
                vReason: '',
            }
        })
    }

    _changeReason = (e) => {
        const {value} = e.target
        const {validated} = this.state

        this.setState({
            reason: value,
            validated: {
                ...validated,
                vReason: value.trim() === '' ? `This field can't be blank` : ''
            }
        })
    }

    _handleChangeSupporter = (e) => {
        const {value} = e.target
        const {validated} = this.state

        this.setState({
            supporter: value,
            validated: {
                ...validated,
                vSupporter: value.trim() === '' ? `This field can't be blank` : ''
            }
        })
    }

    _handleValidate = () => {
        const {reasonTag, reason, validated, refundValue, supporter, typeRefund, tags} = this.state
        const {vRefund} = validated
        const idFFM = tags.find(itemF => itemF.name === 'ffm')

        let errorRefund = vRefund
        if (parseFloat(refundValue) === 0) {
            errorRefund = 'This value must be larger 0'
        }

        let errorType = ''
        if (typeRefund === '') {
            errorType = `This field can't be blank`
        }

        let errorTag = ''
        if (reasonTag === '') {
            errorTag = `This field can't be blank`
        }

        let errorReason = ''
        if (reason === '') {
            errorReason = `This field can't be blank`
        }

        let errorSupporter = ''
        if (idFFM !== undefined && reasonTag === idFFM._id && supporter === '') {
            errorSupporter = `This field can't be blank`
        }

        this.setState({
            validated: {
                vType: errorType,
                vRefund: errorRefund,
                vRasonTag: errorTag,
                vReason: errorReason,
                vSupporter: errorSupporter
            }
        })

        if (errorType !== '' || vRefund !== '' || errorTag !== '' || errorReason !== '' || errorSupporter !== '') return false
        return true
    }

    _handleRefund = async () => {
        if (this._handleValidate()) {
            this.setState({
                loading: true,
                error: ''
            })

            try {
                const {orderId, onTogglePopup, getAdditionRequestData} = this.props
                const {refund, reasonTag, refundValue, type, reason, typeRefund, supporter, currency, issueOrders} = this.state
                const user = getLocalData('user')

                const payload = {
                    user_id: user.id,
                    refund_amount: typeRefund === 'refund' ? parseFloat(refundValue) : 0,
                    charge_amount: typeRefund === 'charge' ? parseFloat(refundValue) : 0,
                    reason_tag: reasonTag,
                    supporter: supporter,
                    charge_percent: typeRefund === 'charge' ? (type === 'dollar' ? 0 : parseFloat(refund)) : 0,
                    refund_percent: typeRefund === 'refund' ? (type === 'dollar' ? 0 : parseFloat(refund)) : 0,
                    reason_for_seller: reason,
                    currency,
                    ...(getAdditionRequestData && getAdditionRequestData())
                }
                if(!!issueOrders.length) payload.issues = issueOrders.filter(i => i.isCheck).map(o => o._id) || []

                const {success, message: messError} = await createChargeRefund(typeRefund, orderId, payload)

                if (!success) return this.setState({
                    error: messError,
                    loading: false
                })

                this.setState({
                    loading: false
                }, () => {
                    onTogglePopup()
                    if (typeRefund === 'refund') {
                        message.success('Refund request create success!')
                    } else {
                        message.success('Charge request create success!')
                    }

                    broker.publish("ORDER_REFRESH_ORDER")
                    broker.publish('ORDER_REFRESH_REFUNDED')

                    this.props.onOk && this.props.onOk()
                })
            } catch (e) {
                this.setState({
                    error: e.message
                })
            }
        } else {
            this.setState({
                error: ''
            })
        }
    }

    handleFilter = (inputValue, option) => {
        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }

    handleChangeItemRequest = (id, checked) => {
        const {issueOrders} = this.state

        const newListRequestIssues = issueOrders.map(item => {
            if (item._id === id) {
                return {
                    ...item,
                    isCheck: !checked
                }
            }

            return item
        })

        this.setState({
            issueOrders : newListRequestIssues
        })
    }

    render() {
        const {isOpen, onTogglePopup} = this.props
        const {
            error,
            loading,
            refund,
            refundValue,
            type,
            reasonTag,
            reasonTagText,
            validated,
            tags,
            currency,
            typeRefund,
            supporter,
            reason,
            buttonText,
            issueOrders
        } = this.state
        const {vRefund, vRasonTag, vReason, vType, vSupporter} = validated
        const symbolPrice = currencies.find(item => item.value === currency)
        const idFFM = tags.find(itemF => itemF.name === 'ffm')
        const textButton = buttonText !== undefined
            ? buttonText
            : typeRefund !== '' ? (typeRefund === 'charge' ? 'Request Charge' : 'Request Refund') : 'Request Refund/Charge'
        const reasonTagOptions = !!tags && [
            ...tags.filter(item => item.type === typeRefund).map(item => ({
                value: item.description,
                label: item.value,
                key: item._id
            }))
        ]

        return <Modal
            visible={isOpen}
            width={837}
            className={['PopupRefundContent', this.props.isDisableOptions && 'disabled'].filter(Boolean).join(' ')}
            title={this._renderTitlePopup()}
            onOk={this._handleRefund}
            onCancel={onTogglePopup}
            footer={[
                <div key="submit" className="d-flex justify-content-end align-items-center">
                    <button className="btn btn-primary" disabled={loading || (refund === 0 && typeRefund === 'refund')}
                            key="submit" type="primary"
                            onClick={this._handleRefund}>
                        {textButton} {refund !== '' && formatCurrency(currency, refundValue)}
                    </button>
                </div>,
            ]}
        >
            <div className="PopupRefundOptions">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="rowItem d-flex align-items-start mb-3">
                        <label className="mb-0">Type</label>
                        <div className="position-relative">
                            <Radio.Group
                                className={classNames("", vType !== '' && 'error')}
                                onChange={this._changeType}
                                defaultValue={typeRefund}
                                value={typeRefund}
                            >
                                <Radio value="refund">Refund</Radio>
                                <Radio value="charge">Charge</Radio>
                            </Radio.Group>

                            {vType !== '' && <div className="text-danger mt-2">{vType}</div>}
                        </div>
                    </div>
                    <div className="rowItem mb-3 d-flex align-items-start">
                        <label className="mb-0 mt-2">Value</label>
                        <div className="d-flex align-items-center">
                            <div className={classNames("valueRefund", vRefund !== '' && 'error')}>
                                <NumberFormat
                                    thousandSeparator={currency === 'USD' ? true : '.'}
                                    decimalSeparator={currency === 'USD' ? '.' : ','}
                                    decimalScale={currency === 'USD' ? 2 : 0}
                                    prefix=""
                                    value={refund}
                                    onChange={this._handleChangeRefund}
                                />
                                <span onClick={() => this._handleChangeType('dollar')}
                                      className={classNames("", type === 'dollar' && 'active')}>{symbolPrice.symbol}</span>
                                <span onClick={() => this._handleChangeType('percent')}
                                      className={classNames("", type === 'percent' && 'active')}>%</span>
                            </div>
                            {vRefund !== '' && <div className="text-danger ml-3">{vRefund}</div>}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-start justify-content-between mb-3">
                    <div className="rowItem d-flex align-items-start w-100">
                        <label className="mb-0 mt-2">Reason tag</label>
                        <div className="position-relative w-100 ">
                            <AutoComplete style={{width: "100%"}}
                                          className={classNames("", vRasonTag !== '' && 'error')}
                                          onChange={(value, e) => this._changeTag(value, e)}
                                          defaultValue={reasonTagText}
                                          value={reasonTagText}
                                          options={reasonTagOptions}
                                          placeholder='Select reason tag'
                                          filterOption={(inputValue, option) => this.handleFilter(inputValue, option)}
                                          onSelect={this.handleSelect}
                            >
                            </AutoComplete>
                            {vRasonTag !== '' && <div className="text-danger mt-2">{vRasonTag}</div>}
                        </div>
                    </div>
                    {(idFFM !== undefined && reasonTag === idFFM._id) &&
                    <div className="rowItem d-flex align-items-start">
                        <label className="mb-0 w-auto mt-2 mr-3">Supporter</label>
                        <div className="position-relative">
                            <input className={classNames("form-control", vSupporter !== '' && 'error')}
                                   onChange={this._handleChangeSupporter} name="creator" type="text" value={supporter}/>
                            {vSupporter !== '' && <div className="text-danger mt-2">{vSupporter}</div>}
                        </div>
                    </div>}
                </div>
                <div className="rowItem mb-3 pt-1">
                    <label className="d-block">Reason for seller</label>
                    <div className="position-relative">
                        <textarea onChange={this._changeReason}
                                  className={classNames("form-control", vReason !== '' && 'error')}
                                  placeholder="Send to seller..."
                                  value={reason || ""}/>
                        {vReason !== '' && <div className="text-danger mt-2">{vReason}</div>}
                    </div>
                </div>
                {typeRefund === "refund" && <div className="mt-3 mb-4 border-top pt-2">
                    <label style={{ fontWeight: 500 }}>Request refund for issue items:</label>
                    <div className="issue-request-refund">
                        {
                            issueOrders.map(issue => {
                                const { variant_data, quantity, category, package_name, reference_number, tracking_number = '' } = Object.assign({}, issue)
                                const { product_type, size, color } = Object.assign({}, variant_data)
                                return category !== "shipment" ?
                                (
                                    <div key={issue._id} style={{
                                        display: 'grid',
                                        gridTemplateColumns: '60px repeat(4, 1fr)',
                                        borderBottom: '1px solid #dedede',
                                        paddingBottom: '2px',
                                    }}>
                                        <span style={{ maxWidth: 60 }}><Checkbox checked={issue.isCheck} onChange={() => this.handleChangeItemRequest(issue._id, issue.isCheck)} /></span>
                                        {reference_number && <span>{reference_number}</span>}
                                        {category === "production" && product_type && <span>{product_type}</span>}
                                        {category === "production" && <span className='text-center'>{(!!size || !!color) ? (size || color) : (size && color ? `${size}/${color}` : '')}</span>}
                                        {category === "production" && quantity && <span className='text-center'>{`Qty: ${quantity}`}</span>}
                                    </div>
                                ) : (
                                    <div key={issue._id} style={{
                                        display: 'grid',
                                        gridTemplateColumns: '60px repeat(4, 1fr)',
                                        borderBottom: '1px solid #dedede',
                                        paddingBottom: '2px',
                                        background: "#f1f1f1",
                                        cursor: 'pointer'
                                    }}>
                                            <span style={{ maxWidth: 60 }}><Checkbox checked={issue.isCheck} onChange={() => this.handleChangeItemRequest(issue._id, issue.isCheck)} /></span>
                                            <Tooltip title='Shipment issue.'>
                                                {package_name && <span>{package_name}</span>}
                                            </Tooltip>
                                            {tracking_number && <span>{tracking_number}</span>}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>}
            </div>
            {error && <div className="mt-3 text-danger">{error}</div>}
        </Modal>
    }
}

export default RefundPopup
