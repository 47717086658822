import React, {Component} from 'react'

class OrderAddress extends Component {
    render() {
        const {country = '', email = '', full_name = '', zone = ''} = this.props

        return (
            <td className="OrderAddress">
                {
                    !!full_name &&
                    <div>{full_name}</div>
                }
                {
                    !!email &&
                    <div>{email}</div>
                }
                {
                    !!country &&
                    <div className="Country">
                        <span>{country}</span>
                        {!!zone && <span> ({zone})</span>}
                    </div>
                }
            </td>
        )
    }
}

export default OrderAddress
