import React from 'react'
import PropTypes from 'prop-types'
import humanizeTime from "../../../helpers/time/humanizeTime"
import {Tooltip, Tag} from "antd"
import {Link} from "react-router-dom"
import StatusColumn from '../../order-partners-v2/components/data/StatusColumn'

OrderMeta.propTypes = {
    order: PropTypes.object
}

function OrderMeta(props) {
    const {order} = props
    return (
        <div className="OrderMeta">
            <div className="d-flex align-items-baseline">
                <h1 className="PageTitle OrderNumber font-weight-bold mb-0">{order.name}</h1>
                <div className="UpdateTime mx-3">Update at: {humanizeTime(order.pushed_at)}</div>
                <code title="Namespace">{!!order.order && order.order.namespace}</code>
                <Tooltip title="Go to the parent order!"><Link to={{
                    pathname: `/a/orders/${order.order && order.order._id}`
                }}><span className="ti-rocket"/></Link></Tooltip>
                {
                    (order.hasOwnProperty('is_archive') && order.is_archive) && (
                        <Tag className='mx-3' color="#2db7f5">Archived</Tag>
                    )
                }
                <div className='ml-5 mr-3 text-capitalize'>
                    <StatusColumn status={order.package_status||''} />
                </div>
            </div>
            <div className="d-flex align-items-baseline">
                <div className="Custom mr-3">
                    Custom
                    Reference: {!!order.factory_info && !!order.factory_info.order_reference_id && order.factory_info.order_reference_id}
                </div>
            </div>
        </div>
    )
}

export default OrderMeta
