import React from 'react'

const ItemType = ({ type, supplier, brandName }) => {
    return (
        <td className='ItemType'>
            {type}
            {brandName &&
                <div className='ItemBrand mt-2 cursor-pointer'>
                    <span className="mr-2">
                        {brandName}
                    </span>
                </div>
            }
            {supplier &&
                <div className='ItemSupplier mt-2 cursor-pointer'>
                    <span className="mr-2">
                        {supplier}
                    </span>
                </div>
            }
        </td>
    )
}

export default ItemType