import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, notification} from "antd"
import {cancelOrderMD, checkProductionStatus} from "../../../../services/api/ExportOrderServices"
import broker from "../../OrderBroker"

class CancelOrderMD extends Component {
    state = {
        loading: false,
        productionStatus: '',
        error: ''
    }

    componentDidMount() {
        if (this.state.productionStatus) {
            return
        }
        const {fulfillment} = this.props

        checkProductionStatus(fulfillment._id).then((response) => {
            const {
                data: productionStatus,
                message
            } = {...response}

            this.setState({
                error: message,
                productionStatus
            })
        }).catch(error => {
            this.setState({
                error
            })
        })
    }

    _onCloseModal = () => {
        const {onClose} = this.props

        if (typeof onClose === 'function') {
            onClose()
        }
    }

    _onCancelOrder = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {fulfillment} = this.props

            const response = await cancelOrderMD(fulfillment._id)

            const {success, message} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                loading: false
            }, () => {
                const {onClose} = this.props

                if (typeof onClose === 'function') {
                    onClose()
                }

                broker.publish("TRACKING_UPDATED")
                broker.publish('ORDER_REFRESHED_ORDER')
                broker.publish('ORDER_REFRESH_BRANDING')
                broker.publish('ORDER_RESTORE')

                notification.success({
                    message: 'Cancel order success',
                    description: 'Cancel order MD success.',
                })
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    render() {
        const {cancelOrderMD, fulfillment} = this.props
        const {loading, error, productionStatus} = this.state
        const isError = !!error && error.length > 0
        return (
            <Modal
                className='cancelOrderMD'
                visible={cancelOrderMD}
                title="Cancel order MD"
                onOk={this._onCancelOrder}
                onCancel={this._onCloseModal}
                footer={[
                    <div key="content" className='d-flex align-items-center justify-content-between'>
                        <Button key="back" onClick={this._onCloseModal}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="danger" loading={loading} onClick={this._onCancelOrder}
                                disabled={!productionStatus || error}>
                            Submit
                        </Button>
                    </div>
                ]}
            >
                {
                    !productionStatus && !isError &&
                    <div className="fetch-production-status">
                        <i className="ti-reload anticon-spin"/>
                        <span>Checking production status of the package <strong>{fulfillment.name}</strong> ...</span>
                    </div>
                }
                {
                    productionStatus && !isError && <>
                        <p className="mb-0">The production status of the
                            package <strong>{fulfillment.name}</strong> is <strong>{productionStatus}</strong>.</p>
                        <p className="mb-0">Are you sure you want to cancel this package?</p>
                    </>
                }
                {
                    isError && <div className="text-danger">{error}</div>
                }
            </Modal>
        )
    }
}

CancelOrderMD.propTypes = {
    cancelOrderMD: PropTypes.bool,
    fulfillment: PropTypes.object
}

export default CancelOrderMD
