import React, {Component, Fragment} from 'react'
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import PackageAddressEditor from './PackageAddressEditor'
import PackageListItemsEditor from './PackageListItemsEditor'
import {getItemsPreviews, pushItemToSuplier} from '../../../../services/api/ExportOrderServices'
import PropTypes from 'prop-types'
import {getLocalData} from '../../../../services/StorageServices'
import {message} from "antd"
import {Checkbox,} from 'antd'
import broker from "../../OrderBroker"

class PackagePusherInner extends Component {
    state = {
        previews: {},
        error: '',
        force: false,
        isLoading: false,
        isPushing: false,
        changeArtworkData: [],
        shippingCarrier: this.props.fulfillment.shipping_carrier || 'DHL eCommerce'
    }

    componentDidMount() {
        this._fetchItems()
    }

    _fetchItems = async () => {
        const {fulfillment} = this.props
        const {_id: fulfillmentId} = fulfillment

        this.setState({
            isLoading: true,
            error: '',
        })

        try {
            const {success, data, message: messageError} = await getItemsPreviews(fulfillmentId)

            if (!success) {
                throw new Error(messageError)
            }

            const disabledSplit = data.items.filter(item => !!item.outOfStock)
            let errorMessageCustom = ''
            if (data.countryCode && ['BH', 'CY', 'EG', 'IR', 'IQ', 'IL', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', 'SA', 'SY', 'TR', 'AE', 'YE'].includes(data.countryCode) && (!data.phone || !data.email)) {
                errorMessageCustom = 'This order is missing email or phone data! Should check again before pushing! '
            }
            
            const newData = {
                ...data,
                items: data.items.map(i => {
                    if (i.hasOwnProperty('ffmIssue')) {
                        return {
                            ...i,
                            ffmIssue: {
                                ...i.ffmIssue,
                                isCheck: true
                            }
                        }
                    }
                    return i
                }) || []
            }

            this.setState({
                error: errorMessageCustom + (!!disabledSplit && disabledSplit.length ? disabledSplit[0].outOfStock : ''),
                isLoading: false,
                previews: newData,
            }, () => {
                const hasWarning = newData.items.filter(item => item.level === 'productvariant' || (item.hasOwnProperty('pack') && item.pack > 1))
                if (hasWarning.length > 0) {
                    this.setState({
                        error: 'Order using artworks with type as variant, please check quantity again before push.'
                    })
                }
            })
        } catch (e) {
            this.setState({
                error: e.message || e,
                isLoading: false,
            })
        }
    }

    _handleClickCancel = e => {
        e.preventDefault()
        this.props.onClose()
    }

    _handleChangePreviews = items => {
        this.setState({
            previews: Object.assign({}, items),
        })
    }

    _handleChangeItems = items => {
        const {previews} = this.state

        const updateItems = Object.assign({}, previews, {
            items: items
        })

        this.setState({
            previews: Object.assign({}, updateItems),
        })
    }

    _removeItem = index => {
        const {previews} = this.state
        const {items} = previews

        const filtered = items.filter((item, idx) => index !== idx)

        const updateItems = Object.assign({}, previews, {
            items: filtered
        })

        this.setState({
            previews: Object.assign({}, updateItems),
        })
    }

    _onChangeArtworkData = (data) => {
        const {changeArtworkData} = this.state
        const dataChange = changeArtworkData.filter(item => item._id !== data._id)

        this.setState({
            changeArtworkData: [...dataChange, {...data}]
        }, () => {
            this.setState({
                error: 'The design of supplier PF has been optimized for production. If you change the link design, this optimal function will not apply.'
            })
        })
    }

    _handleChangeShippingCarrier = (value) => {
        this.setState({
            shippingCarrier: value
        })
    }

    _handleCheckBoxIssueReplace = (id, checked) => {
        const { previews } = this.state
        const { items = [] } = Object.assign({}, previews)

        const newItems = items.map(item => {
            if (item._id === id && item.hasOwnProperty('ffmIssue')) {
                return {
                    ...item,
                    ffmIssue: {
                        ...item.ffmIssue,
                        isCheck: checked
                    }
                }
            }
            return item
        })

        this.setState({
            previews: {
                ...previews,
                items: newItems
            }
        })
    }

    _handleClickPush = async e => {
        e.preventDefault()

        this.setState({
            isPushing: true,
            error: ''
        })

        const { previews, force, changeArtworkData, shippingCarrier } = this.state
        const { fulfillment, supplierSelected } = this.props
        const { _id: fulfillmentId } = fulfillment
        const { sku_prefix = '' } = Object.assign({}, supplierSelected)
        const {items = []} = Object.assign({}, previews)

        try {
            const supliers = [
                {
                    'sku_prefix': '1C',
                    'sheet_url': getLocalData(`exportGgDrv.link.1c`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.1c`, '')
                },
                {
                    'sku_prefix': 'BG',
                    'sheet_url': getLocalData(`exportGgDrv.link.basegift`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.basegift`, '')
                },
                {
                    'sheet_url': getLocalData(`exportGgDrv.link.other`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.other`, '')
                },
            ]

            const issueItems = items.map(item => {
                if (item.hasOwnProperty('ffmIssue') && item.ffmIssue.isCheck) return item.ffmIssue._id
            }).filter(Boolean) || []

            const params = {
                supplier_sheets: supliers,
                ...previews,
                force,
                changeArtworkData,
                shipping_carrier: shippingCarrier
            }

            if (sku_prefix !== "1C" && issueItems.length > 0) params.issueItems = issueItems

            const { success, message: error } = await pushItemToSuplier(fulfillmentId, params)

            if (!success) {
                return this.setState({
                    isPushing: false, error: error
                })
            }

            this.setState({
                error: '',
                isPushing: false,
            }, () => {
                message.success('Package push success!')
                broker.publish("TRACKING_UPDATED")
                broker.publish("ORDER_REFRESH_ORDER")
                this.props.onClose()
            })
        } catch (err) {
            this.setState({
                error: err.message || err,
                isPushing: false,
            })
        }
    }

    _handleChangeForcePush = (e) => {
        this.setState({
            force: e.target.checked
        })
    }

    _handleDuplicateItem = (item) => {
        this.setState(preState => ({
            ...preState,
            previews : {
                ...preState.previews,
                items : [
                    ...preState.previews.items,
                    item
                ]
            }
        }))
    }

    render() {
        const {fulfillment, supplierSelected, preference} = this.props
        const {name, items, factory_info = {}} = fulfillment
        const totalItems = Array.isArray(items) ? items.length : 0
        const {error, isLoading, previews, isPushing, force, shippingCarrier} = this.state

        const {items: itemsToPush, needGenMockup, mockupStatus} = previews
        const itemsHaveMockupUrl = itemsToPush && itemsToPush.length > 0 ? itemsToPush.filter(item => item.isMockUpUrl === false) : []

        return (
            <Fragment>
                <ModalHeader>
                    <div className="Name"><strong>{name}</strong> - <strong>{totalItems}</strong> items</div>
                </ModalHeader>
                <ModalBody>
                    {
                        !!isLoading && <div className="Loading">Loading...</div>
                    }

                    {
                        (!isLoading && previews !== undefined) &&
                        <div className="Editor">
                            <PackageAddressEditor
                                supplierSku={supplierSelected.sku_prefix}
                                shippingCarrier={shippingCarrier}
                                previews={previews}
                                onChangePreview={this._handleChangePreviews}
                                onChangeShippingCarrier={this._handleChangeShippingCarrier}
                                factoryInfo={factory_info}
                            />

                            {
                                itemsToPush !== undefined && (
                                    <PackageListItemsEditor
                                        supplierSku={supplierSelected.sku_prefix}
                                        items={itemsToPush}
                                        onChangeItems={this._handleChangeItems}
                                        onChangeArtworkData={this._onChangeArtworkData}
                                        onRemoveItem={this._removeItem}
                                        preference={preference}
                                        handleDuplicateItem={this._handleDuplicateItem}
                                        onChangeCheckBoxIssueReplace={this._handleCheckBoxIssueReplace}
                                    />
                                )
                            }
                        </div>
                    }

                    {
                        !!error && <div className="Error text-danger mt-3">{error}</div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="footer-message">
                            {
                                !!needGenMockup && ( !mockupStatus || mockupStatus !== 'completed' ) && itemsHaveMockupUrl.length > 0 ?
                                    <div className="Error text-danger">Generate mockups for designs in progress. You need to check before pushing this order.</div> : ''
                            }
                        </div>
                        <div className="d-flex align-items-center footer-button">
                            <div className="check_force d-flex justify-content-end mr-3">
                                <Checkbox
                                    checked={force}
                                    onChange={this._handleChangeForcePush}
                                >
                                </Checkbox>
                                <span className="ml-2">
                                    Do you want to push again?
                                </span>
                            </div>
                            <button className="btn btn-secondary" disabled={isPushing} onClick={this._handleClickCancel}>
                                Cancel
                            </button>
                            <button onClick={this._handleClickPush} className="btn btn-success ml-3" disabled={isPushing}>
                                {isPushing ? 'Pushing...' : 'Push'}
                            </button>
                        </div>
                    </div>
                </ModalFooter>
            </Fragment>
        )
    }
}

PackagePusherInner.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    fulfillment: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdateTotalPushToProduction: PropTypes.func.isRequired,
}

export default PackagePusherInner
