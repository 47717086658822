export const allowStore = ['m825fdn', 'e6tfim4', '2u8dh9j', 'xlvod42', 'yt6tepe']
export const allowType = [
    'ACRYLIC_PLAQUE',
    'GRADUATION_STOLE',
    'MICA_KEYCHAIN_V2_3D',
    'CUSTOM_MICA_PLAQUE',
    'CUSTOM_ACRYLIC_PLAQUE',
    'CUSTOM_WOOD_SIGN',
    'HAWAII_SHIRT_UID',
    'PASSPORT_WALLET',
    'STEEL_KEYCHAIN',
    'PHONECASE_FC',
    'SOCKS_3D',
    'T-SHIRT',
    '1_CERAMIC_ORNAMENT',
    '2_CERAMIC_ORNAMENT',
    '1_ALUMINIUM_ORNAMENT',
    '2_ALUMINIUM_ORNAMENT',
    '1L_WOOD_ORNAMENT_UID',
    'MICA_ORNAMENT_UID',
]