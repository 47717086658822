import React from 'react'
import { Checkbox, Input, Select } from 'antd'

const GeneralPage = (props) => {

  const {
    zone, shipping_plan, supplier,
    store,
    handleCheckAllStore,
    allStore,
    handleChangeAddStore,
  } = props

  const onChange = (e) => {
    handleCheckAllStore()
  }

  const onChangeStore = (e) => {
    handleChangeAddStore(e.target.value)
  }

  return (
    <div className="GeneralPage py-4 px-5">
      <div className="General_Select">
        <div className="itemFilter mb-3">
          <div className="font-weight-500 LabelFilter mb-1">Supplier<span className="text-danger ml-1">*</span></div>
          <div className="ShippingPlansSelector w-auto">
            <Select
              size={'large'}
              allowClear
              showArrow
              defaultValue={supplier.name || ''}
              value={supplier.name || ''}
              style={{ width: '350px' }}
              placeholder='Choose supplier'
              disabled
            >
            </Select>
          </div>
        </div>

        <div className="itemFilter mb-3">
          <div className="font-weight-500 LabelFilter mb-1">Shipping plan<span className="text-danger ml-1">*</span></div>
          <div className="ShippingPlansSelector w-auto">
            <Select
              size={'large'}
              allowClear
              showArrow
              defaultValue={shipping_plan.title || ''}
              value={shipping_plan.title || ''}
              style={{ width: '350px' }}
              placeholder='Choose shipping plan'
              disabled
            >
            </Select>
          </div>
        </div>

        <div className="itemFilter mb-3">
          <div className="font-weight-500 LabelFilter mb-1">Zone<span className="text-danger ml-1">*</span></div>
          <div className="ShippingPlansSelector w-auto">
            <Select
              size={'large'}
              allowClear
              showArrow
              defaultValue={zone.title || ''}
              value={zone.title || ''}
              style={{ width: '350px' }}
              placeholder='Choose zone'
              disabled
            >
            </Select>
          </div>
        </div>
      </div>

      <div className="General_store mt-5">
        <div className="Store_all d-flex flex-column">
          <span className="font-weight-bold">Choose All or input value</span>
          <Checkbox className="py-3 w-25" checked={allStore} disabled onChange={onChange}>All store</Checkbox>
        </div>
        <div className="Create_store">
          <Input.Group compact >
            <Input style={{ width: 350 }}
              placeholder="Store name"
              disabled
              value={!!store ? store : ''}
              onChange={onChangeStore}
              className="py-2 rounded"
            />
          </Input.Group>
        </div>

      </div>
    </div>
  )
}

export default GeneralPage