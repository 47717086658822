import React, { useState, useEffect } from 'react'
import { searchProducts } from '../../../../services/api/ProductsServices'
import { message, Popover, Tooltip, Spin } from 'antd'
import ObjectInspector from "react-object-inspector"
import { Link } from 'react-router-dom'
import AddChildProductsModal from './AddChildProductsModal'

function ChildProducts(props) {
    const { productId, productDetail, reset, disableAll } = { ...props }
    const { child_products = [] } = { ...productDetail }
    const arrChildProducts = child_products.length > 0 ? child_products.map(i => i.product_id) : []
    const [listProducts, setListProducts] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isEditChild, setIsEditChild] = useState(false)

    const getListProduct = async () => {
        setLoading(true)
        try {
            const { data, success, message: mess } = await searchProducts({ limit: 10000 })
            if (!success) {
                throw new Error(mess)
            }
            const { products = [] } = { ...data }
            const newListProducts = products.length > 0 ? products.map(option => ({
                label: option.title,
                product_id: option._id,
                value: option._id
            })).filter(i => !!i && i.label) : []
            setListProducts(newListProducts)
        } catch (error) {
            message.error(error.message || 'Unknown error')
        } finally {
            setLoading(false)
        }
    }

    const handleShowModal = (type) => {
        if (type === 'Edit') {
            setIsEditChild(true)
        } else {
            setIsEditChild(false)
        }
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const contentPopover = (data) => {
        return (
            <ObjectInspector data={data} name='mapping_artwork_sides' />
        )
    }

    useEffect(() => {
        getListProduct()
    }, [])

    const listChilds = child_products.length > 0 ? listProducts.filter(i => arrChildProducts.includes(i.product_id))
        .map(i => ({
            ...i,
            mapping_artwork_sides: child_products.filter(child => child.product_id === i.product_id)[0]['mapping_artwork_sides'],
            edit: false
        })) : []

    const disableAdd = loading || disableAll || !(listProducts.length > 0)

    return (
        <div className='ChildProducts mt-3'>
            <div className="SectionInner">
                <div className="headerContent d-flex justify-content-between align-items-center mb-3">
                    <div className="SectionHeading">Child products</div>
                    {listChilds.length > 0 &&
                        <Tooltip placement='topRight' title='Child products detail'>
                            <div
                                className="btn btn-link cursor-pointer p-0"
                                onClick={() => handleShowModal('Detail')}
                                disabled={disableAdd}
                            >
                                Detail
                            </div>
                        </Tooltip>
                    }
                </div>
                <Spin spinning={loading} tip='Loading'>
                    <div className='mb-4' style={{ maxHeight: '128px', overflow: 'auto' }}>
                        {listChilds.map((i, index) => {
                            return (
                                <div className="mb-2 d-flex justify-content-between align-items-center" key={`ItemChild_${index}`}>
                                    <Link to={`/a/products/${i.value}`} target='_blank'>
                                        {i.label &&
                                            <Tooltip placement="topRight" title={i.label}>
                                                <span className='cursor-pointer'>{i.label.length > 37 ? i.label.substring(0, 37) + '...' : i.label}</span>
                                            </Tooltip>
                                        }
                                    </Link>
                                    <Popover
                                        placement="topLeft"
                                        className="CustomPopover"
                                        content={contentPopover(i.mapping_artwork_sides)}
                                        title={`Mapping artwork sides`}
                                        trigger="click"
                                    >
                                        <Tooltip placement="topRight" title='Mapping artwork sides'>
                                            <i className='ti-info-alt cursor-pointer'></i>
                                        </Tooltip>
                                    </Popover>
                                </div>
                            )
                        })
                        }
                    </div>
                    {!disableAll &&
                        <button
                            className="btn btn-dark AddMore"
                            onClick={() => handleShowModal('Edit')}
                            disabled={disableAdd}
                            style={{ cursor: disableAdd ? 'not-allowed' : 'pointer' }}
                        >
                            Add more
                        </button>
                    }
                    {listProducts.length > 0 &&
                        <AddChildProductsModal
                            visible={showModal}
                            closeModal={handleCloseModal}
                            listChilds={listChilds}
                            listProducts={listProducts}
                            reset={reset}
                            productId={productId}
                            isEditChild={isEditChild}
                            disableAll={disableAll}
                        />
                    }
                </Spin>
            </div>
        </div>
    )
}

export default ChildProducts