import React, {Component} from 'react'
import PropTypes from 'prop-types'
import PackageSingleItemEditor from "./PackageSingleItemEditor"
import {Table} from "reactstrap"
import HeadItems from "./previewItems/HeadItems"


class PackageListItemsEditor extends Component {
    _handleEditItem = (item, index) => {
        const {items} = this.props
        const updatedItems = items.map((_item, _index) => {
            if (_index !== index) return _item

            return item
        })

        this.props.onChangeItems(updatedItems)
    }

    _checkDisableRemoveItem = (items) => {
        if (items.length === 2) {
            const hasBranding = items.filter(item => item.productLabel === 'branding')

            if (hasBranding.length === 1) {
                return true
            }
        } else if (items.length === 1) {
            return true
        }

        return false
    }

    render() {
        const {items, ...rest} = this.props
        const totalItems = items.length
        const disableRemove = this._checkDisableRemoveItem(items)

        return (
            <div className="PackageListItemsEditor">
                <div className="HeadingTitle">Items</div>
                <Table responsive striped>
                    <HeadItems {...rest}/>
                    <tbody>
                    {
                        items.map((item, index) => {
                            return (
                                <PackageSingleItemEditor
                                    {...rest}
                                    key={index}
                                    totalItem={totalItems}
                                    index={index}
                                    item={item}
                                    disableRemove={disableRemove}
                                    onChange={this._handleEditItem}
                                />
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}

PackageListItemsEditor.propTypes = {
    items: PropTypes.array.isRequired,
    onChangeItems: PropTypes.func.isRequired,
}

export default PackageListItemsEditor
