import React, {Component} from 'react'
import {getOrderUnfufillItems} from "../../../services/api/OrdersServices"
import OrderItemTable from "./OrderItemTable"
import PropTypes from 'prop-types'
import OrderActions from "./OrderActions"
import broker from "../OrderBroker"

class OrderItems extends Component {
    state = {
        orderItems: null,
        fulfillable: false,
        error: '',
    }

    _subscription1 = null
    _subscription2 = null
    _subscription3 = null
    _subscription4 = null
    _subscription5 = null

    componentDidMount() {
        this._fetchOrderItems()
        this._subscription1 = broker.subscribe('PRODUCT_MAP_UPDATED', this._handleProductMapUpdated)
        this._subscription2 = broker.subscribe("UNREJECT_ORDER_ITEMS", this._handleProductMapUpdated)
        this._subscription3 = broker.subscribe("ORDER_RESTORE", this._handleProductMapUpdated)
        this._subscription4 = broker.subscribe("UNDO_REJECTED_ORDER_ITEM", this._handleProductMapUpdated)
        this._subscription5 = broker.subscribe("FETCH_ORDER_ITEM", this._fetchOrderItems)
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription1)
        broker.unsubscribe(this._subscription2)
        broker.unsubscribe(this._subscription3)
        broker.unsubscribe(this._subscription4)
        broker.unsubscribe(this._subscription5)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {orderItems} = this.state

        if (prevState.orderItems !== orderItems) {
            this._handleGetInfoProductItem()
        }
    }

    _fetchOrderItems = async () => {
        try {
            const {orderId} = this.props
            const {success, message, data} = await getOrderUnfufillItems(orderId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const fulfillable = data.find(item => {
                const productLineVariant = Object.assign({}, item.productLineVariant)

                return Object.entries(productLineVariant).length <= 0
            })

            return this.setState({
                orderItems: data,
                fulfillable: fulfillable === undefined,
                error: '',
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    _handleProductMapUpdated = () => {
        this._fetchOrderItems()
    }

    _handleGetInfoProductItem = () => {
        const {onGetTotalBaseCost, onGetCurrencyPrice, onGetStatusMapping, onTotalBaseCostFromStore} = this.props
        const {orderItems} = this.state
        let statusMap = false
        const baseCostArr = orderItems.map(item => {
            const {productLineVariant, quantity} = item

            if(!productLineVariant.hasOwnProperty('product')){
                return 0
            }

            statusMap = true

            const {base_cost, custom_base_cost, currency, price:priceVar} = productLineVariant

            const priceStore = priceVar ? priceVar.price : ''

            let price = base_cost * quantity
            onTotalBaseCostFromStore(false)

            if(priceStore){
                price = priceStore * quantity
                onTotalBaseCostFromStore(true)
            }

            if(custom_base_cost !== undefined && custom_base_cost !== null && custom_base_cost !== ''){
                price = custom_base_cost * quantity
                onTotalBaseCostFromStore(false)
            }

            onGetCurrencyPrice(currency)

            return price
        })

        const baseCostSum = baseCostArr.reduce((accumulator, value) => {
            return accumulator + value;
        }, 0);

        onGetStatusMapping(statusMap)
        onGetTotalBaseCost(baseCostSum)
    }

    render() {
        const {orderItems, fulfillable} = this.state
        const {
            orderId,
            fulfillmentStatus,
            storeMode,
            packageName,
            tags,
            namespace,
            language,
            languageError,
            orderType,
            customShippingCost,
            setArtWorkLevel,
            setArtWorkPack,
            setArtworks,
            order,
        } = this.props

        if (!orderItems || orderItems.length <= 0) {
            return null
        }

        return (
            <div className="OrderItems mb-3">
                <div className="SectionInner">
                    <h3 className="SectionHeading mb-3">Unfulfilled</h3>

                    <OrderItemTable
                        order={order}
                        namespace={namespace}
                        tags={tags}
                        packageName={packageName}
                        fulfillmentStatus={fulfillmentStatus}
                        storeMode={storeMode}
                        orderId={orderId}
                        orderItems={orderItems}
                        language={language}
                        languageError={languageError}
                        _fetchOrderItems={this._fetchOrderItems}
                        orderType={orderType}
                        setArtWorkLevel={setArtWorkLevel}
                        setArtWorkPack={setArtWorkPack}
                        setArtworks={setArtworks}
                    />

                    <OrderActions
                        orderItems={orderItems}
                        tags={tags}
                        fulfillable={fulfillable}
                        orderId={orderId}
                        fulfillmentStatus={fulfillmentStatus}
                        _fetchOrderItems={this._fetchOrderItems}
                        orderType={orderType}
                        customShippingCost={customShippingCost}
                    />
                </div>
            </div>
        )
    }
}

OrderItems.propTypes = {
    namespace: PropTypes.string,
    orderId: PropTypes.string.isRequired,
    storeMode: PropTypes.string.isRequired,
    packageName: PropTypes.string,
    tags: PropTypes.any
}

export default OrderItems
