import React, { useState, useRef } from 'react'
import { Modal, Select, Tooltip, Popconfirm, message, Button } from 'antd'
import { addChildProducts } from '../../../../services/api/ProductsServices'

function AddChildProductsModal(props) {
    const { visible, closeModal, listChilds = [], listProducts = [], reset, productId, isEditChild } = { ...props }
    const [data, setData] = useState(props.listChilds)

    const bottomRef = useRef(null)

    const handleAddChild = async () => {
        const newData = [...data]
        newData.push({
            "label": "",
            "value": "",
            product_id: '',
            "mapping_artwork_sides": {
                ['new_1']: ''
            },
            "edit": true
        })
        await setData(newData)
        bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleEdit = (index) => {
        const newData = [...data]
        newData[index].edit = true
        setData(newData)
    }

    const handleCancelEdit = (index, id) => {
        const checkId = listChilds.map(i => i.product_id).includes(id)
        if (!checkId) {
            const newData = [...data.filter((i, idx) => idx != index)]
            setData(newData)
        } else {
            const newData = [...data]
            newData[index] = { ...listChilds.filter(i => i.product_id === id)[0] }
            setData(newData)
        }
    }

    const handleDelete = async (index) => {
        const childProducts = data.filter(i => i.product_id).filter((i, idx) => idx !== index).map((i) => {
            const { mapping_artwork_sides = {} } = { ...i }
            const mappingSides = { ...mapping_artwork_sides }
            const arrProductSideNew = Object.keys(mappingSides).filter(i => i.includes('new_'))
            if (arrProductSideNew.length > 0) {
                arrProductSideNew.forEach(side => {
                    delete mappingSides[side]
                })
            }
            return {
                product_id: i.product_id,
                mapping_artwork_sides: mappingSides
            }
        })

        const payload = {
            childProducts: childProducts
        }

        try {
            const { success, data: resData, message: mess } = await addChildProducts(productId, payload)
            if (!success) {
                throw new Error(mess)
            }
            const newData = [...data.filter((i, idx) => idx !== index)]
            setData(newData)
            message.success(`Child Product ${data[index].label} deleted!`)
            reset()
        } catch (error) {
            message.error(error.message)
        }
    }

    const handleCloseModal = () => {
        closeModal()
        setData(listChilds)
    }

    const handleChangeSelect = (index, value) => {
        const newData = [...data]
        const { label: newlabel } = { ...listProducts.find(i => i.product_id === value) }

        newData[index].product_id = value
        newData[index].value = value
        newData[index].label = newlabel
        setData(newData)
    }

    const handleChangeProductSide = (index, value, item, sideIdx) => {
        const newKey = value
        const newData = [...data]
        const { mapping_artwork_sides: MappingSide = {} } = { ...data[index] }
        if (!!newKey) {
            const newMappingSide = {}
            Object.keys(MappingSide).forEach((i, iIdx) => {
                if (iIdx === sideIdx) {
                    newMappingSide[value] = MappingSide[i]
                } else {
                    newMappingSide[i] = MappingSide[i]
                }
            })
            newData[index].mapping_artwork_sides = newMappingSide
        } else {
            newData[index].mapping_artwork_sides = {
                ...data[index].mapping_artwork_sides,
                [`new_${sideIdx + 1}`]: ''
            }
            delete newData[index].mapping_artwork_sides[item]
        }
        setData(newData)
    }

    const handleChangeChildSide = (index, value, item) => {
        const newData = [...data]
        newData[index].mapping_artwork_sides[item] = value
        setData(newData)
    }

    const handleAddSide = (index) => {
        const newData = [...data]
        const arrProductSideNew = data[index].mapping_artwork_sides ?
            Object.keys(data[index].mapping_artwork_sides).filter(i => i.includes('new_')) : []
        const lastNewIndex = arrProductSideNew.length > 0 ? Math.max(...arrProductSideNew.map(i => parseInt(i.split('new_')[1]))) : 0
        const sideNumber = data[index].mapping_artwork_sides ? lastNewIndex + 1 : 1
        newData[index].mapping_artwork_sides = {
            ...data[index].mapping_artwork_sides,
            [`new_${sideNumber}`]: ''
        }
        setData(newData)
    }

    const confirmDelete = (index) => {
        handleDelete(index)
    }

    const handleDeleteSide = (index, item) => {
        const newData = [...data]
        delete newData[index].mapping_artwork_sides[item]
        setData(newData)
    }

    const handleSaveData = async (index) => {
        const childProducts = data.filter(i => i.product_id).map((i) => {
            const { mapping_artwork_sides = {} } = { ...i }
            const mappingSides = { ...mapping_artwork_sides }
            const arrProductSideNew = Object.keys(mappingSides).filter(i => i.includes('new_'))
            if (arrProductSideNew.length > 0) {
                arrProductSideNew.forEach(side => {
                    delete mappingSides[side]
                })
            }
            return {
                product_id: i.product_id,
                mapping_artwork_sides: mappingSides
            }
        })
        const payload = {
            childProducts: childProducts
        }
        try {
            const { success, data: resData, message: mess } = await addChildProducts(productId, payload)

            if (!success) {
                throw new Error(mess)
            }

            const newData = [...data]
            newData[index].edit = false
            setData(newData)
            message.success(`Child products updated!`)
            reset()
        } catch (error) {
            message.error(error.message)
        }
    }

    const checkDisableSaveBtn = (product_id, mapping_artwork_sides) => {
        const checkChild = !product_id || !mapping_artwork_sides
        const checkProductSide = !!mapping_artwork_sides && !Object.keys(mapping_artwork_sides).length
        const checkChildProductSide = !!mapping_artwork_sides && Object.values(mapping_artwork_sides).includes('')
        return checkChild || checkProductSide || checkChildProductSide
    }

    const sideOptions = [
        { label: 'Front', value: 'front' },
        { label: 'Back', value: 'back' },
        { label: 'Sleeve', value: 'sleeve' },
        { label: 'Hood', value: 'hood' },
    ]

    const listChildId = data.length > 0 ? data.map(i => i.product_id) : []

    return (
        <Modal
            title='Child Products'
            visible={visible}
            onCancel={handleCloseModal}
            style={{ minWidth: '900px' }}
            okText='Save'
            className='AddChildProductsModal'
            footer={false}
        >
            <div className="modalContent">
                {!!data && data.length > 0 &&
                    <table className="list-child w-100 table">
                        <thead>
                            <tr>
                                <th style={{ maxWidth: '400px' }}>Child product</th>
                                <th>Product side - Child product side</th>
                                {isEditChild &&
                                    <th></th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((i, index) => (i.edit ?
                                (<tr className="ChildItem  w-100 align-top" key={`ChildItem_${index}`}>
                                    <td className=" mr-3 vertical-align-top">
                                        <Select className=''
                                            placeholder="Child product"
                                            style={{ width: '250px' }}
                                            value={i.product_id || []}
                                            onChange={(value) => handleChangeSelect(index, value)}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                                            }
                                        >
                                            {listProducts.map((productOption, productOptionIdx) => (
                                                <Select.Option
                                                    key={`productOption_${productOptionIdx}`}
                                                    value={productOption.value}
                                                    disabled={listChildId.includes(productOption.value) || productOption.value === productId}
                                                >
                                                    {productOption.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </td>
                                    {i.mapping_artwork_sides ?
                                        <td className="listSide">
                                            {i.mapping_artwork_sides && Object.keys(i.mapping_artwork_sides).map((item, sideIdx) => (
                                                <div className="sideItem d-flex mb-2 align-items-center" key={`Side_${sideIdx}`}>
                                                    <Select
                                                        style={{ width: '150px' }}
                                                        placeholder='Product side'
                                                        value={item.includes('new_') ? [] : (item || [])}
                                                        onChange={(value) => handleChangeProductSide(index, value, item, sideIdx)}
                                                    >
                                                        {sideOptions.map((sideOption, sideOptionIdx) => (
                                                            <Select.Option
                                                                key={`sideOption_${sideOptionIdx}`}
                                                                value={sideOption.value}
                                                                disabled={Object.keys(i.mapping_artwork_sides).includes(sideOption.value)}
                                                            >
                                                                {sideOption.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                    <Select
                                                        className='ml-2'
                                                        style={{ width: '160px' }}
                                                        placeholder='Child product side'
                                                        value={i.mapping_artwork_sides[item] || []}
                                                        disabled={!item || item.includes('new_')}
                                                        options={sideOptions}
                                                        onChange={(value) => handleChangeChildSide(index, value, item)}
                                                    >
                                                        {sideOptions.map((sideOption, sideOptionIdx) => (
                                                            <Select.Option
                                                                key={`sideOption_${sideOptionIdx}`}
                                                                value={sideOption.value}
                                                                disabled={Object.keys(i.mapping_artwork_sides).includes(sideOption.value)}
                                                            >
                                                                {sideOption.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                    <span
                                                        className="ti-close px-3 text-danger cursor-pointer"
                                                        onClick={() => handleDeleteSide(index, item, sideIdx)}
                                                    />
                                                </div>
                                            ))}
                                            {(!i.mapping_artwork_sides || Object.keys(i.mapping_artwork_sides).length < 4) &&
                                                <div
                                                    className='text-primary mt-2 mb-2 d-inline-block cursor-pointer'
                                                    onClick={() => handleAddSide(index)}
                                                >
                                                    + Add side
                                                </div>
                                            }
                                        </td> :
                                        <td>
                                            <div
                                                className='text-primary mt-2 mb-2 d-inline-block cursor-pointer'
                                                onClick={() => handleAddSide(index, i.product_id)}
                                            >
                                                + Add side
                                            </div>
                                        </td>
                                    }
                                    {isEditChild &&
                                        <td className="Action">
                                            <div className='d-flex align-items-start'>
                                                <Button
                                                    type='link'
                                                    className="text-primary"
                                                    onClick={() => handleSaveData(index)}
                                                    disabled={checkDisableSaveBtn(i.product_id, i.mapping_artwork_sides)}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    type='link'
                                                    className="text-secondary"
                                                    onClick={() => handleCancelEdit(index, i.product_id)}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </td>
                                    }
                                </tr>) :
                                (<tr className="ChildItem  w-100 align-top" key={`ChildItem_${index}`}>
                                    <td className=" mr-3 vertical-align-top text-break" style={{ maxWidth: '400px' }}>
                                        {i.label &&
                                            <Tooltip title={i.label}>
                                                {i.label}
                                            </Tooltip>
                                        }
                                    </td>
                                    <td className="listSide">
                                        {i.mapping_artwork_sides && Object.keys(i.mapping_artwork_sides).map((item, sideIdx) => (
                                            <div className="sideItem d-flex mb-2 text-capitalize" key={`Side_${sideIdx}`}>
                                                {
                                                    item.includes('new_') ? '' : (item || '') +
                                                        (i.mapping_artwork_sides[item] ? ` - ${i.mapping_artwork_sides[item]}` : '')
                                                }
                                            </div>
                                        ))}
                                    </td>
                                    {isEditChild &&
                                        <td className="Action">
                                            <div className=' d-flex align-items-start'>
                                                <Button type='link' className="text-secondary" onClick={() => handleEdit(index)}>Edit</Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={'Are you sure to delete this child product?'}
                                                    onConfirm={() => confirmDelete(index)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button type='link' className="text-danger">Delete</Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    }
                                </tr>)
                            ))
                            }
                        </tbody>
                    </table>
                }
                <div ref={bottomRef}></div>
            </div>
            {isEditChild &&
                <div
                    className='text-primary mt-3 mb-2 d-inline-block cursor-pointer'
                    onClick={handleAddChild}
                >
                    + Add new child product
                </div>
            }
        </Modal>
    )
}

export default AddChildProductsModal