import React from 'react'
import FFMPackage from './FFMPackage'

export const initialState = {
    isLoading: true,
    order: null,
    packages: [],
    message: null,
    errors: null,
    suppliers: [],
    suppliersIdsRequireShippingCarrier: [],
    isProcessing: false,
    isProcessed: false,
    isOrderHaveUpdate: false,
}

const resetForm = (state) => {
    return {...state, isLoading: true, isProcessing: false, isProcessed: false, order: null, packages: [], message: null, errors: null, isOrderHaveUpdate: false,}
}

const setLoading = (state, payload) => {
    return {...state, isLoading: payload}
}

const updateForm = (state, payload) => {
    return {
        ...state,
        order: payload.order,
        isLoading: typeof payload.isLoading !== 'undefined' ? payload.isLoading : state.isLoading,
        packages: payload.packages.map(item => new FFMPackage({...item, shippingPlan: payload.shippingPlan, order: payload.order})),
        message: payload.message,
        errors: payload.errors,
        isProcessing: false,
        isProcessed: false,
        isOrderHaveUpdate: false,
    }
}

const addPackage = (state) => {
    const {packages} = state
    packages.push(new FFMPackage({}))
    return {...state, packages}
}

const removePackage = (state, payload) => {
    const packages = state.packages.filter(item => item._id !== payload._id)

    return {...state, packages}
}

const addPackageItem = (state, payload) => {
    let {packages} = state
    const {packId, orderItem, fromPackId} = payload
    let fromPack = packages.find(pack => pack._id === fromPackId)

    packages = packages.map(pack => {
        if (pack._id === packId) {
            pack.addItem(orderItem)

            if (pack.items.length === 1 && fromPack) {
                pack.setSupplier(fromPack.supplier)
                pack.setShippingCarrier(fromPack.shippingCarrier)
                pack.setRequireShippingCarrier(fromPack.isRequireShippingCarrier)
            }
        }

        return pack
    })

    return {...state, packages}
}

const removePackageItem = (state, payload) => {
    let {packages} = state
    const {packId, orderItem} = payload

    packages = packages.map(pack => {
        if (pack._id === packId) {
            pack.removeItem(orderItem._id)
        }

        return pack
    })

    return {...state, packages}
}

const updateListSupplier = (state, payload) => {
    let sku_prefixRequire = ['YC', 'DBUS', 'DPCN', 'SPUS', 'FSUS', 'PYUS', 'FSUS', 'MDUS', 'CC']
    let suppliersIdsRequireShippingCarrier = payload.filter(item => sku_prefixRequire.includes(item.sku_prefix)).map(item => item._id)

    return {...state, suppliers: payload, suppliersIdsRequireShippingCarrier}
}

const changeSupplier = (state, payload) => {
    let {packages, suppliersIdsRequireShippingCarrier, order = {}} = state
    const {order_type = ''} = order
    const {pack, supplier, sku_prefix} = payload
    packages = packages.map(item => {
        if (item._id === pack._id) {
            let slug = !!pack.shippingPlan && pack.shippingPlan.slug
            let shippingPlan = !!slug && ['standard','express','fast', 'shipbytiktok'].includes(slug) ? slug : 'standard'
            item.setSupplier(supplier)
            item.setRequireShippingCarrier(suppliersIdsRequireShippingCarrier.includes(supplier))
            if (!item.isRequireShippingCarrier) {
                item.setShippingCarrier(null)
            } else {
                if(sku_prefix==='YC'){
                    item.setShippingCarrier('Standard shipping')
                }
                if(sku_prefix==='DBUS'){
                    item.setShippingCarrier('Standard shipping UPS')
                }
                if(sku_prefix==='DPCN'){
                    item.setShippingCarrier('Standard Delivery')
                }
                if(sku_prefix==='SPUS'){
                    item.setShippingCarrier(shippingPlan === 'fast' ? 'priority' : shippingPlan === 'express' ? 'express' : 'standard')
                }
                if(sku_prefix==='FSUS'){
                    let shippingCarrier = 'FirstClass'

                    if(order_type === "TIKTOK") shippingCarrier = 'Tiktok2Days'
                    if(shippingPlan === "fast" && order_type !== "TIKTOK") shippingCarrier = 'Priority'
                    // if((shippingPlan === 'standard' || shippingPlan === "shipbytiktok") && order_type === "TIKTOK") shippingCarrier = 'Rush Production Tiktok Standard'
                    // if(order_type === "TIKTOK" && shippingPlan === "fast") shippingCarrier = "Rush Production Tiktok Priority" 
                    // if(order_type === "TIKTOK" && shippingPlan === "shipbytiktok") shippingCarrier = 'Tiktok2Days'
            
                    item.setShippingCarrier(shippingCarrier)
                }
                if(sku_prefix==='PYUS'){
                    let shippingCarrier = shippingPlan
                    if(shippingPlan === "shipbytiktok" && order_type === "TIKTOK") shippingCarrier = 'standard'
                    if(shippingPlan === "fast") shippingCarrier = 'priority'

                    item.setShippingCarrier(shippingCarrier)
                    // item.setShippingCarrier(shippingPlan === 'fast' ? 'priority' : shippingPlan)
                }
                if(sku_prefix==='MDUS'){
                    const shippingCarrier = shippingPlan === "standard" ? "standard" : shippingPlan === "fast" ? "priority" : "express"
                    item.setShippingCarrier(shippingCarrier)
                }
                if(sku_prefix==='CC'){
                    item.setShippingCarrier(shippingPlan === 'fast' ? 'priority' : shippingPlan)
                }
            }
        }

        return item
    })

    return {...state, packages}
}

const changeShippingCarrier = (state, payload) => {
    let {packages} = state
    const {pack, shippingCarrier} = payload

    packages = packages.map(item => {
        if (item._id === pack._id) {
            pack.setShippingCarrier(shippingCarrier)
        }

        return item
    })

    return {...state, packages}
}

const setAllowFulfill = (state, payload) => {
    let {packages} = state
    const {pack, status} = payload

    packages = packages.map(item => {
        if (item._id === pack._id) {
            pack.setAllowFulfill(status)
        }

        return item
    })

    return {...state, packages}
}

const changeProcessing = (state, payload) => {
    return {...state, isProcessing: payload}
}

const changeProcessed = (state, payload) => {
    return {...state, isProcessed: payload}
}

const checkIsOrderHaveUpdate = (state, payload) => {
    return {...state, isOrderHaveUpdate: payload}
}

const setStatus = (state, payload) => {
    let {packages} = state
    const {pack, status, message, details} = payload

    packages = packages.map(item => {
        if (item._id === pack._id) {
            pack.setStatus(status, message, details)
        }

        return item
    })

    return {...state, packages}
}

const splitItemQuantity = (state, payload) => {
    let {packages} = state
    const {pack, orderItem, quantity} = payload
    let newItem

    packages = packages.map(p => {
        if (p._id === pack._id) {
            newItem = p.splitQuantity(orderItem, quantity)
        }

        return p
    })

    if (newItem) {
        const newPack = new FFMPackage({})
        newPack.addItem(newItem)
        newPack.setSupplier(pack.supplier)
        newPack.setShippingCarrier(pack.shippingCarrier)
        newPack.setRequireShippingCarrier(pack.isRequireShippingCarrier)
        packages.push(newPack)
    }

    packages = packages.filter(p => !!p.items.length)

    return {...state, packages}
}

export const reducer = (state, action) => {
    const {type, payload} = action
    switch (type) {
        case 'UPDATE_FORM':
            state = updateForm(state, payload)
            break

        case 'RESET_FORM':
            state = resetForm(state)
            break

        case 'SET_LOADING':
            state = setLoading(state, payload)
            break

        case 'ADD_PACKAGE':
            state = addPackage(state, payload)
            break

        case 'REMOVE_PACKAGE':
            state = removePackage(state, payload)
            break

        case 'ADD_PACKAGE_ITEM':
            state = addPackageItem(state, payload)
            break

        case 'REMOVE_PACKAGE_ITEM':
            state = removePackageItem(state, payload)
            break

        case 'UPDATE_LIST_SUPPLIERS':
            state = updateListSupplier(state, payload)
            break

        case 'CHANGE_SUPPLIER':
            state = changeSupplier(state, payload)
            break

        case 'CHANGE_SHIPPING_CARRIER':
            state = changeShippingCarrier(state, payload)
            break

        case 'SET_ALLOW_FULFILL':
            state = setAllowFulfill(state, payload)
            break

        case 'CHANGE_STATUS':
            state = setStatus(state, payload)
            break

        case 'CHANGE_PROCESSING':
            state = changeProcessing(state, payload)
            break

        case 'CHANGE_PROCESSED':
            state = changeProcessed(state, payload)
            break

        case 'SPLIT_QUANTITY':
            state = splitItemQuantity(state, payload)
            break

        case 'IS_ORDER_HAVE_UPDATE':
            state = checkIsOrderHaveUpdate(state, payload)  
            break

        default:
            break
    }

    window.state = state

    return state
}

export const OrderFulfillmentContext = React.createContext({
    state: initialState,
    dispatch: reducer,
})

export const useOrderFulfillment = () => React.useContext(OrderFulfillmentContext)
