import React, {useState, createRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {setupExport, getExportSetup} from '../../../../services/api/ExportOrderServices'
import {getLocalData, setLocalData} from '../../../../services/StorageServices'
import {notification} from "antd"

const _getFromLocalData = (props) => {
    const supplier = getLocalData('exportGgDrv.supplier') || (!!props && props.hasOwnProperty('supplier') && props.supplier) || '1c'
    const name = getLocalData(`exportGgDrv.name.${supplier}`, '')
    const link = getLocalData(`exportGgDrv.link.${supplier}`, '')

    return {
        supplier,
        name,
        link,
    }
}


function SetupExportModal(props) {
    let input = createRef()
    const [_form, _setForm] = useState(_getFromLocalData(props))
    const [_exportInfo, _setExportInfo] = useState({})
    const [_loading, _setLoading] = useState(false)
    const {open, onToggle} = props

    useEffect(() => {
        _fetchSetupInfo()
    }, [])

    useEffect(() => {
        if (open) input.focus()
    }, [open])

    const _fetchSetupInfo = async () => {
        try {
            const {success, data} = await getExportSetup()
            if (!success) return
            _setExportInfo({..._exportInfo, ...data})
        } catch (e) {
            console.log(e.message)
        }
    }

    const _onToggle = () => {
        onToggle()
        _setForm(_getFromLocalData())
    }

    const _onSubmit = (e) => {
        e.preventDefault()
        _submit()
    }

    const _onClickSubmit = () => {
        _submit()
    }

    const _submit = async () => {
        _setLoading(true)

        try {
            const {link, name, supplier} = _form
            const {success, message} = await setupExport({link, name, supplier})
            _setLoading(false)

            if (!success) return alert(message)

            setLocalData('exportGgDrv.supplier', supplier)
            setLocalData(`exportGgDrv.name.${supplier}`, name)
            setLocalData(`exportGgDrv.link.${supplier}`, link)
            _onToggle()

            notification.success({
                message: 'Setup success',
                description: 'Setup info export google sheet success.',
            })
        } catch (e) {
            _setLoading(false)
            alert(e.message)
        }
    }

    const _onChangeInput = (key) => (e) => {
        const {value} = e.target
        _setForm({
            ..._form,
            [key]: value,
        })
    }


    return (
        <Modal isOpen={open} toggle={_onToggle} className="TrackingModal">
            <ModalHeader toggle={_onToggle}>
                Link to Google Sheet
            </ModalHeader>
            <ModalBody>
                <form onSubmit={_onSubmit} className="mb-3">
                    <label htmlFor="tracking_link">
                        Link
                    </label>
                    <input
                        id="tracking_link" value={_form.link || ''} ref={r => input = r}
                        className="form-control mb-2" onChange={_onChangeInput('link')}
                    />

                    <label htmlFor="tracking_name">
                        Name
                    </label>
                    <input
                        id="tracking_name" value={_form.name || ''}
                        className="form-control mb-2" onChange={_onChangeInput('name')}
                    />

                    <label htmlFor="tracking_name">
                        Supplier
                    </label>
                    <select className="form-control"
                            value={_form.supplier || ''} onChange={_onChangeInput('supplier')}>
                        <option value="1c">1C</option>
                        <option value="basegift">BaseGift</option>
                        <option value="other">Other</option>
                        {/* <option disabled value="cailian">Cailian Yoco (YC)</option>
                        <option disabled value="zimo">Zimo (ZM)</option>
                        <option disabled value="teezily">Teezily (TZL)</option>
                        <option disabled value="pleu">PL EU</option>
                        <option disabled value="cw">CW</option> */}
                        <option value="bfus">Order BFUS</option>
                        <option value="dpcn">Order DPCN</option>
                        {/* <option disabled value="dbus">Order DBUS</option> */}
                        <option value="dtus">Order DTUS</option>
                        <option value="spus">Order SPUS</option>
                        <option value="wlus">Order WLUS</option>
                        <option value="fsus">Order FSUS</option>
                        <option value="dfus">Order DFUS</option>
                        <option value="efus">Order EFUS</option>
                        <option value="ucus">Order UCUS</option>
                        <option value="glus">Order GLUS</option>
                        <option value="tcvn">Order TCVN</option>
                        <option value="mcus">Order MCUS</option>
                        <option value="shipment-status">Shipment status</option>
                    </select>
                </form>
                <div>
                    Share your Google Sheet to this
                    account:
                    <br/>
                    <strong>{_exportInfo.email}</strong>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-outline-primary" onClick={_onToggle}>
                    Cancel
                </button>
                <button
                    className="btn btn-primary" disabled={_loading}
                    onClick={_onClickSubmit}
                >
                    {_loading && <span className="spinner-border spinner-border-sm mr-2"/>}
                    <span>{_loading ? 'Saving...' : 'Save'}</span>
                </button>
            </ModalFooter>
        </Modal>
    )
}

SetupExportModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
}

export default SetupExportModal
