import React, {Component} from 'react'
import OrdersTable from './OrdersTable'
import {searchOrders} from '../../../services/api/OrdersServices'
import Namespace from './filters/Namespace'
import Supplier from './filters/Supplier'
import ImportTrackingContainer from './import-tracking/ImportTrackingContainer'
import SetupExport from './setup-export/SetupExport'
import ExportOrders from './export-orders/ExportOrders'
import Platform from './filters/Platform'
import ToggleOrderItems from './filters/ToggleOrderItems'
import ShippingPlan from './filters/ShippingPlan'
import FilterOptions from '../../shared/FilterOptions'
import {
    ARTWORK_STATUS, DATE_TRACKING,
    FULFILLMENT_STATUS, HAS_NOTE,
    INTERNATIONAL_STATUS,
    ORDERS_ARCHIVED,
    PAYMENT_STATUS, PRODUCTION_STATUS, SHIPMENT_STATUS,
    TRACKING_STATUS,
    ORDER_TYPE,
    MAPPING_CONDITION,
    MOCKUP_STATUS, REQUEST_UPDATE
} from '../helper/orderFilters'
import FilterDate from '../../shared/FilterDate'
import SearchGroup from '../../shared/SearchGroup'
import {Pagination, Select} from 'antd'
import {getFiltersStorage, resetFiltersStorage, setFilterStorage} from '../../shared/ManageFiltersState'
import changeQuerySearch from '../../../helpers/routing/changeQuerySearch'
import OrdersResetFilters from './OrdersResetFilters'
import ProductTypes from "./filters/ProductTypes"
import PackageStatus from "./filters/PackageStatus"
import StoreMode from './filters/StoreMode'
import OrderFilterPersonalized from "./OrderFilterPersonalized"
import SaveFilter from "./filters/SaveFilter"
import {getLocalData, setLocalData} from "../../../services/StorageServices"
import OrdersMissFailed from './OrdersMissFailed'
import { parseJwt } from '../../../helpers/parseToken'

const INIT_FILTERS = {
    namespace: '',
    types: '',
    store_mode: '',
    payment_status: '',
    fulfillment_status: '',
    artwork_status: '',
    tracking_status: '',
    show_archive: 'hide_archive',
    shipment_status: '',
    production_status: 'all',
    zone: '',
    paid_at: {},
    fulfilled_at: {},
    completed_at: {},
    code: '',
    tags: '',
    email: '',
    customer_name: '',
    shipping_plan: '',
    suppliers: [],
    platform: '',
    has_note: '',
    package_status: '',
    missing_tracking: '',
    user_mapping_email: '',
    plf_product_title: '',
    mapping_condition_user: '',
    mapping_condition: '',
    order_type: '',
    mockup_status: '',
    request_update_statuses: []
}

class OrdersPage extends Component {
    constructor(props) {
        super(props)

        const filtersStorage = getFiltersStorage('orders')

        this.state = {
            page: this.props.currentPage,
            limit: 20,
            orders: null,
            pages: 1,
            total: 0,
            filter: {
                ...INIT_FILTERS,
                ...filtersStorage,
            },
            displayOrderItem: false,
            error: '',
            reset: false,
            codes: [],
            openQuickFilter: false,
            defaultOption: ''
        }

        this.timer = null
    }

    componentDidMount() {
        this._fetchOrders()
    }

    _handleChangeState = (field, data) => {
        this.setState(() => {
            return {
                ...this.state,
                [field]: data,
            }
        })
    }

    _handleChangeFilterState = (paramFilter, clear, defaultOption) => {
        this.setState(({filter}) => {
            if(Object.keys(paramFilter).includes("request_update_statuses")){
                return {
                    filter: {
                        ...filter,
                        request_update_statuses : paramFilter['request_update_statuses'],
                    },
                    page: 1,
                    reset: false,
                    defaultOption
                }
            }else{
                return {
                    filter: {
                        ...filter,
                        ...paramFilter,
                    },
                    page: 1,
                    reset: false,
                    defaultOption
                }
            }
        }, () => {
            if (this._timeOut) clearTimeout(this._timeOut)
            this._timeOut = setTimeout(async () => {
                await this._fetchOrders()

                changeQuerySearch({page: 1})
                if (clear !== true) setLocalData('ordersPage.QuickReportItem', {}, false, 'sessionStorage')
                setFilterStorage('orders', paramFilter)
            }, 100)
        })
    }

    _fetchOrders = async () => {
        const {page, limit, filter, requestCount} = this.state
        const nextCount = requestCount ? requestCount + 1 : 1

        this.setState({
            loading: true,
            requestCount: nextCount
        })

        try {
            const {data, success, message} = await searchOrders({
                page: page,
                limit: limit,
                ...filter,
                plf_product_title: filter.plf_product_title !== undefined && filter.plf_product_title !== '' ? `^${filter.plf_product_title}$` : ''
            })

            if (nextCount !== this.state.requestCount) {
                return
            }

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const {orders, pages, total} = data

            return this.setState({
                orders: orders,
                pages: pages,
                total: total,
                error: '',
                loading: false,
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    _handlePageChange = current => {
        this.setState({
            page: current,
        }, () => {
            if (this._timeOut) clearTimeout(this._timeOut)
            this._timeOut = setTimeout(async () => {
                changeQuerySearch({page: current}, true)

                this._fetchOrders()
            }, 100)
        })
    }

    _handleShowSizeChange = (current, size) => {
        this.setState({
            limit: size,
            page: 1,
        }, () => {
            if (this._timeOut) clearTimeout(this._timeOut)
            this._timeOut = setTimeout(async () => {
                await this._fetchOrders()
            }, 100)
        })
    }

    _resetFilters = () => {
        this.setState({
            filter: INIT_FILTERS,
            reset: true,
            defaultOption: ''
        }, () => {
            if (this._timeOut) clearTimeout(this._timeOut)
            this._timeOut = setTimeout(async () => {
                this._fetchOrders()

                resetFiltersStorage('orders')
            }, 100)
        })
    }

    _handleChangeCodes = (data) => {
        this.setState({
            codes: data
        })
    }

    _onIsCloseQuickFilter = () => {
        this.setState({
            openQuickFilter: false
        })
    }

    _onIsOpenQuickFilter = () => {
        this.setState({
            openQuickFilter: true
        })
    }

    _onChangeOrderNote = (id, value) => {
        const {orders} = this.state
        const newListOrders = orders.map(item => {
            if (id === item._id) {
                if (item.note.length > 0) {
                    const noteBlock = item.note.split(',')
                    noteBlock.push(value)
                    const newValue = noteBlock.join(' ,')
                    item.note = newValue
                    return item
                } else {
                    item.note = value
                    return item
                }
            }
            return item
        })
        return this.setState({
            orders: newListOrders
        })
    }

    render() {
        const {
            orders,
            page,
            total,
            limit,
            error,
            displayOrderItem,
            loading,
            filter,
            reset,
            codes,
            openQuickFilter
        } = this.state
        const {
            production_status,
            zone,
            paid_at,
            fulfilled_at,
            completed_at,
            code,
            tags,
            email,
            customer_name,
            shipping_plan,
            suppliers,
            store_mode,
            missing_tracking,
            user_mapping_email,
            plf_product_title,
            mapping_condition_user,
            mapping_condition
        } = filter
        const {
            payment_status, fulfillment_status, artwork_status, tracking_status,
            show_archive, shipment_status, platform, namespace, types, has_note, package_status, order_type, mockup_status, request_update_statuses
        } = filter
        const {
            currentPage, listOrderFailed, onIsCloseOrderFailed, openOrderFailed, totalsFailed, handlePageChange, pageOrderMiss, newFBAOrders, 
            FBAUpdateLabel, FBAUpdateBoxInfo, totalFBAWarning, waitingPaidOrders, waitingMappingOrders, waitingFulfillOrders, validateWarnings
        } = this.props

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}

        return (
            <div className="OrdersPage">
                <div className="d-sm-flex justify-content-between align-items-end mb-2">
                    <h1 className="PageTitle mb-0 mr-auto">Orders</h1>
                    <div
                        className='filterWrapper d-flex justify-content-sm-end align-items-end mr-3 mr-lg-0 ml-3 ml-sm-0'>
                        <div className='mr-2'>
                            <OrdersResetFilters onClick={this._resetFilters}/>
                        </div>

                        {
                            permissionsData.hasOwnProperty('ffm_order_save_filter') &&
                            <div className='mr-2'>
                                <SaveFilter filters={filter}/>
                            </div>
                        }
                        {permissionsData.hasOwnProperty('ffm_order_export_order') && 
                            <div className=" mr-2">
                                <SetupExport/>
                            </div>
                        }

                        {
                            permissionsData.hasOwnProperty('ffm_order_import_tracking') && 
                            <ImportTrackingContainer/>
                        }

                        {
                            permissionsData.hasOwnProperty('ffm_order_export_order') && 
                            <ExportOrders filters={{...filter}} code={codes}/>
                        }
                        
                    </div>
                </div>

                <div className='d-sm-flex FiltersTop align-items-end mx-3 mx-lg-0'>
                    <div className='d-flex flex-wrap align-items-baseline'>
                        <div className="mr-3 mb-3">
                            <Supplier suppliers={suppliers} onChangeFilterState={this._handleChangeFilterState}/>
                        </div>

                        <div className='mr-3 mb-3'>
                            <PackageStatus
                                packageStatus={package_status}
                                onChangeFilterState={this._handleChangeFilterState}
                            />
                        </div>

                        <div className="mr-3 mb-3">
                            <Platform platform={platform} onChangeFilterState={this._handleChangeFilterState}/>
                        </div>

                        <Namespace namespace={namespace} onChangeFilterState={this._handleChangeFilterState}/>

                        <ProductTypes types={types} onChangeFilterState={this._handleChangeFilterState}/>

                        <div className="mr-3 mb-3">
                            <StoreMode store_mode={store_mode} onChangeFilterState={this._handleChangeFilterState}/>
                        </div>
                    </div>
                </div>

                <div className="SectionInner mx-3 mx-lg-0">
                    <div className="OrderFilters row align-items-end mb-3">

                        <div className="col-auto mb-3">
                            <FilterOptions
                                options={PAYMENT_STATUS}
                                defaultOption={''}
                                field='payment_status'
                                heading='Payment status:'
                                selectedKey={payment_status}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <FilterOptions
                                options={FULFILLMENT_STATUS}
                                defaultOption={''}
                                field='fulfillment_status'
                                heading='Fulfillment status:'
                                selectedKey={fulfillment_status}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <FilterOptions
                                options={ARTWORK_STATUS}
                                defaultOption={''}
                                field='artwork_status'
                                heading='Artwork status:'
                                selectedKey={artwork_status}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <FilterOptions
                                options={TRACKING_STATUS}
                                defaultOption={''}
                                field='tracking_status'
                                heading='Tracking status:'
                                selectedKey={tracking_status}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>
                    </div>

                    <div className="OrderFilters mb-3 row align-items-end">
                        <div className="col-auto mb-3">
                            <FilterOptions
                                options={MOCKUP_STATUS}
                                defaultOption={''}
                                field='mockup_status'
                                heading='Mockup status:'
                                selectedKey={mockup_status}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>

                        <div className='col-auto mb-3'>
                            <FilterOptions
                                options={INTERNATIONAL_STATUS}
                                defaultOption=''
                                field='zone'
                                heading='Zone:'
                                selectedKey={zone}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>

                        <div className='col-auto mb-3'>
                            <FilterOptions
                                options={PRODUCTION_STATUS}
                                defaultOption={'all'}
                                field='production_status'
                                heading='Production status:'
                                selectedKey={production_status}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>

                        <div className='col-auto mb-3'>
                            <div className='OrderType ml-sm-3'>
                                <FilterOptions
                                    options={ORDER_TYPE}
                                    defaultOption={'hide_archive'}
                                    field='order_type'
                                    heading='Order type:'
                                    layout='select'
                                    selectedKey={order_type}
                                    onChangeOption={this._handleChangeFilterState}
                                />
                            </div>
                        </div>
                        <div className='col-auto mb-3'>
                            <ShippingPlan
                                value={shipping_plan}
                                onChangeFilterState={this._handleChangeFilterState}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <div className='ShipmentStatus'>
                                <FilterOptions
                                    options={HAS_NOTE}
                                    defaultOption={''}
                                    className="ml-sm-3"
                                    field='has_note'
                                    layout='select'
                                    heading='Note:'
                                    selectedKey={has_note}
                                    onChangeOption={this._handleChangeFilterState}
                                />
                            </div>
                        </div>

                        <div className="col-3 mb-3">
                            <div className='RequestUpdate'>
                                <div className='mb-1 Heading ml-sm-3 font-weight-500' style={{fontSize: '.875rem'}}>Request update status:</div>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    className="ml-sm-3"
                                    placeholder="Choose request update status"
                                    value={request_update_statuses}
                                    onChange={value => this._handleChangeFilterState({'request_update_statuses': value})}
                                    options={REQUEST_UPDATE}
                                />
                            </div>
                        </div>

                        {/* <div className='col-auto  mb-3'>
                            <div className='ShipmentStatus'>
                                <FilterOptions
                                    options={SHIPMENT_STATUS}
                                    defaultOption={''}
                                    field='shipment_status'
                                    layout='select'
                                    heading='Shipment status:'
                                    selectedKey={shipment_status}
                                    onChangeOption={this._handleChangeFilterState}
                                />
                            </div>
                        </div> */}
                        {/* <div className="col-auto mb-3">
                            <FilterOptions
                                options={DATE_TRACKING}
                                defaultOption={''}
                                className="ml-sm-3" field='missing_tracking'
                                layout='select' heading='Warning tracking:'
                                selectedKey={missing_tracking}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div> */}
                    </div>

                    <div className='row mb-3 align-items-end'>
                        <div className='col-auto mb-3'>
                            <FilterOptions
                                options={ORDERS_ARCHIVED}
                                defaultOption={'hide_archive'}
                                field='show_archive'
                                heading='Show order:'
                                layout='select'
                                selectedKey={show_archive}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>
                        <div className="ml-33 mb-3 col-md-3">
                            <FilterOptions
                                options={MAPPING_CONDITION}
                                defaultOption={''}
                                field='mapping_condition'
                                layout='select'
                                heading='Mapping condition:'
                                selectedKey={mapping_condition}
                                onChangeOption={this._handleChangeFilterState}
                            />
                        </div>
                        <div className="ml-33 mb-3 col-md-3">
                            <FilterDate
                                heading='Completed at:'
                                field='completed_at'
                                value={completed_at}
                                classDrop={'Left646'}
                                showTime={true}
                                onDatesChange={this._handleChangeFilterState}
                            />
                        </div>
                        <div className='col-auto mb-3'>
                            <ToggleOrderItems onChangeState={this._handleChangeState}/>
                        </div>
                    </div>

                    <div className='row mb-3 align-items-center'>
                        <FilterDate
                            heading='Paid at:'
                            field='paid_at'
                            className="ml-33 mb-3 col-md-3"
                            value={paid_at}
                            showTime={true}
                            classDrop={'Left182'}
                            onDatesChange={this._handleChangeFilterState}
                        />

                        <div className="ml-33 mb-3 col-md-3">
                            <FilterDate
                                heading='Fulfilled at:'
                                field='fulfilled_at'
                                value={fulfilled_at}
                                classDrop={'Left646'}
                                onDatesChange={this._handleChangeFilterState}
                            />
                        </div>

                        <div className='ml-auto mb-3'>
                            <SearchGroup
                                options={
                                    [
                                        {title: 'Number', key: 'code', value: code},
                                        {title: 'Tags', key: 'tags', value: tags},
                                        {title: 'Customer email', key: 'email', value: email},
                                        {title: 'Customer name', key: 'customer_name', value: customer_name},
                                        {title: 'User mapping', key: 'user_mapping_email', value: user_mapping_email},
                                        {
                                            title: 'PLF product title',
                                            key: 'plf_product_title',
                                            value: plf_product_title
                                        },
                                        {title: 'Mapper', key: 'mapping_condition_user', value: mapping_condition_user},
                                    ]
                                }
                                reset={reset}
                                onSearch={this._handleChangeFilterState}
                                defaultOption={this.state.defaultOption}
                                search={tags}
                            />
                        </div>
                    </div>
                    <div className='mb-3'>
                        <strong>{total}</strong> items
                    </div>

                    {
                        error && (
                            <div className="text-danger mb-3">
                                <span className="font-weight-bold">ERROR:</span> {error}
                            </div>
                        )
                    }

                    {
                        !!orders && (
                            <OrdersTable
                                orders={orders}
                                displayOrderItem={displayOrderItem}
                                loading={loading}
                                currentPage={currentPage}
                                onChangeCodes={this._handleChangeCodes}
                                onFetchOrders={this._fetchOrders}
                                handleChangeFilterState={this._handleChangeFilterState}
                                onChangeOrderNote={this._onChangeOrderNote}
                                filters={{...filter}}
                            />
                        )
                    }

                    <Pagination
                        current={page}
                        total={total}
                        pageSize={limit}
                        className='text-right'
                        showSizeChanger
                        pageSizeOptions={['10', '20', '50', '100']}
                        onChange={this._handlePageChange}
                        onShowSizeChange={this._handleShowSizeChange}
                    />
                </div>

                <div className="QuickFilter" onClick={this._onIsOpenQuickFilter}>
                    <i className="ti-filter"/>
                </div>

                <>
                    <OrderFilterPersonalized
                        openQuickFilter={openQuickFilter}
                        onToggleFilter={this._onIsCloseQuickFilter}
                        onChangeState={this._handleChangeFilterState}
                    />
                    <div
                        className={`BackgroundQuickFilter ${openQuickFilter ? 'Open' : 'Close'}`}
                        onClick={this._onIsCloseQuickFilter}
                    />
                </>

                <OrdersMissFailed
                    onIsCloseOrderFailed={onIsCloseOrderFailed}
                    openOrderFailed={openOrderFailed}
                    listOrderFailed={listOrderFailed}
                    totalsFailed={totalsFailed}
                    pageOrderMiss={pageOrderMiss}
                    handlePageChange={handlePageChange}
                    newFBAOrders={newFBAOrders}
                    FBAUpdateLabel={FBAUpdateLabel}
                    FBAUpdateBoxInfo={FBAUpdateBoxInfo}
                    totalFBAWarning={totalFBAWarning}
                    waitingPaidOrders={waitingPaidOrders}
                    waitingMappingOrders={waitingMappingOrders}
                    waitingFulfillOrders={waitingFulfillOrders}
                    validateWarnings={validateWarnings}
                    onSearchTagMuniFest={this._handleChangeFilterState}
                />
            </div>
        )
    }
}

export default OrdersPage
