import createApiServices from './createApiServices'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api' : 'https://fulfillment-staging.merchize.com/api'
const api = createApiServices({ baseUrl: prefixURL })

export const getPackageItems = (data = {}) => {
    return api.makeAuthRequest({
        url: `/supplier/package-items/search`,
        method: 'POST',
        data,
    })
}

export const upsertPackageIssues = (data) => {
    return api.makeAuthRequest({
        url: `/supplier/issues`,
        method: 'POST',
        data: data
    })
}

export const deletePackageIssue = (id) => {
    return api.makeAuthRequest({
        url: `/supplier/issues/${id}`,
        method: 'DELETE',
    })
}

export const getIssues = (data) => {
    return api.makeAuthRequest({
        url: `supplier/issues/search`,
        method: 'POST',
        data,
    })
}

export const getIssueConfirm = (data) => {
    return api.makeAuthRequest({
        url: '/supplier/issues/notification',
        method: 'GET',
        params: data,
    })
} 

export const exportIssues = (data) => {
    return api.makeAuthRequest({
        url: `supplier/issues/export/csv/ffm?${data}`,
        method: 'GET',
    })
}

export const getIssueType = (data) => {
    return api.makeAuthRequest({
        url: `/supplier/issue-types`,
        method: 'GET',
        params: data,
    })
}

export const approveIssue = (data, id) => {
    return api.makeAuthRequest({
        url: `/supplier/approve-issue/${id}`,
        method: 'POST',
        data: data,
    })
}

export const markIssuesDone = (id) => {
    return api.makeAuthRequest({
        url: `/supplier/issues/${id}/mark-done`,
        method: 'PUT',
    })
}

export const markIssuesReplaced = (id) => {
    return api.makeAuthRequest({
        url: `/supplier/issues/${id}/mark-replaced`,
        method: 'PUT',
    })
}

export const markIssuesRefunded = (id) => {
    return api.makeAuthRequest({
        url: `/supplier/issues/${id}/mark-refunded`,
        method: 'PUT',
    })
}

export const requestRefundIssue = (id, data) => {
    return api.makeAuthRequest({
        url: `/order/orders/${id}/request/refund`,
        method: 'POST',
        data
    })
}

export const importIssueShipment = (payload) => {
    return api.makeAuthRequest({
        url: `/supplier/issues/import-csv`,
        method: 'POST',
        data: payload
    })
}

export const markIssuesPartnerRefunded = (id) => {
    return api.makeAuthRequest({
        url: `/supplier/issues/${id}/mark-partner-refunded`,
        method: 'PUT',
    })
}
