import React from 'react'
import FilterSelect from './filters/FilterSelect'
import FilterDate from "../../shared/FilterDate"
import { Input, Select } from 'antd'
import { STATUS, REQUEST_UPDATE, ORDERS_ARCHIVED, STATUS_CUT } from './helpers/PrintFileFilters'
import { SearchOutlined } from '@ant-design/icons'

const PrintFilesFilters = (props) => {
    const { loading, suppliers, filters, handleChangeFilters, placeholders, selectField, handleChageSelectField, listProducts, listBrand } = props
    return (
        <div className='PrintFilesFilters'>
            <div className='d-flex GroupFilter'>
                <div className="itemFilter mb-3 mr-2">
                    <FilterSelect
                        placeholder="Choose supplier"
                        options={suppliers}
                        style={{ width: 170 }}
                        allowClear={true}
                        showSearch={true}
                        value={filters.supplier}
                        handleChangeFilters={handleChangeFilters}
                        field="supplier"
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3 mr-2">
                    <FilterSelect
                        placeholder="Choose print file status"
                        options={STATUS}
                        style={{ width: 200 }}
                        allowClear={true}
                        showSearch={true}
                        value={filters.statuses}
                        handleChangeFilters={handleChangeFilters}
                        field="statuses"
                        mode="multiple"
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3 mr-2">
                    <FilterSelect
                        placeholder="Choose cut status"
                        options={STATUS_CUT}
                        style={{ width: 170 }}
                        allowClear={true}
                        showSearch={true}
                        value={filters.statuses_cut}
                        handleChangeFilters={handleChangeFilters}
                        field="statuses_cut"
                        mode="multiple"
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3 mr-2">
                    <FilterSelect
                        placeholder="Choose product type"
                        options={listProducts}
                        style={{ width: 250 }}
                        allowClear={true}
                        showSearch={true}
                        value={filters.product_types}
                        handleChangeFilters={handleChangeFilters}
                        field="product_types"
                        mode="multiple"
                        type="product"
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3 mr-2">
                    <FilterSelect
                        placeholder="Choose auto approved status"
                        options={[
                            {
                                label: "Yes",
                                value: "auto-approved",
                            },
                            {
                                label: "No",
                                value: 'no',
                            },
                        ]}
                        style={{ width: 250 }}
                        allowClear={true}
                        value={filters.auto_approved_status}
                        handleChangeFilters={handleChangeFilters}
                        field="auto_approved_status"
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3 mr-2">
                    <FilterSelect
                        placeholder="Choose request update status"
                        options={REQUEST_UPDATE}
                        style={{ width: 250 }}
                        allowClear={true}
                        value={filters.request_update_statuses}
                        handleChangeFilters={handleChangeFilters}
                        field="request_update_statuses"
                        mode="multiple"
                        loading={loading}
                    />
                </div>
                {/* <div className="itemFilter mb-3 mr-4">
                    <Input
                        style={{ width: 150 }}
                        placeholder="Update counts"
                        onChange={e => handleChangeFilters('update_design_count', e.target.value)}
                        value={filters.update_design_count}
                        disabled
                    />
                </div> */}
                <div className="d-flex flex-nowrap align-items-center itemFilter filterPushedAt mb-3 font-weight-bold">
                    <div className='mr-1'>Created at:</div>
                    <FilterDate
                        field='created'
                        value={filters.created || {}}
                        onDatesChange={handleChangeFilters}
                        loading={loading}
                    />
                </div>
            </div>
            <div className='d-flex GroupFilter'>
                <div className="itemFilter mb-3 mr-2">
                    <Input.Group compact>
                        <Select
                            value={selectField}
                            style={{ width: 170 }}
                            onChange={handleChageSelectField}
                            loading={loading}
                        >
                            <Select.Option value="package_names">Package name</Select.Option>
                            <Select.Option value="order_number">Order number</Select.Option>
                            {/* <Select.Option value="namespace">Namespace</Select.Option> */}
                            <Select.Option value="barcode_numbers">Barcode</Select.Option>
                        </Select>
                        <Input
                            style={{ width: 387 }}
                            placeholder={placeholders[selectField]}
                            onChange={e => handleChangeFilters(selectField, e.target.value)}
                            value={filters[selectField]}
                            loading={loading}
                            disabled={loading}
                        />
                    </Input.Group>
                </div>
                <div className="itemFilter mb-3 mr-2">
                    <Input
                        style={{ width: 200 }}
                        placeholder="Search brand name"
                        onChange={e => handleChangeFilters('brand_name', e.target.value)}
                        value={filters.brand_name}
                        suffix={<SearchOutlined />}
                        disabled={loading}
                    />
                </div>

                {/* <div className="itemFilter mb-3 mr-4">
                    <FilterSelect
                        placeholder="Search brand name"
                        options={listBrand}
                        style={{ width: 200 }}
                        allowClear={true}
                        showSearch={true}
                        value={filters.brand_name}
                        handleChangeFilters={handleChangeFilters}
                        field="brand_name"
                    />
                </div> */}
                <div className="itemFilter mb-3 mr-2">
                    <FilterSelect
                        placeholder="Note status"
                        options={[
                            {
                                label: "Have note",
                                value: "yes",
                            },
                            {
                                label: "No note",
                                value: 'no',
                            },
                        ]}
                        style={{ width: 150 }}
                        allowClear={true}
                        value={filters.has_note}
                        handleChangeFilters={handleChangeFilters}
                        field="has_note"
                        loading={loading}
                    />
                </div>
                <div className='itemFilter mb-3 mr-2'>
                    <FilterSelect
                        placeholder="Show order"
                        options={ORDERS_ARCHIVED}
                        style={{ width: 230 }}
                        value={filters.show_archive || 'hide_archive'}
                        handleChangeFilters={handleChangeFilters}
                        field="show_archive"
                        loading={loading}
                    />
                </div>
                <div className="d-flex flex-nowrap align-items-center itemFilter filterPaidAt mb-3 font-weight-bold">
                    <div className='mr-3'>Paid at:</div>
                    <FilterDate
                        field='paid_at'
                        value={filters.paid_at || {}}
                        onDatesChange={handleChangeFilters}
                        loading={loading}
                    />
                </div>
                {/* <div className="d-flex flex-nowrap align-items-center itemFilter filterPushedAt mb-3 font-weight-bold">
                    <div className='mr-3'>Pushed at:</div>
                    <FilterDate
                        field='pushed_at'
                        value={filters.pushed_at || {}}
                        onDatesChange={handleChangeFilters}
                       
                    />
                </div> */}
            </div>
        </div>
    )
}

export default PrintFilesFilters