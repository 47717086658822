import React, { useState, useRef } from 'react'
import { Input } from 'antd'
import SuppliersSelector from './SuppliersSelector'
import ShippingPlansSelector from './ShippingPlansSelector'
import ZonesSelector from './ZonesSelector'


const ShippingFeeFtilter = (props) => {
    const { placeholders, placeholder, onchangeFilter, supplier, handleChangeSupplier, shippingPlan, handleChangeShippingPlan,
        handleChangeZone, zone, search: searchProps
    } = props

    const [searchField, setSearchField] = useState(props.searchField.length ? props.searchField[0] : null)
    const [search, setSearch] = useState('')
    const inputRef = useRef()

    const _onChangeSelectSearchField = e => {
        const { value } = e.target
        inputRef.current.focus()

        setSearch('')
        if(searchProps) delete {...searchProps}
        setSearchField(value)
    }

    const _onChangeInputFilter = value => {
        setSearch(value)
        if (searchField === 'Title') return onchangeFilter('product_title', value)
        if (searchField === 'Type') return onchangeFilter('product_type', value)

        onchangeFilter('product_sku_prefix', value)
    }
    return (
        <div className="TableFilter d-xl-flex justify-content-between align-items-end mb-3">
            <div className="Filter">
                <div className="d-flex flex-wrap align-items-baseline justify-content-start">
                    <div className="FilterWrapper FilterOptions mb-3 mr-3">
                        <div className="d-flex align-items-stretch justify-content-end">
                            <div className="input-group-prepend SelectWrapper">
                                {searchField.length > 0 ? <select
                                    value={searchField}
                                    className="form-control"
                                    onChange={_onChangeSelectSearchField}
                                >
                                    {props.searchField.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </select> :
                                    <span className="input-group-text">
                                        <i className="ti ti-search" />
                                    </span>}
                            </div>

                            <div className="InputWrapper position-relative">
                                <i className="ti-search position-absolute d-none" />
                                <Input
                                    value={search || ''}
                                    onChange={(e) => _onChangeInputFilter(e.target.value)}
                                    className="form-control InputSearch"
                                    placeholder={searchField.length ? placeholders[searchField] : placeholder}
                                    ref={inputRef}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <form>
                            <SuppliersSelector
                                supplier={supplier}
                                handleSelectSupplier={handleChangeSupplier}
                                width={'200px'}
                                placeholder="Supplier"
                            />
                        </form>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <form>
                            <ShippingPlansSelector
                                shippingPlan={shippingPlan}
                                handleChangeShippingPlan={handleChangeShippingPlan}
                                width={'200px'}
                                placeholder="Shipping plan"
                            />
                        </form>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <form>
                            <ZonesSelector
                                zone={zone}
                                handleChangeZone={handleChangeZone}
                                width={'200px'}
                                placeholder="Zone"
                                supplier={supplier}
                            />
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ShippingFeeFtilter