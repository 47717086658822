import React, {Component} from 'react'
import {Switch, Route, Redirect} from 'react-router'
import HomeContainer from './home/components/HomeContainer'
import DashboardContainer from './dashboard/components/DashboardContainer'
import OauthCallbackContainer from './oauth-callback/components/OauthCallbackContainer'
import DocTitleByStore from './dashboard/shared/DocTitleByStore'
import LoginPageContainer from './login/components/LoginPageContainer'
import ScrollToTop from "./shared/ScrollToTop"

class App extends Component {
    render() {
        return (
            <div className="App">
                <DocTitleByStore title='Merchize'/>
                <ScrollToTop/>
                <Switch>
                    <Route path="/" exact component={HomeContainer}/>
                    <Route path="/login" exact component={LoginPageContainer}/>
                    <Route path="/a" component={DashboardContainer}/>
                    <Route path="/oauth-callback" component={OauthCallbackContainer}/>
                    <Redirect to="/"/>
                </Switch>
            </div>
        )
    }
}

export default App
