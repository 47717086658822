import React, { Component } from 'react'
import { CSVLink } from "react-csv"

class FileTemplate extends Component {
    render() {
        const FileTemplateData = [
            ["Package name","Store","Supplier","Tracking","Shipping carrier","Original carrier","Issue type","Solution","FFM's note","FFM's proof","Partner's confirm","Partner's note"],
            [],
        ]

        return (
            <div className='FileTemplate d-flex align-items-center'>
                <h3 className='Title mb-0'>Upload file CSV</h3>
                <CSVLink
                    data={FileTemplateData}
                    filename={"issue-shipment-template.csv"}
                    className="btn btn-link"
                >
                    Download template
                </CSVLink>(<p className='mb-0'>File name have not contain special characters and limit 5MB</p>)
            </div>
        )
    }
}

export default FileTemplate
