import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, Select, message, Checkbox, Spin } from 'antd'
import { searchProducts, productlineApplyBranding } from '../../../../services/api/ProductsServices'

ProductVariantAttribute.propTypes = {
    variant: PropTypes.object,
    attribute: PropTypes.object
}

function ProductVariantAttribute(props) {
    const { variant, attribute, fetchListVariants, productDetail, variants, disableAll } = props
    const { products_apply_branding } = { ...variant }
    const usedProductType = useRef()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [products, setProducts] = useState([])
    const [allProductType, setAllProductType] = useState(false)
    const [productsSelected, setProductsSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const { options = [] } = variant
    const { Option } = Select
    const attValue = options.find(option => option.attribute === attribute._id) || ""
    usedProductType.current = !!products_apply_branding && products_apply_branding.length ? products_apply_branding.filter(i => i.is_deleted === false).map(i => i._id) : []

    const _handleToggleModal = async () => {
        setIsModalOpen(!isModalOpen)
        if (isModalOpen === false) {
            await _getProductType()
        } else {
            setAllProductType(usedProductType.current.length === products.length)
            setProductsSelected([])
        }
    }

    const _getProductType = async () => {
        setProductsSelected([])
        setLoading(true)
        try {
            const payload = {
                supplier: ['5cf099aa3b7f1e3d46b7ae73', '6603874fdb576f701e6e5dbd', '66dad3efcdd92285af643843'],
                product_label: 'product',
                // supplier: ['5cf099aa3b7f1e3d46b7ae73', '65f3e7f6e536a73058379e3d'],
                limit: 10000,
            }
            const { data, success, message } = await searchProducts(payload)
            if (success) {
                setProducts([...data.products])
                setLoading(false)
                setAllProductType(usedProductType.current.length === data.products.length)
                setProductsSelected(usedProductType.current)
            } else {
                console.log(message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const onchangeProductType = (value) => {
        if (value.some(i => i === 'all')) {
            return setProductsSelected(optionProductType.map(item => item.value))

        }
        setProductsSelected([...value])
        setSearchValue('')
    }

    const onSearchType = (value)=>{
        if(value.includes(',')){
            // setSearchValue('')
            const Value = value.split(',').map(i=>i.trim())
            const productValue = products.filter(i=>Value.includes(i.title)).map(i=>i._id)
            setProductsSelected([...productsSelected,...productValue])
        }else{
            setSearchValue(value)
        }
    }

    const _handleOk = async () => {
        const payload = {}
        if (allProductType) {
            payload.products_apply_branding = products.map(i => i._id)
        } else {
            payload.products_apply_branding = productsSelected
        }
        try {
            const { success, message: mess } = await productlineApplyBranding(variant._id, payload)
            if (success) {
                _handleToggleModal()
                await fetchListVariants()
                message.success('Product type apply Brand Kit succcess!')
            } else {
                message.error(mess)
            }
        } catch (error) {
            message.error(error)
        }
    }

    const optionProductType = products.length
        ? products.map((item) => {
            return {
                id: item._id,
                title: item.title,
                value: item._id,
            }
        })
        : []

    const onChange = (e) => {
        if (e.target.checked === false) {
            setProductsSelected([])
        }
        setAllProductType(e.target.checked);
    }

    const [listProductTypeExisted] = variants.filter(
        i => i._id !== variant._id &&  !!i.products_apply_branding && i.products_apply_branding.length > 0
        ).map(item => {
        for (let i = 0; i < item.products_apply_branding.length; i++) {
            return item.products_apply_branding.map(i => i._id)
        }
    })

    const checkBoxDisable = !!listProductTypeExisted && listProductTypeExisted.length > 0
    const selectDisable = !!listProductTypeExisted && listProductTypeExisted.length === products.length

    return (
        <td className='ProductVariantAttribute'>
            {productDetail.product_label === 'branding' &&
                <a onClick={_handleToggleModal}>
                    <i className="ti-archive text-primary fa-sm mr-2" ></i>
                </a>
            }
            {!!attValue && attValue.name}
            <Modal
                visible={isModalOpen}
                onCancel={_handleToggleModal}
                title={
                    <div>
                        <h5>
                            Product apply Brand Kit
                        </h5>
                        <label>{`variant(${variant.title})`}</label>
                    </div>
                }
                okText='Save'
                width={1000}
                onOk={_handleOk}
                okButtonProps={{disabled: loading || disableAll}}
            >
                <Spin spinning={loading} tip='Loading Products type...'>
                    <label className='font-weight-bold'>Product:</label>
                    <Checkbox
                        checked={allProductType}
                        onChange={onChange}
                        disabled={checkBoxDisable || disableAll}
                        className='float-right'
                    >
                        All product
                    </Checkbox>
                    <Select
                        placeholder={(!!allProductType ? "All Product " : "Select Product ") || []}
                        onChange={onchangeProductType}
                        mode="multiple"
                        className="formFilter w-100 mb-3"
                        value={allProductType ? [] : (productsSelected || [])}
                        disabled={allProductType || selectDisable || disableAll}
                        filterOption={(input, option) =>
                            (option.children).toLowerCase().includes(input.toLowerCase())
                        }
                        // tokenSeparators={[',']}
                        // onSearch={onSearchType}
                        // searchValue={searchValue}
                        showArrow
                        allowClear
                    >
                        {optionProductType.map((data, index) => (
                            <Option
                                key={index}
                                value={data.value}
                                disabled={!!listProductTypeExisted && listProductTypeExisted.includes(data.value) ? true : false}
                            >
                                {data.title}
                            </Option>
                        ))}
                    </Select>
                </Spin>
            </Modal>
        </td>
    )
}

export default ProductVariantAttribute
