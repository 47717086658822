import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Switch, Tooltip} from "antd"
import ProductVariantAttribute from "./ProductVariantAttribute"
import ProductVariantPrice from "./ProductVariantPrice"
import ProductVariantPriceStore from "./ProductVariantPriceStore"
import ProductVariantQuantity from "./ProductVariantQuantity"
import ProductVariantPfVariant from "./ProductVariantPFVariant"
import isMatchVariant from "../../../product/helpers/isMatchVariant"
import ProductVariantRowDimension from "./ProductVariantRowDimension"
import moment from "moment"
import { validate } from 'uuid'

ProductVariantsRow.propTypes = {
    variant: PropTypes.object,
    attributes: PropTypes.array,
    variantIndex: PropTypes.number,
    totalVariants: PropTypes.number,
    variants: PropTypes.array,
    filter: PropTypes.object,
    onChangeVariant: PropTypes.func.isRequired,
    onChangeVariantDimension: PropTypes.func.isRequired,
    highlightRow: PropTypes.func.isRequired,
    storeName: PropTypes.array,
}

function ProductVariantsRow(props) {
    const {
        filter,
        variant,
        variants,
        attributes,
        variantIndex,
        totalVariants,
        onChangeVariant,
        onChangeAllVariant,
        onChangeVariantDimension,
        handlePaste,
        productId,
        fetchListVariants,
        productDetail,
        storeName,
        isShowMoreTable,
        disableAll,
        highlightRow = (sku = '') => {},
    } = props
    const [isActive, setIsActive] = useState(variant.hasOwnProperty('is_disabled') ? !variant.is_disabled : true)
    const [isActiveAvailableTiktok, setIsActiveAvailableTiktok] = useState(variant.hasOwnProperty('available_tiktok') ? variant.available_tiktok : false)


    const _isShow = () => {
        return isMatchVariant(filter, variant)
    }

    const onChange = (checked) => {
        setIsActive(checked)

        if (typeof onChangeVariant === 'function') {
            onChangeVariant(variantIndex, 'is_disabled', !checked)
        }
    }

    const onChangeAvailableTiktok = (checked) => {
        setIsActiveAvailableTiktok(checked)

        if (typeof onChangeVariant === 'function') {
            onChangeVariant(variantIndex, 'available_tiktok', checked)
        }
    }

    const getRowTitle = () => {
        const {isDuplicated} = variant
        const {duplicateVariants} = props

        if (!isDuplicated || !duplicateVariants) {
            return ''
        }

        return `This variant has duplicate partner sku with ` + duplicateVariants.filter(v => v._id !== variant._id).map(v => v.sku).join(',')
    }

    useEffect(() => {
        setIsActive(variant.hasOwnProperty('is_disabled') ? !variant.is_disabled : true)
        setIsActiveAvailableTiktok(variant.hasOwnProperty('available_tiktok') ? variant.available_tiktok : false)
    }, [variants])

    const isShow = _isShow()

    // if (!isShow) {
    //     return ''
    // }

    const {isDuplicated} = variant
    const listStock = {
        haveDate: variant.restock_date !== '2222-02-22T22:22:22.222Z' ? `Restock date is ${moment(variant.restock_date).format('YYYY-MM-DD HH:mm ')}` : 'SKU is unavailable. No restock date',
        empty: (variant.is_disabled && (variant.restock_date === undefined || variant.restock_date === '')) ? 'Off by user' : ''
    }

    const newStore = storeName.length > 0 ? storeName.filter(store => !!store.store) : []
    let storePrice = []

    for(let i = 0; i < newStore.length; i++) {
        const currentStore = newStore[i]

        const matchingStore = variant.hasOwnProperty('store') && variant.store.filter(item => !!item.store).find(i => i.store._id === currentStore._id)
        if(matchingStore) storePrice.push(matchingStore)
    }

    const seenGroups = new Set()

    return !isShow ? '' : (
        <tr className={['ProductVariantsRow', !!isDuplicated ? 'text-danger' : ''].filter(Boolean).join(' ')}
            title={getRowTitle()}
            data-group={variant.groupDuplicated}
            onMouseOver={highlightRow(variant)} onMouseLeave={highlightRow()}
            data-id={variant._id}>
            {
                !!attributes && attributes.length > 0 && attributes.map(att => {
                    return <ProductVariantAttribute
                        key={`att_row_${att._id}`}
                        variant={variant}
                        attribute={att}
                        productId={productId}
                        fetchListVariants={fetchListVariants}
                        productDetail={productDetail}
                        variants={variants}
                        disableAll={disableAll}
                    />
                })
            }

            <ProductVariantPrice
                price={variant.base_cost}
                variantIndex={variantIndex}
                totalVariants={totalVariants}
                onChangeVariant={onChangeVariant}
                handlePaste={handlePaste}
                disableAll={disableAll}
            />

            {
                storePrice.length > 0 && storePrice.filter(item => {
                    const {store}={...item}
                    const group = store.group
                    if (!group || !seenGroups.has(group)) {
                        if (group) {
                            seenGroups.add(group)
                        }
                        return true
                    }
                    return false
                }).map((item, index) => (
                    <ProductVariantPriceStore
                        price={variant.base_cost}
                        priceStore={item.price}
                        store={variant.store}
                        variantIndex={variantIndex}
                        onChangeVariant={onChangeVariant}
                        handlePaste={handlePaste}
                        key={index}
                        id={item._id}
                        storeId={item.store._id}
                        group={item.store.group}
                        disableAll={disableAll}
                    />
                ))
            }

            <ProductVariantQuantity
                quantity={variant.as_quantity}
                variantIndex={variantIndex}
                onChangeVariant={onChangeVariant}
                handlePaste={handlePaste}
                disableAll={disableAll}
            />

            <td>{variant.sku}</td>

            <ProductVariantPfVariant
                variant={variant}
                variants={variants}
                pfSku={variant.partner_sku}
                variantIndex={variantIndex}
                totalVariants={totalVariants}
                onChangeVariant={onChangeVariant}
                onChangeAllVariant={onChangeAllVariant}
                filter={filter}
                disableAll={disableAll}
            />

            <td>
                <Tooltip
                    title={`${!!variant.restock_date ? listStock['haveDate'] : listStock['empty'] ? listStock['empty'] : isActive ? 'Enable variant' : 'Disable variant'}`}>
                    <Switch checked={isActive} onChange={onChange} disabled={disableAll}/>
                </Tooltip>
            </td>

            <td>
                <Tooltip title={`${isActiveAvailableTiktok ? 'Available Tiktok' : 'Not Available Tiktok'}`}>
                        <Switch 
                            checked={isActiveAvailableTiktok}
                            onChange={onChangeAvailableTiktok}
                            disabled={disableAll}
                        />
                    </Tooltip>
            </td>

            {
                isShowMoreTable && 
                <>
                    <ProductVariantRowDimension
                        value={!!variant.dimension ? variant.dimension.height : ''}
                        variantIndex={variantIndex}
                        field='height'
                        onChangeVariantDimension={onChangeVariantDimension}
                        handlePaste={handlePaste}
                        disableAll={disableAll}
                    />
                    <ProductVariantRowDimension
                        value={!!variant.dimension ? variant.dimension.length : ''}
                        variantIndex={variantIndex}
                        field='length'
                        onChangeVariantDimension={onChangeVariantDimension}
                        handlePaste={handlePaste}
                        disableAll={disableAll}
                    />
                    <ProductVariantRowDimension
                        value={!!variant.dimension ? variant.dimension.width : ''}
                        variantIndex={variantIndex}
                        field='width'
                        onChangeVariantDimension={onChangeVariantDimension}
                        handlePaste={handlePaste}
                        disableAll={disableAll}
                    />
                    <ProductVariantRowDimension
                        value={!!variant.dimension ? variant.dimension.weight : ''}
                        variantIndex={variantIndex}
                        field='weight'
                        onChangeVariantDimension={onChangeVariantDimension}
                        handlePaste={handlePaste}
                        disableAll={disableAll}
                    />
                    <ProductVariantRowDimension
                        value={!!variant.dimension ? variant.dimension.additional_weight : ''}
                        variantIndex={variantIndex}
                        field='additional_weight'
                        onChangeVariantDimension={onChangeVariantDimension}
                        handlePaste={handlePaste}
                        disableAll={disableAll}
                    />
                    <td>{(!!variant.dimension && variant.dimension.weight_by_v > 0) ? variant.dimension.weight_by_v.toFixed(2) : ''}</td>
                </>
            }

            <th className="table-col-5"></th>
        </tr>
    )
}

export default ProductVariantsRow
