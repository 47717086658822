import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Button, message, Modal, Select, Tooltip} from "antd"
import {ReactComponent as RequestIcon} from "../../../../statics/assets/images/request-icon.svg"
import {ReactComponent as AttachIcon} from "../../../../statics/assets/images/attach.svg"
import {ReactComponent as DeleteIcon} from "../../../../statics/assets/images/trash.svg"
import {createUpdateAddressRequest, createUpdateDesignRequest} from "../../../../services/api/PlatformApiKeysServices"
import broker from "../../OrderBroker"
import AutoCompletedText from "../request/AutoCompletedText"

RequestUpdateModal.propTypes = {
    namespace: PropTypes.string,
    language: PropTypes.string,
    languageError: PropTypes.string,
    loading: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.string,
    packageName: PropTypes.string,
    orderItemId: PropTypes.string
}

function RequestUpdateModal(props) {
    const {title, value, packageName, orderItemId, namespace, language, languageError} = props
    const [visible, setVisible] = useState(false)
    const [files, setFiles] = useState('')
    const [content, setContent] = useState('')

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const _onOpenModal = () => {
        setVisible(true)
    }

    const _onCloseModal = () => {
        setVisible(false)
        setContent('')
        setFiles('')
    }

    const _handleChangeFile = (e) => {
        const {files} = e.target

        if (files[0].size > 5242880) {
            setError('File size is lager than 5M')
        } else {
            setError('')
            setFiles(files)
        }
    }

    const _onDeleteFile = (index) => {
        const newFilter = Object.assign({}, files)
        delete newFilter[index]

        setFiles(newFilter)
    }

    const _handleChangeContent = (value) => {
        setContent(value)
    }

    const _onChangeFile = (e) => {
        e.target.value = ''
    }

    const _onSubmit = async () => {
        if (value === 'design') {
            await _onCreateUpdateDesignRequest()
            broker.publish('ORDER_REFRESH_ORDER')
        } else {
            await _onCreateUpdateAddressRequest()
            broker.publish('ORDER_REFRESH_ORDER')
        }
    }

    const _onCreateUpdateDesignRequest = async () => {
        setLoading(true)
        setError('')

        try {
            if (content === '') {
                setLoading(false)
                return setError('Description is required.')
            }

            const formData = new FormData()

            formData.append('image', files[0])
            formData.append('content', content)

            const response = await createUpdateDesignRequest(orderItemId, formData)

            const {success, message: error} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            _onCloseModal()
            message.success('created request success.')
        } catch (e) {
            setError(e.message)
        }
    }

    const _onCreateUpdateAddressRequest = async () => {
        setLoading(true)
        setError('')

        try {
            const formData = new FormData()

            formData.append('image', files[0])
            formData.append('content', content)

            const response = await createUpdateAddressRequest(orderItemId, formData)

            const {success, message: error} = response

            if (!success) {
                setLoading(false)

                return setError(error)
            }

            setLoading(false)
            _onCloseModal()
            message.success('created request success.')
        } catch (e) {
            setError(e.message)
        }
    }

    const keysFile = Object.keys(files)

    return (
        <div className='RequestUpdateModal'>
            {/* <Tooltip title="Request Update Address">
                <RequestIcon onClick={_onOpenModal}/>
            </Tooltip> */}
            <div onClick={_onOpenModal} className="RequestUpdateAddress">
                <div className="Tooltip3 Top">
                    <RequestIcon/>

                    <div className="TooltipContent Clipboard">
                        <div className="ContentInner text-center">
                            <small className="text-monospace">Request Update Address</small>
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal
                className="RequestModal"
                visible={visible}
                title={`${title} - ${packageName}`}
                onCancel={_onCloseModal}
                footer={[
                    <Button key="back" onClick={_onCloseModal}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={_onSubmit}>
                        Send Request
                    </Button>
                ]}
            >
                <div className="ContentModal">
                    <div className="RequestType">
                        <label htmlFor="Type">Request type:</label>
                        <Select name="request-type" id="Type" value={value} disabled>
                            <Select.Option value="design">Design</Select.Option>
                            <Select.Option value="address">Address</Select.Option>
                        </Select>
                    </div>

                    <AutoCompletedText
                        value={value}
                        namespace={namespace}
                        language={language}
                        label="Description"
                        parentClass="RequestDescription"
                        onChange={_handleChangeContent}
                    />

                    <div className="AttachFile">
                        <input
                            id={`${value}_${orderItemId}_select_file`}
                            type="file"
                            accept='image/png, image/jpeg, image/jpg'
                            multiple={false}
                            onChange={_handleChangeFile}
                            onClick={_onChangeFile}
                        />
                        <label htmlFor={`${value}_${orderItemId}_select_file`} id={`${value}_select_file`}
                               className='d-block mb-0 cursor-pointer'>
                            Attach file
                        </label>
                    </div>
                    <div className="ListFileInput">
                        <ul>
                            {
                                keysFile && keysFile.length > 0 && keysFile.map((item, index) => (
                                    <li key={index}>
                                        <div className="Left">
                                            <AttachIcon/>
                                            <span className="FileName">{files[item].name}</span>
                                        </div>
                                        <DeleteIcon className="Delete" onClick={() => _onDeleteFile(item)}/>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {
                        !!error && error.length > 0 && <div className="text-danger mt-2">
                            {error}
                        </div>
                    }

                    {
                        !!languageError && languageError.length > 0 && <div className="text-danger mt-2">
                            {languageError}
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default RequestUpdateModal
