import React, { useRef, useEffect, useState } from 'react'
import PackageIssuesHeader from './PackageIssuesTable/PackageIssuesHeader'
import PackageIssuesRow from './PackageIssuesTable/PackageIssuesRow'

function PackageIssuesTable(props) {
    const { listData = [], refresh, permissionsData, setLoading, issueCategory, col2Ref, col3Ref, ProductionIssueTypes, ShipmentIssueTypes,
    onChangeLoad, selectedAll, setSelectedAll, listSelecteds, setListSelecteds } = { ...props }
    const col1Refs = useRef([])
    const col2Refs = useRef([])
    const col3Refs = useRef([])

    useEffect(() => {
        col1Refs.current = listData.map((_, i) => col1Refs.current[i] || React.createRef())
        col2Refs.current = listData.map((_, i) => col2Refs.current[i] || React.createRef())
        col3Refs.current = listData.map((_, i) => col3Refs.current[i] || React.createRef())
    }, [listData])

    const _handleChangeSelectAll = (isActive = false) => {
        if (isActive) {
            const listSelectedId = listData.map(item => item._id)
            setListSelecteds(listSelectedId)
            setSelectedAll(true)
        } else {
            setListSelecteds([])
            setSelectedAll(false)
        }
    }

    const _handleSelected = (id, isAdded) => {
        if (isAdded) {
            if (!listSelecteds.includes(id)) {
                setListSelecteds([
                    ...listSelecteds, id,
                ])
                setSelectedAll(listSelecteds.length + 1 === listData.length)
            }
        } else {
            const filtered = listSelecteds.filter(item => item !== id)

            setListSelecteds(filtered)
            setSelectedAll(false)
        }
    }

    return (
        <div className='PackageIssuesTable mt-3 table-wrapper'>
            <table className='table'>
                <PackageIssuesHeader
                    permissionsData={permissionsData}
                    selectedAll={selectedAll}
                    listSelecteds={listSelecteds}
                    handleChangeSelectAll={_handleChangeSelectAll}
                    refresh={refresh}
                    refs={{
                        col1Ref: col1Refs.current[0],
                        col2Ref: col2Refs.current[0],
                        col3Ref: col3Refs.current[0]
                    }}
                    listData={listData}
                    onChangeLoad={onChangeLoad}
                    ProductionIssueTypes={ProductionIssueTypes}
                    ShipmentIssueTypes={ShipmentIssueTypes}
                    issueCategory={issueCategory}
                />
                <tbody className='PackageIssuesRow'>
                    {!!listData && listData.length > 0 ? listData.map((item, i) => {
                        return <PackageIssuesRow
                            key={item._id}
                            index={i}
                            item={item}
                            refresh={refresh}
                            setLoading={setLoading}
                            permissionsData={permissionsData}
                            ProductionIssueTypes={ProductionIssueTypes}
                            ShipmentIssueTypes={ShipmentIssueTypes}
                            refs={{
                                col1Ref: col1Refs.current[i],
                                col2Ref: col2Refs.current[i],
                                col3Ref: col3Refs.current[i]
                            }}
                            listSelecteds={listSelecteds}
                            handleSelected={_handleSelected}
                        />
                    }) : <tr>
                        <td className='fixed-column1'>No data</td>
                        <td className='fixed-column2'></td>
                        <td className='fixed-column3'></td>
                        <td colSpan={13}></td>
                        <td className='fixed-column-right'></td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default PackageIssuesTable