import React, {useEffect, useState} from 'react'
import {Spin, Radio, Select, Tooltip} from "antd"
import {getCompletedProduction, getCompletedProductionByStore} from "../../../../services/api/SuppliersServices"
import moment from "moment"
import FilterDate from "../../../shared/FilterDate"
import debounce from "../../../../helpers/debounce"
import downloadCsv from '../../../../helpers/common/downloadCsv'

const {Option} = Select
const baseLink = '/a/orders-supplier?show_archive=show_all'
const allowSuppliers = [
    // SKU prefix
    '1C', 'PF', 'CC', 'CC API', 'YC', 'CW', 'PLEU', 'PL EU', 'DBUS', 'MDUS', 'SPUS', 'DPCN', 'PBAU', 'DTUS', 'FSUS', 'DFUS', 'GLUS', 'HF EU', 'PYUS', 'EFUS', 'GTUS', 'TCVN', 'BFUS', 'MCUS'
]
const defaultSup = '1C'
const defaultTime = 'lastMonth'

let componentDidMount = false
let currentTime = 0

function ReportProductionTime(props) {
    const getDefaultSup = () => {
        return vSuppliers.length
            ? vSuppliers.find(sup => (sup.sku_prefix === defaultSup || sup.name === defaultSup || sup._id === defaultSup))
            : false
    }
    const {suppliers} = props
    const vSuppliers = suppliers ? suppliers.filter(s => {
        return allowSuppliers.includes(s.sku_prefix) || allowSuppliers.includes(s.name) || allowSuppliers.includes(s._id)
    }).sort((a, b) => (a.sku_prefix || a.name).localeCompare(b.sku_prefix || b.name)) : []
    const [reportData, setStaticsProductionTime] = useState({})
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [timeFilter, setTimeFilter] = useState(defaultTime)
    const [dateRange, setDateRange] = useState()
    const [supplier, setSupplier] = useState(getDefaultSup())
    const [sortItem, setSortItem] = useState('-due')
    const [currentTab, setCurrentTab] = useState('')

    const rangeTime2FromTo = (timeFilter) => {
        const format = 'DD-MM-YYYY'
        switch (timeFilter) {
            case 'lastWeek':
                return {
                    from: moment().startOf("isoWeek").subtract(7, 'days').format(format),
                    to: moment().endOf("isoWeek").subtract(7, 'days').format(format),
                }
            case 'lastMonth':
                return {
                    from: moment().startOf("month").subtract(1, 'months').format(format),
                    to: moment().startOf("month").subtract(1, 'day').format(format),
                }
            case 'lastQuarter':
                return {
                    from: moment().startOf("quarter").subtract(3, 'months').format(format),
                    to: moment().endOf("quarter").subtract(3, 'months').format(format),
                }
            case 'thisWeek':
                return {
                    from: moment().startOf("isoWeek").format(format),
                    to: moment().endOf("isoWeek").format(format),
                }
            case 'thisMonth':
                return {
                    from: moment().startOf("month").format(format),
                    to: moment().endOf("month").format(format),
                }
            case 'thisQuarter':
                return {
                    from: moment().startOf("quarter").format(format),
                    to: moment().endOf("quarter").format(format),
                }
        }
        return {}
    }
    const _handleTimeChange = (e) => {
        const {value} = e.target
        setTimeFilter(value)
        setDateRange(false)
    }

    async function _fetchReportProductionTime(time) {
        try {
            if (!timeFilter && !dateRange || (!currentTab && !supplier)) {
                return
            }

            setLoading(true)
            setError('')
            const convertDate = (d) => {
                const p = d.split('/')

                return [p[1], p[0], p[2]].join('/')
            }
            const payloadStatic = {
                ...(dateRange && {
                    from: dateRange.from ? convertDate(dateRange.from) : undefined,
                    to: dateRange.to ? convertDate(dateRange.to) : undefined,
                }),
                date_range: timeFilter,
            }
            const response = !currentTab
                ? await getCompletedProduction(supplier && supplier._id, payloadStatic)
                : await getCompletedProductionByStore(supplier && supplier._id, payloadStatic)

            if (time < currentTime) {
                console.log(`Too late! Request time ${time}, currently time is ${currentTime}.`)
                return
            }

            const {success, data, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setSortItem('-due')
            setLoading(false)
            setStaticsProductionTime(data)

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const handleChangeDate = (e) => {
        if (e.date_range && (e.date_range.from || e.date_range.to)) {
            setTimeFilter('')
        } else {
            setTimeFilter(defaultTime)
        }
        setDateRange(e.date_range)
    }

    const handleChangeSupplier = (value) => {
        if (!value) {
            return setSupplier()
        }
        setSupplier(vSuppliers.find(s => s._id === value))
    }

    const setQuery = debounce(() => {
        if (!supplier) {
            return
        }

        const query = {
            supplier: supplier && supplier._id,
            ...dateRange,
            date_range: timeFilter,
        }
        const queryStr = 'rpt=' + Object.keys(query).map(name => {
            return query[name] ? `${name}:${query[name]}` : ''
        }).filter(Boolean).join('|')
        const match = window.location.href.match(/([\?|\&])rpt=([^\&]*)/)

        if (match) {
            window.history.pushState({}, '', window.location.href.replace(match[0], match[1] + `${queryStr}`))
        } else {
            const x = window.location.href.indexOf('?') === -1 ? '?' : '&'
            window.history.pushState({}, '', window.location.href + `${x}${queryStr}`)
        }
    }, 100)

    const onLoad = () => {
        const match = window.location.href.match(/([\?|\&])rpt=([^\&]*)/)
        const vSupplier = getDefaultSup() || {}
        const query = match ? match[2].replace(/^rpt=/, '').split('|').reduce((a, b) => {
            const pairs = b.split(':')

            return {
                ...a,
                [pairs[0]]: pairs[1],
            }
        }, {}) : {
            date_range: defaultTime,
            supplier: vSupplier ? vSupplier._id : '',
        }

        setTimeout(() => {
            if (query.supplier) {
                setSupplier(vSuppliers && vSuppliers.find(s => s._id === query.supplier))
            } else {
                setSupplier(vSupplier)
            }

            if (!query.date_range && !query.from && !query.to) {
                query.date_range = defaultTime
            }

            if (query.date_range) {
                setTimeFilter(query.date_range)
            }

            if (query.from || query.to) {
                setDateRange({
                    from: query.from,
                    to: query.to,
                })
            }
        }, 300)
    }

    // Did mount?
    useEffect(() => {

    }, [])

    useEffect(() => {
        setQuery()
        currentTime = Date.now()
        _fetchReportProductionTime(currentTime, currentTab).then(() => {
        })
    }, [timeFilter, dateRange, supplier, currentTab])

    useEffect(() => {
        if (suppliers && suppliers.length && !componentDidMount) {
            onLoad()
            componentDidMount = true
        }
    }, [suppliers])

    const linkTo = (baseLink, text, args) => {
        if (!baseLink) {
            return text
        }

        const query = {

            ...(supplier && {suppliers: supplier._id}),
            ...(dateRange && (dateRange.from || dateRange.to) ? {
                pushed_at: dateRange,
            } : {
                pushed_at: rangeTime2FromTo(timeFilter),
            }),
            ...args,
        }

        const queryString = Object.keys(query).map(name => {
            if (query[name] === '' || query[name] === undefined || query[name] === null) {
                return
            }
            if (typeof query[name] !== 'object') {
                return `${name}=${query[name]}`
            }

            return Object.keys(query[name]).map(c => {
                return `${name}.${c}=${query[name][c]}`
            }).join('&')
        }).filter(Boolean).join('&')

        return <a href={`${baseLink}&${queryString}`} target={'_blank'}>{text}</a>
    }

    const {
        completed = 0,
        due = 0,
        due_for_seller = 0,
        processing = 0,
        total = 0,
        products = [],
        stores = [],
    } = {...reportData}
    const hasItems = !currentTab ? !!products.length : !!stores.length
    const items = !currentTab ? products : stores
    const getSort = () => {
        const sorts = `${sortItem}`.match(/^(-)?(.*)$/)

        return {
            key: sorts[2],
            dir: sorts[1] ? -1 : 1,
        }
    }
    const _handleChangeSortBy = (field) => () => {
        const {key, dir} = getSort()
        const newSort = [
            field === key
                ? (dir === -1 ? '' : '-')
                : (['type', 'store'].includes(field) ? '' : '-'),
            field || key,
        ].join('')

        setSortItem(newSort)
    }

    const renderItems = () => {
        const {key, dir} = getSort()

        switch (dir) {
            case 1:
                return [...items].sort((a, b) => {
                    return ['type', 'store'].includes(key)
                        ? `${a[key]}`.localeCompare(b[key])
                        : a[key] - b[key]
                })
            case -1:
                return [...items].sort((a, b) => {
                    return ['type', 'store'].includes(key)
                        ? `${b[key]}`.localeCompare(a[key])
                        : b[key] - a[key]
                })
            default:
                return items
        }
    }

    const switchTab = (e) => {
        setCurrentTab(currentTab ? '' : 'byStore')
        currentTab && !supplier && setSupplier(getDefaultSup())
        e.preventDefault()
    }

    const doExport = () => {
        downloadCsv(items, {
            mapCols: {
                ...(!currentTab && {type: 'Type'}),
                ...(currentTab && {store: 'Store'}),
                total: 'Total',
                completed: 'Completed',
                processing: 'Processing',
                due: 'Due',
                ...(currentTab && {due_for_seller: 'Due for Store'}),
                avg_time: 'Avg time production',
            },
            prefix: [`production-report`, currentTab && 'by-store'].filter(Boolean).join('-') + '-',
        })
    }

    const getSortIcon = (field) => {
        const {key, dir} = getSort()
        const classNames = [`ti-filter cursor-pointer position-absolute ${field} Sort`].filter(Boolean).join(' ')
        const theTitle = `Sort ${dir !== -1 || key !== field ? 'Down' : 'Up'}`
        return <Tooltip title={theTitle}><i
            className={classNames}
            style={{fontSize: '10px', bottom: '33px', right: '3px'}}
            onClick={_handleChangeSortBy(field)}/></Tooltip>
    }

    const getHeaderClasses = (field) => {
        const {key} = getSort()

        return ['font-weight-bold position-relative SortField', key === field && 'hasSorted', !['type', 'store'].includes(field) && 'text-right'].filter(Boolean).join(' ')
    }

    const getColClasses = (field, classes) => {
        const {key} = getSort()
        return [...classes.split(/\s+/), key === field && 'Sorted'].filter(Boolean).join(' ')
    }

    const tabsStaticsHeihght = !!document.getElementById("tabsStatics") && document.getElementById("tabsStatics").offsetHeight
    const FilterHeight = !!document.getElementById("Filter") && document.getElementById("Filter").offsetHeight
    const ErrorHeight = !!document.getElementById("Error") && document.getElementById("Error").offsetHeight

    return (
        <div className="ProductionReports">
            <div className="ReportHeading d-flex justify-content-between" id="Filter"
                 style={{top: `${tabsStaticsHeihght}px`}}>
                <h3>Production Time Report{currentTab && ' by Store'}</h3>
                <div className="Filter pb-2">
                    <Radio.Group value={timeFilter} onChange={_handleTimeChange}>
                        <Radio.Button value="lastQuarter">Last quarter</Radio.Button>
                        <Radio.Button value="lastMonth">Last month</Radio.Button>
                        <Radio.Button value="lastWeek">Last week</Radio.Button>
                        <Radio.Button value="thisQuarter">This quarter</Radio.Button>
                        <Radio.Button value="thisMonth">This month</Radio.Button>
                        <Radio.Button value="thisWeek">This week</Radio.Button>
                    </Radio.Group>
                    <FilterDate
                        heading=""
                        field="date_range"
                        value={dateRange || {}}
                        onDatesChange={handleChangeDate}
                        locale={{lang: {rangePlaceholder: ['Pushed from', 'Pushed to']}}}
                        style={{display: 'inline-block', marginLeft: '3px'}}
                        rangePickerStyle={{width: 250}}
                    />
                    <div align="right" className="pt-3 Filters">
                        <button className="btn SwitchButton mr-2"
                                onClick={switchTab}>{currentTab ? 'By Type' : 'By Store'}</button>
                        <button className="btn SwitchButton mr-2 btn-export"
                                onClick={doExport}>Export
                        </button>

                        <Select onChange={handleChangeSupplier} value={supplier && supplier._id || ''}
                                style={{width: '200px'}}>
                            {
                                currentTab &&
                                <Option value="">--All--</Option>
                            }
                            {
                                vSuppliers.map(supplier => {
                                    return <Option key={supplier._id}
                                                   value={supplier._id}>{supplier.sku_prefix || supplier.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
            <div className="text-danger Error" id="Error"
                 style={{top: `${tabsStaticsHeihght + FilterHeight}px`}}>{error}</div>
            <Spin spinning={loading} tip="Getting statics..."/>

            <table className="table table-borderless ProductionTimeTable">
                <thead style={{top: `${tabsStaticsHeihght + FilterHeight + ErrorHeight}px`}}>
                <tr>
                    <th className={getHeaderClasses(!currentTab ? 'type' : 'store')} rowSpan={2}>
                        {!currentTab ? 'Product type' : 'Store'}
                    </th>
                    <th className="col-total">Total</th>
                    <th className="col-completed">Completed</th>
                    <th className="col-processing">Processing</th>
                    <th className="col-due">Due</th>
                    {
                        currentTab ? <th className="col-due">Due for Store</th> : ''
                    }

                    <th className="col-avg">Avg time production</th>
                </tr>
                {
                    hasItems &&
                    <tr>
                        <th className={getHeaderClasses('total')}>
                            {linkTo(false, total, {
                                package_status: 'pushed,in_production,completed,pre_transit,in_transit,delivered',
                            })}
                            {
                                getSortIcon('total')
                            }
                        </th>
                        <th className={getHeaderClasses('completed')}>
                            {linkTo(baseLink, completed, {
                                delay: 'production_completed_from_pushed_at',
                                store_mode: 'normal,merchize-payment',
                                order_type: 'normal',
                                package_status_not_in: 'cancelled',
                                nin_did_actions: "fastproduction"
                            })}
                            {
                                getSortIcon('completed')
                            }
                        </th>
                        <th className={getHeaderClasses('processing')}>
                            {linkTo(baseLink, processing, {
                                delay: 'production_processing_from_pushed_at',
                                not_packaged: 'true',
                                store_mode: 'normal,merchize-payment',
                                order_type: 'normal',
                                package_status_not_in: 'cancelled',
                                nin_did_actions: "fastproduction"
                            })}
                            {
                                getSortIcon('processing')
                            }
                        </th>
                        <th className={getHeaderClasses('due')}>
                            {linkTo(baseLink, due, {
                                delay: 'production_delay_from_pushed_at',
                                not_packaged: 'true',
                                store_mode: 'normal,merchize-payment',
                                order_type: 'normal',
                                package_status_not_in: 'cancelled',
                                nin_did_actions: "fastproduction"
                            })}
                            {
                                getSortIcon('due')
                            }
                        </th>
                        {
                            currentTab ? <th className={getHeaderClasses('due')}>
                                {linkTo(baseLink, due_for_seller, {
                                    delay: 'seller_production_delay_from_pushed_at',
                                    not_packaged: 'true',
                                    store_mode: 'normal,merchize-payment',
                                    order_type: 'normal',
                                    package_status_not_in: 'cancelled',
                                    nin_did_actions: "fastproduction"
                                })}
                                {
                                    getSortIcon('due')
                                }
                            </th> : ''
                        }
                        <th className={getHeaderClasses('avg_time')}>{
                            getSortIcon('avg_time')
                        }</th>
                    </tr>
                }
                </thead>
                <tbody>
                {
                    hasItems && renderItems(sortItem).map((item, index) => {
                        const {
                            total: productTotal,
                            completed: productCompleted,
                            processing: productProcessing,
                            due: productDue,
                            due_for_seller: productDueForSeller,
                            avg_time: productAvgTime,
                            type: productType,
                            store,
                        } = item
                        const linkQuery = {
                            ...!currentTab && {product_type: productType},
                            ...currentTab && {namespaces: store},
                        }

                        return (
                            <tr key={`${index}`}>
                                <td className={getColClasses('', "text-left")}><label
                                    style={{whiteSpace: "pre-line"}}>{linkTo(false, !currentTab ? productType : store, linkQuery)}</label>
                                </td>
                                <td className={getColClasses('total', "text-right font-weight-bold")}>{linkTo(false, productTotal, linkQuery)}</td>
                                <td className={getColClasses('completed', "text-right linkable")}>{linkTo(baseLink, productCompleted, {
                                    ...linkQuery,
                                    delay: 'production_completed_from_pushed_at',
                                    store_mode: 'normal,merchize-payment',
                                    order_type: 'normal',
                                    package_status_not_in: 'cancelled',
                                    nin_did_actions: "fastproduction"
                                })}</td>
                                <td className={getColClasses('processing', "text-right linkable")}>{linkTo(baseLink, productProcessing, {
                                    ...linkQuery,
                                    delay: 'production_processing_from_pushed_at',
                                    not_packaged: 'true',
                                    store_mode: 'normal,merchize-payment',
                                    order_type: 'normal',
                                    package_status_not_in: 'cancelled',
                                    nin_did_actions: "fastproduction"
                                })}</td>
                                <td className={getColClasses('due', "text-right linkable")}>{linkTo(baseLink, productDue, {
                                    ...linkQuery,
                                    delay: 'production_delay_from_pushed_at',
                                    not_packaged: 'true',
                                    store_mode: 'normal,merchize-payment',
                                    order_type: 'normal',
                                    package_status_not_in: 'cancelled',
                                    nin_did_actions: "fastproduction"
                                })}</td>
                                {
                                    currentTab ? <td className={getColClasses('due', "text-right linkable")}>{linkTo(baseLink, productDueForSeller, {
                                        ...linkQuery,
                                        delay: 'seller_production_delay_from_pushed_at',
                                        not_packaged: 'true',
                                        store_mode: 'normal,merchize-payment',
                                        order_type: 'normal',
                                        package_status_not_in: 'cancelled',
                                        nin_did_actions: "fastproduction"
                                    })}</td> : ''
                                }
                                <td className={getColClasses('avg_time', "text-right")}>{Math.round(productAvgTime * 100) / 100}</td>
                            </tr>
                        )
                    })
                }
                {
                    !hasItems && <tr>
                        <td colSpan={6}>
                            <p className="text-center text-muted mb-0">
                                {supplier && `No data for supplier \`${supplier.sku_prefix || supplier.name}\``}
                                {!supplier && `No data`}
                            </p>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}

export default ReportProductionTime
