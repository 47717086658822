import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, notification} from "antd"
import {cancelOrderSPUS} from "../../../../services/api/ExportOrderServices"
import broker from "../../OrderBroker"

class CancelOrderSPUS extends Component {
    state = {
        loading: false,
        error: ''
    }

    _onCloseModal = () => {
        const {onClose} = this.props

        if (typeof onClose === 'function') {
            onClose()
        }
    }

    _onCancelOrder = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {fulfillment} = this.props

            const response = await cancelOrderSPUS(fulfillment._id)

            const {success, message} = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                loading: false
            }, () => {
                const {onClose} = this.props

                if (typeof onClose === 'function') {
                    onClose()
                }

                broker.publish("TRACKING_UPDATED")
                broker.publish('ORDER_REFRESHED_ORDER')
                broker.publish('ORDER_REFRESH_BRANDING')
                broker.publish('ORDER_RESTORE')

                notification.success({
                    message: 'Cancel order success',
                    description: 'Cancel order SPUS success.',
                })
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    render() {
        const {cancelOrderSPUS, fulfillment} = this.props
        const {loading, error} = this.state

        return (
            <Modal
                className='cancelOrderSPUS'
                visible={cancelOrderSPUS}
                title="Cancel order SPUS"
                onOk={this._onCancelOrder}
                onCancel={this._onCloseModal}
                footer={[
                    <div key="content" className='d-flex align-items-center justify-content-between'>
                        <Button key="back" onClick={this._onCloseModal}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="danger" loading={loading} onClick={this._onCancelOrder}>
                            Submit
                        </Button>
                    </div>
                ]}
            >
                <p>Do you want to cancel <strong>{fulfillment.name}</strong>?</p>
                {
                    !!error && error.length > 0 && <div className="text-danger">{error}</div>
                }
            </Modal>
        )
    }
}

CancelOrderSPUS.propTypes = {
    cancelOrderSPUS: PropTypes.bool,
    fulfillment: PropTypes.object
}

export default CancelOrderSPUS
