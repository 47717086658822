export const STATUS = [
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Processing",
        value: "processing",
    },
    {
        label: "Review",
        value: "review",
    },
    {
        label: "Done",
        value: "done",
    },
    {
        label: "Hold",
        value: "hold",
    },
    {
        label: "Retry",
        value: "retry",
    },
    {
        label: "Error",
        value: "error",
    },
    {
        label: "Error design",
        value: "error design",
    },
]
export const REQUEST_UPDATE = [
    {
        label: "Waiting update",
        value: "waiting-update-design"
    },
    {
        label: "Updated",
        value: "updated-design"
    },
    {
        label: "No request",
        value: "no-request"
    },
    // {
    //     label: "Deleted update",
    //     value: "deleted-update-design"
    // },
    {
        label: "Force updated",
        value: "force-updated-design"
    },
]

export const ITEMS_STATUS = [
    {
        name: 'Chưa in',
        value: 'unprinted',
    },
    {
        name: 'Đã đẩy',
        value: 'pushed',
    },
    {
        name: 'Đã in',
        value: 'printed',
    },
    {
        name: 'Hoàn thành',
        value: 'completed',
    },
    {
        name: 'Lỗi',
        value: 'error',
    },
    {
        name: 'Đã sửa',
        value: 'repaired',
    },
    {
        name: 'Thất lạc',
        value: 'lost',
    },
    {
        name: 'Đã ship',
        value: 'shipped',
    },
    {
        name: 'QC sau ép',
        value: 'confirmed',
    },
    {
        name: 'Đang chờ',
        value: 'pending',
    },
    {
        name: 'Đang in',
        value: 'printing',
    },
    {
        name: 'Đang ép',
        value: 'transferring',
    },
    {
        name: 'Đã ép',
        value: 'transferred',
    },
    {
        name: 'Đang may',
        value: 'sewing',
    },
    {
        name: 'Đã chuyển',
        value: 'moved',
    },
    {
        name: 'Hủy ship',
        value: 'cancel-ship',
    },
    {
        name: 'Đã ship',
        value: 'manual-package',
    }
]

export const ORDERS_ARCHIVED = [
    {
        label: 'Hide archived orders',
        value: 'hide_archive',
    },
    {
        label: 'Only archived orders',
        value: 'only_archive',
    },
    {
        label: 'All orders',
        value: 'show_all',
    },
]

export const STATUS_CUT = [
    {
        label: "Prepare",
        value: "prepare",
    },
    {
        label: "Cutting",
        value: "cutting",
    },
    {
        label: "Failed",
        value: "cut_failed",
    },
    {
        label: "Retry",
        value: "retry_cutting",
    },
]