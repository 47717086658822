import React from 'react'
import { Select, Input, DatePicker} from 'antd'
import {ReactComponent as SearchIcon} from '../../../../statics/assets/icons/search.svg'
import moment from 'moment'

function OrderProfitFilter(props) {
    const { RangePicker } = DatePicker
    const { Option } = Select

    const {
        supplier, listSupplier,
        loading, orderId,
        handleChangeSupplier,
        handleChangeOrderId,
        handleChangeCreated,
        createdStart,createdEnd,
        handleArchive, isArchive
    } = props
    const dateFormat= "DD/MM/YYYY"
    const dateFilterValue= createdStart&&createdEnd?[moment(createdStart, dateFormat), moment(createdEnd, dateFormat)]:[]
    const supplierOption = !!listSupplier && listSupplier.map(item =>
        <Option key={item._id} value={item._id}>{item.name}</Option>
    )

    return (
        <div className="TableFilter OrderProfitFilter d-xl-flex justify-content-between align-items-end">
        <div className="Filter">
            <div className="d-flex flex-wrap align-items-baseline justify-content-start">
                <div className="FilterWrapper FilterOptions mb-3 mr-3">
                    <div className="d-flex align-items-stretch justify-content-end">
                        <Input
                            style={{ minWidth: "400px" }}
                            placeholder='Search for order...'
                            value={orderId || ''}
                            onChange={handleChangeOrderId}
                            allowClear
                            disabled={loading}
                            prefix={<SearchIcon className='mr-2'/>}
                            className='search'
                        />
                    </div>
                </div>

                <div className='CustomSelect FilterOptions mr-3 mb-3'>
                    <Select
                        style={{ minWidth: "200px" }}
                        placeholder="-- Supplier --"
                        value={supplier || []}
                        onChange={handleChangeSupplier}
                        allowClear
                        disabled={loading}
                        showSearch
                        filterOption={(input, option) => 
                            option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                        }
                    >
                        {supplierOption}
                    </Select>
                </div>

                <div className='CustomSelect FilterOptions mr-3 mb-3'>
                    <Select
                        style={{ minWidth: "200px" }}
                        placeholder="-- Archive --"
                        value={isArchive || []}
                        onChange={handleArchive}
                        allowClear
                        disabled={loading}
                    >
                        {/* <Option value=''>All</Option> */}
                        <Option value='true'>Only archived</Option>
                        <Option value='false'>Hide archived</Option>
                    </Select>
                </div>

                <div className='CustomSelect FilterOptions mr-3 mb-3'>
                    <RangePicker
                        id='createdAt'
                        placeholder={['Start date', 'End date']}
                        ranges={{
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                        }}
                        format={dateFormat}
                        onChange={handleChangeCreated}
                        popupStyle={{ maxWidth: '600px' }}
                        disabled={loading}
                        value={dateFilterValue}
                    />
                </div>
            </div>
        </div>
    </div>
    )
}

export default OrderProfitFilter