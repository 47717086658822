import React, {Component} from 'react'
import PackagePusherEditor from "../PackagePusherEditor"
import PackagePusherFactoryEditor from "../ExportFactory/PackagePusherEditor"
import PropTypes from 'prop-types'
import CancelOrderPf from "../../cancel-order/CancelOrderPF"
import CancelOrderPLEU from "../../cancel-order/CancelOrderPLEU"
import CancelOrderCW from "../../cancel-order/CancelOrderCW"
import CancelOrderMD from "../../cancel-order/CancelOrderMD"
import CancelOrderDPCN from '../../cancel-order/CancelOrderDPCN'
import CancelOrderSPUS from '../../cancel-order/CancelOrderSPUS'
import CancelOrderPBAU from '../../cancel-order/CancelOrderPBAU'
import CancelOrderDTUS from '../../cancel-order/CancelOrderDTUS'
import CancelOrderHFT71 from '../../cancel-order/CancelOrderHFT71'
import CancelOrderGTUS from '../../cancel-order/CancelOrderGTUS'
import CancelOrderMPUS from '../../cancel-order/CancelOrderMPUS'
import CancelOrderPYUS from '../../cancel-order/CancelOrderPYUS'
import {Badge, Tooltip, Modal, Popconfirm, message } from 'antd'
import PackageCheckDetail from '../PackageCheckDetail'
import {getLocalData} from '../../../../../services/StorageServices'
import {parseJwt} from '../../../../../helpers/parseToken'
import CancelOrderFSUS from '../../cancel-order/CancelOrderFSUS'
import PackagePusherContainer from "../PackagePusherContainer"
import {InfoCircleOutlined} from "@ant-design/icons"
import CancelOrderTEEU from '../../cancel-order/CancelOrderTEEU'
import CancelOrderSupplier from '../../cancel-order/CancelOrderSupplier'
import { confirmPushSupplier } from '../../../../../services/api/ExportOrderServices'
import broker from "../../../OrderBroker"

class PushPackageItem extends Component {
    state = {
        isOpen: false,
        isOpenExportFactory: false,
        cancelOrderPF: false,
        cancelOrderPLEU: false,
        cancelOrderCW: false,
        cancelOrderMD: false,
        cancelOrderDPCN: false,
        cancelOrderSPUS: false,
        cancelOrderPBAU: false,
        cancelOrderDTUS: false,
        cancelOrderHFT71: false,
        cancelOrderGTUS: false,
        CancelOrderMPUS: false,
        cancelOrderPYUS: false,
        isOpenCheckDetail: false,
        cancelOrderFSUS: false,
        cancelOrderTEEU: false,
            }

    _handleClickPush = (e) => {
        e.preventDefault()
        const {fulfillment, supplierSelected} = {...this.props}
        const {factory_info} = {...fulfillment}
        const {metadata} ={...factory_info}
        const {confirmStatus}={...metadata}
        const {name:SupplierName} = {...supplierSelected}
        
        if(['PCUK'].includes(SupplierName) && confirmStatus==='error') {
            Modal.confirm({
                icon: '',
                content: <span className='font-weight-bold fs-14'>Package has been successfully pushed to PCUK. Do you want to push it again?</span>,
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    this.setState({
                        isOpen: true
                    })
                }
            })
            return
        }

        this.setState({
            isOpen: true
        })
    }

    _handleClose = () => {
        this.setState({
            isOpen: false,
            isOpenExportFactory: false
        })
    }

    _showConfirmCancelOrderPF = () => {
        this.setState({
            cancelOrderPF: true
        })
    }

    _showConfirmCancelOrderPLEU = () => {
        this.setState({
            cancelOrderPLEU: true
        })
    }

    _showConfirmCancelOrderCW = () => {
        this.setState({
            cancelOrderCW: true
        })
    }

    _closeConfirmCancelOrderPF = () => {
        this.setState({
            cancelOrderPF: false
        })
    }

    _closeConfirmCancelOrderPLEU = () => {
        this.setState({
            cancelOrderPLEU: false
        })
    }

    _closeConfirmCancelOrderCW = () => {
        this.setState({
            cancelOrderCW: false
        })
    }

    _showConfirmCancelOrderMD = () => {
        this.setState({
            cancelOrderMD: true
        })
    }

    _closeConfirmCancelOrderMD = () => {
        this.setState({
            cancelOrderMD: false
        })
    }

    _showConfirmCancelOrderDPCN = () => {
        this.setState({
            cancelOrderDPCN: true
        })
    }

    _closeConfirmCancelOrderDPCN = () => {
        this.setState({
            cancelOrderDPCN: false
        })
    }

    _showConfirmCancelOrderSPUS = () => {
        this.setState({
            cancelOrderSPUS: true
        })
    }

    _closeConfirmCancelOrderSPUS = () => {
        this.setState({
            cancelOrderSPUS: false
        })
    }

    _showConfirmCancelOrderPBAU = () => {
        this.setState({
            cancelOrderPBAU: true
        })
    }

    _closeConfirmCancelOrderPBAU = () => {
        this.setState({
            cancelOrderPBAU: false
        })
    }

    _showConfirmCancelOrderDTUS = () => {
        this.setState({
            cancelOrderDTUS: true
        })
    }

    _closeConfirmCancelOrderDTUS = () => {
        this.setState({
            cancelOrderDTUS: false
        })
    }

    _showConfirmCancelOrderHFT71 = () => {
        this.setState({
            cancelOrderHFT71: true
        })
    }

    _closeConfirmCancelOrderHFT71 = () => {
        this.setState({
            cancelOrderHFT71: false
        })
    }

    _showConfirmCancelOrderGTUS = () => {
        this.setState({
            cancelOrderGTUS: true
        })
    }

    _closeConfirmCancelOrderGTUS = () => {
        this.setState({
            cancelOrderGTUS: false
        })
    }

    _showConfirmCancelOrderMPUS = () => {
        this.setState({
            cancelOrderMPUS: true
        })
    }

    _closeConfirmCancelOrderMPUS = () => {
        this.setState({
            cancelOrderMPUS: false
        })
    }

    _handleOpenCheckDetail = (e) => {
        e.preventDefault()

        this.setState({
            isOpenCheckDetail: true
        })
    }

    _handleCloseCheckDetail = () => {
        this.setState({
            isOpenCheckDetail: false
        })
    }

    _showConfirmCancelOrderFSUS = () => {
        this.setState({
            cancelOrderFSUS: true
        })
    }

    _closeConfirmCancelOrderFSUS = () => {
        this.setState({
            cancelOrderFSUS: false
        })
    }

    _showConfirmCancelOrderPYUS = () => {
        this.setState({
            cancelOrderPYUS: true
        })
    }

    _closeConfirmCancelOrderPYUS = () => {
        this.setState({
            cancelOrderPYUS: false
        })
    }
    
    _showConfirmCancelOrderTEEU = () => {
        this.setState({
            cancelOrderTEEU: true
        })
    }

    _closeConfirmCancelOrderTEEU = () => {
        this.setState({
            cancelOrderTEEU: false
        })
    }
    handleEmbroiderySurcharge = () => {
        this.props.handleEmbroiderySurcharge()
    }
    _handleClickConfirm = async (e) => {
        e.preventDefault()
        const { fulfillment } = { ...this.props }
        const { _id: fulfillmentId } = { ...fulfillment }
        const payload = {
            fulfillmentId: fulfillmentId,
            isConfirm: true,
        }
        try {
            const {success, message: mess, data} = await confirmPushSupplier(payload)
            if(!success) {
                throw new Error(mess)
            }
            message.success('Confirm success!')
            broker.publish("TRACKING_UPDATED")
            broker.publish('ORDER_REFRESHED_ORDER')
            broker.publish('ORDER_REFRESH_BRANDING')
            broker.publish('ORDER_RESTORE')
        } catch (error) {
            message.error(error.message || 'Unknown error')
        }
    }

    render() {
        const {fulfillment, supplierSelected, paymentStatus, preference, showPushButton} = this.props
        const {
            isOpen, isOpenExportFactory, cancelOrderPF,
            cancelOrderPLEU, cancelOrderCW, cancelOrderMD,
            cancelOrderSPUS, cancelOrderDPCN, cancelOrderPBAU,
            cancelOrderDTUS, cancelOrderHFT71, cancelOrderGTUS, isOpenCheckDetail,
            cancelOrderMPUS, cancelOrderFSUS, cancelOrderPYUS, cancelOrderTEEU,
        } = this.state
        const disabled = /*paymentStatus === 'partially_refunded' || */paymentStatus === 'refunded' /*|| (!!fulfillment.artwork_pf_status && fulfillment.artwork_pf_status !== 'done')*/ // temporary off condition partially_refunded

        const permissionsData = getLocalData('permissions') ? getLocalData('permissions') : {}
        const embroiderySurchargeValue = fulfillment && fulfillment.embroiderySurcharge && fulfillment.embroiderySurcharge.amount
        const {factory_info} = {...fulfillment}
        const {metadata} ={...factory_info}
        const {confirmStatus}={...metadata}
        const {name:SupplierName} = {...supplierSelected}

        return (
            <div className="pushTo1C">
                <div className='d-flex align-items-center justify-content-end'>
                    {
                        !!fulfillment && fulfillment.push_status === "pushed" && showPushButton &&
                        <Tooltip title="get order detail from SupplierName">
                            <button onClick={this._handleOpenCheckDetail} className="btn btn-primary mr-auto">
                                Check Detail
                            </button>
                        </Tooltip>
                    }

                    {
                        !!fulfillment && !!fulfillment.factory_info &&
                        !!fulfillment.factory_info.status &&
                        fulfillment.factory_info.status === 'pending' &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'PF' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderPF} className="btn btn-danger mr-3">
                            Cancel PF
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'on_hold') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'PL EU' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderPLEU} className="btn btn-danger mr-3">
                            Cancel PL EU
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'in_production') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'CW' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderCW} className="btn btn-danger mr-3">
                            Cancel CW
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'in_production' || fulfillment.factory_info.status === 'packaged') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'MDUS' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderMD} className="btn btn-danger mr-3">
                            Cancel MD
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        fulfillment.factory_info.status === 'pending' &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'DPCN' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderDPCN} className="btn btn-danger mr-3">
                            Cancel DPCN
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'in_production') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'SPUS' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderSPUS} className="btn btn-danger mr-3">
                            Cancel SPUS
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'PBAU' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderPBAU} className="btn btn-danger mr-3">
                            Cancel PBAU
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'DTUS' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderDTUS} className="btn btn-danger mr-3">
                            Cancel DTUS
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'HFT71' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderHFT71} className="btn btn-danger mr-3">
                            Cancel HFT71
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'on_hold') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'GTUS' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderGTUS} className="btn btn-danger mr-3">
                            Cancel GTUS
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (['pending', 'in_production', 'packaged'].includes(fulfillment.factory_info.status)) &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'MPUS' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderMPUS} className="btn btn-danger mr-3">
                            Cancel MPUS
                        </button>
                    }
                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'in_production') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'PYUS' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderPYUS} className="btn btn-danger mr-3">
                            Cancel PYUS
                        </button>
                    }

                    {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'on_hold' || fulfillment.factory_info.status === 'in_production') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'FSUS' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderFSUS} className="btn btn-danger mr-3">
                            Cancel FSUS
                        </button>
                    }

                    {/* {
                        !!fulfillment && !!fulfillment.factory_info && !!fulfillment.factory_info.status &&
                        (fulfillment.factory_info.status === 'pending' || fulfillment.factory_info.status === 'on_hold') &&
                        !!supplierSelected && supplierSelected.sku_prefix === 'TEEU' && showPushButton &&
                        <button onClick={this._showConfirmCancelOrderTEEU} className="btn btn-danger mr-3">
                            Cancel TEEU
                        </button>
                    } */}

                    <CancelOrderSupplier
                        fulfillment={fulfillment}
                        showPushButton={showPushButton}
                    />

                    {
                        !!this.props.showEmbroiderySurchargeButton &&
                        <label
                            className="PackageEmbroiderySurcharge">{this.props.getButtonEmbroiderySurchargeText()}</label>
                    }

                    {['PCUK'].includes(SupplierName) && confirmStatus === 'error' &&
                        <a className="btn btn-warning ml-2 text-white btnConfirm" onClick={this._handleClickConfirm}>
                            Confirm
                        </a>
                    }

                    {
                        permissionsData.hasOwnProperty('ffm_order_detail_push') && showPushButton &&
                        <button onClick={this._handleClickPush} disabled={disabled}
                                className="btn btn-primary ml-2">Push</button>
                    }

                </div>

                {/*Xưởng cũ*/}
                <PackagePusherEditor
                    {...this.props}
                    isOpen={isOpen}
                    onClose={this._handleClose}
                    preference={preference}
                />
                {/*Xưởng mới*/}
                <PackagePusherFactoryEditor
                    {...this.props}
                    isOpen={isOpenExportFactory}
                    onClose={this._handleClose}
                />
                {/*Cancel đơn với xưởng PF*/}
                <CancelOrderPf
                    cancelOrderPF={cancelOrderPF}
                    fulfillment={fulfillment}
                    onClose={this._closeConfirmCancelOrderPF}
                />
                {/*Cancel đơn với xưởng PLEU*/}
                <CancelOrderPLEU
                    cancelOrderPLEU={cancelOrderPLEU}
                    fulfillment={fulfillment}
                    onClose={this._closeConfirmCancelOrderPLEU}
                />
                {/*Cancel đơn với xưởng CW*/}
                <CancelOrderCW
                    cancelOrderCW={cancelOrderCW}
                    fulfillment={fulfillment}
                    onClose={this._closeConfirmCancelOrderCW}
                />

                {/*Cancel đơn với xưởng MD*/}
                {
                    cancelOrderMD && <CancelOrderMD
                        cancelOrderMD={cancelOrderMD}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderMD}
                    />
                }
                {/*Cancel đơn với xưởng DPCN*/}
                {
                    cancelOrderDPCN && <CancelOrderDPCN
                        cancelOrderDPCN={cancelOrderDPCN}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderDPCN}
                    />
                }
                {/*Cancel đơn với xưởng SPUS*/}
                {
                    cancelOrderSPUS && <CancelOrderSPUS
                        cancelOrderSPUS={cancelOrderSPUS}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderSPUS}
                    />
                }
                {/*Cancel đơn với xưởng PBAU*/}
                {
                    cancelOrderPBAU && <CancelOrderPBAU
                        cancelOrderPBAU={cancelOrderPBAU}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderPBAU}
                    />
                }
                {/*Cancel đơn với xưởng DTUS*/}
                {
                    cancelOrderDTUS && <CancelOrderDTUS
                        cancelOrderDTUS={cancelOrderDTUS}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderDTUS}
                    />
                }
                {/*Cancel đơn với xưởng HFT71*/}
                {
                    cancelOrderHFT71 && <CancelOrderHFT71
                        cancelOrderHFT71={cancelOrderHFT71}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderHFT71}
                    />
                }
                {/*Cancel đơn với xưởng GTUS*/}
                {
                    cancelOrderGTUS && <CancelOrderGTUS
                        cancelOrderGTUS={cancelOrderGTUS}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderGTUS}
                    />
                }
                {/*Cancel đơn với xưởng MPUS*/}

                {
                    cancelOrderMPUS && <CancelOrderMPUS
                        cancelOrderMPUS={cancelOrderMPUS}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderMPUS}
                    />
                }

                {/*Cancel đơn với xưởng FSUS*/}

                {
                    cancelOrderFSUS && <CancelOrderFSUS
                        cancelOrderFSUS={cancelOrderFSUS}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderFSUS}
                    />
                }

                {/*Cancel đơn với xưởng PYUS*/}

                {
                    cancelOrderPYUS && <CancelOrderPYUS
                        cancelOrderPYUS={cancelOrderPYUS}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderPYUS}
                    />
                }

                {/*Cancel đơn với xưởng TEEU*/}

                {
                    cancelOrderTEEU && <CancelOrderTEEU
                        cancelOrderTEEU={cancelOrderTEEU}
                        fulfillment={fulfillment}
                        onClose={this._closeConfirmCancelOrderTEEU}
                    />
                }
                {
                    !!fulfillment && fulfillment.push_status === "pushed" &&

                    <PackageCheckDetail
                        {...this.props}
                        isOpen={isOpenCheckDetail}
                        onClose={this._handleCloseCheckDetail}
                    />
                }

            </div>
        )
    }
}

PushPackageItem.propTypes = {
    paymentStatus: PropTypes.string
}

export default PushPackageItem
