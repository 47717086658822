import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { message, Spin, Modal, Input, AutoComplete } from 'antd'
import { getListSuppliersV2 } from "../../../../services/api/SuppliersServices"
import { cloneVariantForSupplier, addStoreVariantForSupplier } from "../../../../services/api/ProductsServices"

ProductVariantSupplier.propTypes = {
    suppliers: PropTypes.array,
    productId: PropTypes.string,
    selectedSupplier: PropTypes.string,
    fetchListVariants: PropTypes.func.isRequired,
    fetchProductDetail: PropTypes.func.isRequired,
    fetchStoreVariants: PropTypes.func.isRequired,
}

function ProductVariantSupplier(props) {
    const { suppliers, selectedSupplier, productId, fetchListVariants, fetchProductDetail, fetchStoreVariants, disableAll, listStore = [] } = props
    const [listSuppliers, setListSuppliers] = useState([])
    const [error, setError] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [namespace, setNamespace] = useState('')
    const [storename, setStorename] = useState('')
    const [group, setGroup] = useState('')

    const _handleChangeOpenList = () => {
        setIsOpen(!isOpen)
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        _createStoreVariant()
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        _clearModal()
    }
    const _clearModal = () => {
        setNamespace('')
        setStorename('')
        setGroup('')
    }

    const _fetchListSuppliers = async () => {
        try {
            const response = await getListSuppliersV2({
                page: 1,
                limit: 100,
                active: true
            })

            const { success, data, message: error } = response

            if (!success) {
                return setError(error)
            }

            const { suppliers } = data

            setListSuppliers(suppliers)
        } catch (e) {
            setError(e.message)
        }
    }

    const _onCloneVariantNewSupplier = async (supplierId) => {
        setLoading(true)
        setIsOpen(false)

        try {
            const payload = {
                supplier: supplierId,
                from_supplier: selectedSupplier
            }

            const response = await cloneVariantForSupplier(productId, payload)

            const { success, message: error } = response

            if (!success) {
                setLoading(false)
                setIsOpen(false)
                return message.error(error)
            }

            setLoading(false)
            if (typeof fetchListVariants === 'function' && typeof fetchProductDetail === 'function') {
                await fetchProductDetail()
                await fetchListVariants()
            }
            message.success('Clone variant success.')
        } catch (e) {
            setLoading(false)
            setIsOpen(false)
            message.error(e.message)
        }
    }

    const _createStoreVariant = async () => {
        setLoading(true)
        setError('')

        const payload = {}
        if (namespace) payload.namespace = namespace
        if (storename) payload.storename = storename
        if (group) payload.group = group

        try {
            const response = await addStoreVariantForSupplier(productId, selectedSupplier, payload)

            const { success, message: error } = response

            if (!success) {

                return message.error(error)
            }

            message.success('Create store successfully.')
            setIsModalOpen(false)
            _clearModal()
            fetchListVariants()
            fetchStoreVariants()
        } catch (e) {
            setLoading(false)
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    const _handleChangeNamespace = (e) => {
        const { value } = e.target
        setNamespace(value.trim())
    }

    const _handleChangeStore = (e) => {
        const { value } = e.target
        setStorename(value.trim())
    }

    const _handleChangeGroup = (value) => {
        setGroup(value)
    }

    useEffect(() => {
        _fetchListSuppliers()
    }, [])

    const listSupId = !!suppliers && suppliers.length > 0 && suppliers.map(item => item._id)
    const groupOptions = listStore.length > 0 ? [...new Set(listStore.map(i => i.group).filter(Boolean))].map(i => ({ value: i })) : []

    return (
        <div className='ProductVariantSupplier'>
            {
                !!error && error.length > 0 && <div className="text-danger">{error}</div>
            }
            {!disableAll &&
                <Spin spinning={loading} tip="Clone waiting ...">
                    <div className="d-flex">
                        <div className="add-store" onClick={showModal}>
                            <span>Add Store</span>
                        </div>
                        <Modal
                            title="Add Store"
                            visible={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            okButtonProps={{ disabled: !namespace }}
                        >
                            <div className="item-store mb-2">
                                <div className='mb-1'>Namespace <span className='text-danger'>*</span></div>
                                <Input placeholder="Enter namespace" value={namespace} onChange={_handleChangeNamespace} />
                            </div>
                            <div className="item-store mb-2">
                                <div className='mb-1'>Storename</div>
                                <Input placeholder="Enter storename" value={storename} onChange={_handleChangeStore} />
                            </div>
                            <div className="item-group mb-2">
                                <div className='mb-1'>Group</div>
                                <AutoComplete style={{ width: '100%' }} placeholder="Enter group" value={group} onChange={_handleChangeGroup} options={groupOptions} />
                            </div>
                        </Modal>
                        <div className="ListSupplierVariant">
                            <div onClick={_handleChangeOpenList} className="CloneVariant">
                                <span>Add supplier</span>
                                <i className="ti-angle-down" />
                            </div>
                            <ul className={`ListSupplier ${isOpen ? 'IsOpen' : ''}`}>
                                {
                                    !!listSuppliers && listSuppliers.length > 0 && listSuppliers.map(item => (
                                        <li
                                            key={`clone_${item._id}`}
                                            className={`${!!listSupId && listSupId.length > 0 && listSupId.includes(item._id) ? 'Disable' : ''}`}
                                            onClick={() => _onCloneVariantNewSupplier(item._id)}
                                        >
                                            {item.name}
                                        </li>
                                    ))
                                }
                            </ul>
                            {
                                isOpen && <div className="Background" onClick={_handleChangeOpenList} />
                            }
                        </div>
                    </div>
                </Spin>
            }
        </div>
    )
}

export default ProductVariantSupplier
