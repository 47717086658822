export const JOB_STATUS = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Pending',
        value: 'pending',
    },
    {
        name: 'Failed',
        value: 'failed',
    },
    {
        name: 'Processing',
        value: 'processing',
    },
    {
        name: 'Processed',
        value: 'processed',
    },
    {
        name: 'Cancelled',
        value: 'cancelled',
    },
]

export const JOB_TYPES = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Fulfilled',
        value: 'fulfilled',
    },
    {
        name: 'Update tracking',
        value: 'update-tracking',
    },
    {
        name: 'Rejected',
        value: 'rejected',
    },
    {
        name: 'Processed item',
        value: 'processed-item',
    },
    {
        name: 'Undo processed item',
        value: 'undo-processed-item',
    },
    {
        name: 'Request update',
        value: 'request-update'
    },
    {
        name: 'Update Production Profit',
        value: 'update-production-profit'
    },
    {
        name : 'Update Box Info',
        value : 'update-box-info'
    },
    {
        name : 'Refund',
        value : 'refunded'
    },
    {
        name : 'Charge',
        value : 'charge'
    },
    {
        name : 'Cancel Fulfill',
        value : 'cancel-ffm'
    }
]
