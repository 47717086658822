import React from 'react'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import CreatePackageIssuePage from './CreatePackageIssuePage'
import '../styles/_index.scss'

function CreatePackageIssueContainer(props) {
    const { id, category } = props.match.params

    return (
        <div className='CreatePackageIssueContainer'>
            <DocTitleByStore title="Package Issue Create" />
            <CreatePackageIssuePage id={id} categoryParams={category}/>
        </div>
    )
}

export default CreatePackageIssueContainer