import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getListShippingZonesV2} from "../../../services/api/ShippingServices"


import {Select} from "antd"

const {Option} = Select

class ZonesSelector extends Component {
    state = {
        options: [],
        err: '',
        page: 1,
        limit: -1,
        input: '',
        message: ''
    }

    componentDidMount() {
        this._getCategories()
    }

    _getCategories = async () => {
        const payload ={
            title: "",
            limit:1000
        }
        
        return new Promise(async resolve => {

            try {
                const {success, data, message} = await getListShippingZonesV2(payload)
                if (!success) {
                    return this.setState({
                        err: message
                    })
                }
                this.setState(
                    () => ({
                        options: data.zones.map((item, index) => {
                            return {
                                id: item._id,
                                value: index,
                                label: item.title,
                                supplier : item.supplier
                            }
                        })
                    }),
                    () => resolve(this.state.options)
                )
            } catch (e) {
                this.setState({
                    err: e.message || e
                })
            }
        })
    }


    _handleChange = option => {
        const _selectedZone = (option !== undefined) ? option : ''
        this.props.handleChangeZone(_selectedZone)
    }

    render() {
        const {options, message, err} = this.state
        const {zone, width, placeholder, supplier} = this.props
        const newZone = !!supplier ? options.filter(i=>i.supplier._id === supplier) : options
        return (
            <div className="ZonesSelector w-auto">
                <Select
                    size={'large'}
                    allowClear
                    showArrow
                    defaultValue={zone}
                    value={zone || []}
                    style={{width}}
                    onChange={this._handleChange}
                    placeholder={placeholder? placeholder : 'Select'}
                >
                    {
                        newZone.map(item => {
                            if(!!item.label && item.supplier && !!item.supplier.name){
                                return <Option value={item.id} key={item.id}>{item.label} - {item.supplier.name}</Option>
                            }
                        })
                    }
                </Select>
                {err && (
                    <p className='text-danger'>{err}</p>
                )}
                {message &&
                <p className='text-primary'>{message}</p>
                }
            </div>
        )
    }
}

ZonesSelector.propTypes = {
    handleChangeZone: PropTypes.func.isRequired,
}

export default ZonesSelector
