import React, { Component } from 'react'

class OrderNote extends Component {
    _splitNote = (string) => {
        const listStrings = string.split(" ")

        if (listStrings.length > 25) {
            let new_arr = []
            for (let i = 0; i < 25; i++) {
                new_arr.push(listStrings[i])
            }
            return new_arr.join(' ') + '...'
        } else {
            return string
        }

    }

    render() {
        const { note } = this.props
        const newNote = note.split(",")

        return (
            <td className="OrderNote">
                {
                    newNote.length > 0 && newNote.map((item, index) => (
                        <div key={index}>{(note !== '') && `-${this._splitNote(item)}`}</div>
                    ))
                }
            </td>
        )
    }
}

export default OrderNote