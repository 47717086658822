import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PropTypes from "prop-types"
import {
    exportProductionStatus,
    exportsOrderCheckingDetails,
    exportOrdersBilling,
    exportOrdersSellerRequest,
    exportCailianGoogle,
    exportBaseGiffGoogle,
    getShipmentStatus,
    exportOrdersLocations,
    exportOrderDPCNGoogle,
    exportOrderDBUSGoogle,
    exportOrderDTUSGoogle,
    exportOrderSPUSGoogle,
    exportOrderWLUSGoogle,
    exportOrderFSUSGoogle,
    exportOrderDFUSGoogle,
    exportOrderGLUSGoogle,
    exportOrderUCUSGoogle,
    exportOrderEFUSGoogle,
    exportOrderBFUSGoogle,
    exportOrderMCUSGoogle,
} from "../../../../services/api/ExportOrderServices"
import queryString from 'qs'
import { getLocalData } from "../../../../services/StorageServices"
import { notification, Popconfirm } from "antd"
import SuppliersContext from '../../context/SuppliersContext'

class ExportOrdersModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                name: "",
                template: "billing"
            },
            validate: false,
            error: '',
            loading: false,
            exportInfo: {},
            dataExport: '',
            useOriginalArts: false
        }
    }

    _onChangeInput = (e) => {
        const { name, value } = e.target

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            validate: false,
            error: ''
        })
    }

    _onChangeSelect = (e) => {
        const { value } = e.target

        this.setState({
            form: {
                ...this.state.form,
                template: value
            }
        })
    }

    _onChangeOriginal = (e) => {
        const { value } = e.target

        this.setState({
            useOriginalArts: value
        })
    }

    _onSubmit = (e) => {
        e.preventDefault()
        this._submit()
    }

    _submit = async () => {
        const { filters, code } = this.props

        console.log('===code= check box==')
        console.log(code)
        console.log('===filters===')
        console.log(filters)

        const { form, useOriginalArts } = this.state
        const { template, name } = form
        let newFilters = {}
        const sheetName = getLocalData(`exportGgDrv.name.${template}`, '')
        const linkGG = getLocalData(`exportGgDrv.link.${template}`, '')
        const templatesExportFile = ["billing", "seller", "tracking", "status"]

        if (!templatesExportFile.includes(template)) {
            if ((!sheetName || !linkGG)) {
                if (window.confirm('You need to setup Google Sheet first. Click ok to setup the link')) {
                    window.open(`/a/orders?google_drv_sheet=true&supplier=${template}`)
                }
                return
            }

        }

        if (templatesExportFile.includes(template) && name === '') {
            return this.setState({
                validate: true,
                error: 'Please input file name'
            })
        } else {
            const format = /[~#%&*/\]:"\\|<>?]+/

            if (format.test(name)) {
                return this.setState({
                    validate: true,
                    error: 'File name not use special character.'
                })
            }
        }

        Object.keys(filters).map(item => {
            if (filters[item] !== '') {
                newFilters = { ...newFilters, [item]: filters[item] }
            }

            return newFilters
        })

        let filterNews = Object.assign({}, newFilters)

        if (code.length > 0) {
            filterNews = Object.assign(filterNews, { code: code.toString() })
        }

        let payload = { filename: name, sheetName, linkGG, query: filterNews, useOriginalArts }

        if (!useOriginalArts) {
            payload = { filename: name, sheetName, linkGG, query: filterNews }
        }

        if (template === 'dpcn' || template === 'dbus' || template === 'dtus') {
            payload = { sheetName, linkGG, query: filterNews }
        }

        const query = queryString.stringify(payload)

        console.log('===query===')
        console.log(query)

        let apiUrl = exportOrdersBilling

        if (template === 'tracking') {
            apiUrl = exportsOrderCheckingDetails
        } else if (template === 'status') {
            apiUrl = exportProductionStatus
        } else if (template === 'seller') {
            apiUrl = exportOrdersSellerRequest
        }

        try {
            if (templatesExportFile.includes(template)) {
                this.props.onToggle()

                return window.location.href = apiUrl(query)
            } else {
                this.setState({
                    loading: true
                })

                let response = {}

                if (template === 'cailian') {
                    response = await exportCailianGoogle(query)
                } else if (template === 'other') {
                    const payload = {
                        ...filters, sheetName, linkGG
                    }
                    response = await exportOrdersLocations(payload)
                } else if (template === 'basegift') {
                    response = await exportBaseGiffGoogle(query)
                } else if (template === 'shipment-status') {
                    const query = queryString.stringify({ sheetName: sheetName, sheetLink: linkGG })
                    response = await getShipmentStatus(query)
                } else if (template === 'dpcn') {
                    response = await exportOrderDPCNGoogle(query)
                } else if (template === 'dbus') {
                    response = await exportOrderDBUSGoogle(query)
                } else if (template === 'dtus') {
                    response = await exportOrderDTUSGoogle(query)
                } else if (template === 'spus') {
                    response = await exportOrderSPUSGoogle(query)
                } else if (template === 'wlus') {
                    response = await exportOrderWLUSGoogle(query)
                } else if (template === 'fsus') {
                    response = await exportOrderFSUSGoogle(query)
                } else if (template === 'dfus') {
                    response = await exportOrderDFUSGoogle(query)
                } else if (template === 'glus') {
                    response = await exportOrderGLUSGoogle(query)
                } else if (template === 'ucus') {
                    response = await exportOrderUCUSGoogle(query)
                } else if (template === 'efus') {
                    response = await exportOrderEFUSGoogle(query)
                } else if (template === 'bfus') {
                    response = await exportOrderBFUSGoogle(query)
                } else if (template === 'mcus') {
                    response = await exportOrderMCUSGoogle(query)
                }

                const { success, data } = response

                if (!success) {
                    this.setState({
                        loading: false
                    })

                    this.props.onToggle()

                    return notification.error({
                        message: 'Export error',
                        description: data,
                    })
                }

                this.setState({
                    loading: false
                })

                this.props.onToggle()

                notification.success({
                    message: 'Export success',
                    description: `Please check sheet ${sheetName} in link ${linkGG}.`,
                })
            }
        } catch (e) {
            this.setState({
                error: e.message
            })
        }
    }

    render() {
        const { error, validate, form, useOriginalArts } = this.state
        const { template } = form
        const templatesExportFile = ["billing", "seller", "tracking", "status"]
        const checkWarning = ['dpcn', 'dtus', 'spus', 'wlus', 'basegift']
        const { filters, suppliers } = this.props
        const suppliersSelected = suppliers.filter(i => filters.suppliers.includes(i._id)).map(i => i.name)
        const showConfirm = ['fsus', 'basegift', 'wlus', 'efus', 'ucus', 'bfus', 'mcus'].includes(template)

        return (
            <Modal isOpen={this.props.open} toggle={this.props.onToggle} className="TrackingModal">
                <ModalHeader toggle={this.props.onToggle}>
                    Export orders
                </ModalHeader>
                <ModalBody>
                    {
                        error && !validate && <div className="text-danger mb-2">{error}</div>
                    }

                    <form className="mb-3">
                        <label htmlFor="tracking_template">
                            Template
                        </label>
                        <select className="form-control mb-3" onChange={this._onChangeSelect}>
                            <option value='billing'>Check billing of Store/Order</option>
                            <option value='seller'>Request from seller</option>
                            <option value='tracking'>Check tracking detail</option>
                            <option value='status'>Check production status</option>
                            {/* <option disabled value='cailian'>Order Cailian Yoco Google Sheet</option> */}
                            {suppliersSelected.includes('BaseGift') &&
                                <option value='basegift'>BaseGiff Google Sheet</option>
                            }
                            <option value='shipment-status'>Shipment Status Google Sheet</option>
                            {suppliersSelected.includes('BFUS') &&
                                <option value='bfus'>Order BFUS Google Sheet</option>
                            }
                            {/* <option value='dpcn'>Order DPCN Google Sheet</option> */}
                            {/* <option disabled value='dbus'>Order DBUS Google Sheet</option> */}
                            {/* <option value='dtus'>Order DTUS Google Sheet</option> */}
                            {/* <option value="spus">Order SPUS Google Sheet</option> */}
                            {suppliersSelected.includes('WLUS') &&
                                <option value="wlus">Order WLUS Google Sheet</option>
                            }
                            {suppliersSelected.includes('FSUS') &&
                                <option value="fsus">Order FSUS Google Sheet</option>
                            }
                            {/* <option value="dfus">Order DFUS Google Sheet</option> */}
                            {suppliersSelected.includes('EFUS') &&
                                <option value="efus">Order EFUS Google Sheet</option>
                            }
                            {suppliersSelected.includes('UCUS') &&
                                <option value="ucus">Order UCUS Google Sheet</option>
                            }
                            {/* <option value="glus">Order GLUS Google Sheet</option> */}
                            <option value="other">Orders Information Google Sheet</option>
                            {suppliersSelected.includes('MCUS') &&
                                <option value="mcus">Order MCUS Google Sheet</option>
                            }
                        </select>
                        {
                            (templatesExportFile.includes(form.template)) &&
                            <>
                                <label htmlFor="tracking_name">
                                    File name
                                </label>
                                <input
                                    id="tracking_name"
                                    name="name"
                                    onChange={this._onChangeInput}
                                    className={`form-control ${validate ? 'error' : 'mb-3'}`}
                                />
                                {
                                    error && validate && <div className="text-danger mb-2">{error}</div>
                                }
                            </>
                        }

                        {
                            form.template === 'cailian' &&
                            <>
                                <label htmlFor="tracking_name">
                                    Select type artwork export
                                </label>
                                <select className="form-control" value={useOriginalArts}
                                    onChange={this._onChangeOriginal}>
                                    <option value={false}>HongKong artwork</option>
                                    <option value={true}>Original artwork</option>
                                </select>
                            </>
                        }
                    </form>

                    {checkWarning.includes(form.template) &&
                        <small className='text-danger font-weight-bold'>
                            By clicking the export button, the orders will be considered as successfully pushed to the workshop once.
                        </small>
                    }

                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-outline-primary" onClick={this.props.onToggle}>
                        Cancel
                    </button>

                    {showConfirm ? <Popconfirm
                        title={<div><strong>Pay Attention:</strong> All exported orders will be marked as pushing to suppliers for producing!</div>}
                        onConfirm={this._onSubmit}
                        okText="Yes"
                        cancelText="No"
                        zIndex={99999999}
                    >
                        <button
                            className="btn btn-primary"
                            disabled={this.state.loading}
                            // onClick={this._onSubmit}
                        >
                            {this.state.loading && <span className="spinner-border spinner-border-sm mr-2" />}
                            <span>{this.state.loading ? 'Exporting...' : 'Export'}</span>
                        </button>
                    </Popconfirm> : <button
                        className="btn btn-primary"
                        disabled={this.state.loading}
                        onClick={this._onSubmit}
                    >
                        {this.state.loading && <span className="spinner-border spinner-border-sm mr-2" />}
                        <span>{this.state.loading ? 'Exporting...' : 'Export'}</span>
                    </button>}
                </ModalFooter>
            </Modal>
        )
    }
}

ExportOrdersModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
}

export default function (props) {
    return <SuppliersContext.Consumer>
        {suppliers => {
            return <ExportOrdersModal suppliers={suppliers} {...props} />
        }}
    </SuppliersContext.Consumer>
}
