import React, { useState, useEffect } from 'react'
import { Modal, Checkbox, Spin } from 'antd'
import moment from 'moment'


function SelectItemsModal(props) {
    const { listItem, packageName, handleChangeSelectAll, handleSelected, handleAddNewItems, listAddeds, handleCloseModal, handleHover, handleLeave, ...rest } = { ...props }
    const [listData, setListData] = useState(listData || [])
    const [loading, setLoading] = useState(false)
    const [selectedAll, setSelectedAll] = useState(false)
    const [listSelecteds, setListSelecteds] = useState(listAddeds || [])

    const _handleChangeSelectAll = (isActive = false) => {
        if (isActive) {
            const listSelectedId = listData.map((item, index) => index)
            setListSelecteds(listSelectedId)
            setSelectedAll(true)
        } else {
            setListSelecteds([])
            setSelectedAll(false)
        }
    }

    const _handleSelected = (index, isAdded) => {
        if (isAdded) {
            if (!listSelecteds.includes(index)) {
                setListSelecteds([...listSelecteds, index])
                setSelectedAll(listSelecteds.length + 1 === listData.length)
            }
        } else {
            const filtered = listSelecteds.filter((item) => item !== index)

            setListSelecteds(filtered)
            setSelectedAll(false)
        }
    }

    const handleSaveSelect = async () => {
        handleAddNewItems(listSelecteds, listData)
        setListSelecteds([])
        _handleCloseModal()
    }

    const _handleCloseModal = () => {
        setSelectedAll(false)
        setListSelecteds([])
        handleCloseModal()
    }

    useEffect(() => {
        if (rest.visible && packageName) {
            setListData(listItem)
            setListSelecteds(listAddeds || [])
            setSelectedAll(!!listItem.length && listItem.length === listAddeds.length)
        }
    }, [listItem, rest.visible])

    const handleHoverBarcode = (image) => {
        handleHover(image)
    }

    const handleLeaveBarcode = () => {
        handleLeave()
    }

    return (
        <Modal
            {...rest}
            onOk={handleSaveSelect}
            onCancel={_handleCloseModal}
        >
            <Spin spinning={loading} tip='Loading...'>
                <div className="modalContent">
                    <table className='IssueItemsTable table'>
                        <thead>
                            <tr>
                                <th><Checkbox checked={selectedAll} onChange={(e) => _handleChangeSelectAll(e.target.checked)} /></th>
                                <th>Reference number</th>
                                <th>Supplier</th>
                                <th>Product type</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                                <th>Tracking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!listData && listData.length > 0 ? listData.map((i, index) => {
                                const { supplier, reference_number, mockup, variant_data, tracking_number, pushed_at, quantity } = Object.assign({}, i)
                                const { name } = Object.assign({}, supplier)
                                const { product_type, size, color } = Object.assign({}, variant_data)
                                return (
                                    <tr key={`packageItem_${index}`}>
                                        <td><Checkbox checked={listSelecteds.includes(index)} onChange={() => _handleSelected(index, !listSelecteds.includes(index))} /></td>
                                        <td>
                                            <div className="referenceNumber text-primary">
                                                <span className='referenceNumberText' onMouseOver={() => handleHoverBarcode(mockup)} onMouseLeave={handleLeaveBarcode}>
                                                    {reference_number}
                                                </span>

                                            </div>
                                            <div className="pushedAt">{pushed_at ? moment(pushed_at).format('HH:mm DD/MM/YYYY') : ''}</div>
                                        </td>
                                        <td>{name}</td>
                                        <td>{product_type}</td>
                                        <td>{(size && color) ?
                                            `${size || ''}${size && color ? '/' : ''}${color || ''}` : ''
                                        }
                                        </td>
                                        <td>{quantity}</td>
                                        <td className='text-primary'>{tracking_number}</td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td colSpan={7}>No Issue item</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Spin>
        </Modal>
    )
}

export default SelectItemsModal