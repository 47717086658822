import React, { useEffect, useState, useRef } from "react"
import { message, Modal, Input, Select } from "antd"
import {
  getShippingZoneDetailV2,
  updateShippingZoneV2, getListShippingZonesV2
} from "../../../services/api/ShippingServices"

const { Option } = Select
const { TextArea } = Input

function ShippingZonesEdit(props) {
  const [showEdit, setShowEdit] = useState(false)
  const [title, setTitle] = useState("")
  const [supplier, setSupplier] = useState("")
  const [countryCode, setCountryCode] = useState([])
  const [description, setDescription] = useState("")
  const [error, setError] = useState({})
  const [supplierCountryId, setSupplierCountryId] = useState([])
  const [allowStates, setAllowStates] = useState("")
  const { listSupplier, id, listCountries, fetchListShippingZones } = props

  const usedListCountry = useRef()

  const getShippingZoneDetail = async () => {
    try {
      const response = await getShippingZoneDetailV2(id)
      const { data, success, message } = response
      if (success) {
        data.title ? setTitle(data.title) : setTitle("")
        data.supplier ? setSupplier(data.supplier._id) : setSupplier("")
        if (data.country_code) {
          setCountryCode(data.country_code)
          usedListCountry.current = data.country_code
        }
        data.description ? setDescription(data.description) : setDescription("")
        data.allow_states && data.allow_states.length>0 ? setAllowStates(data.allow_states.toString()) : setAllowStates("")
      }
    } catch (error) {
      message.error(error)
    }
  }

  const showModal = () => {
    getShippingZoneDetail()
    setShowEdit(true)
  }

  const handleCancel = () => {
    setShowEdit(false)
    setSupplier("")
    setCountryCode([])
    setSupplier("")
    setDescription("")
    setAllowStates("")
  }

  const getListStates = (value) => {
    const state = value.toUpperCase().replace(/,|", "|\n/g,',').split(",")
    const filterStates = state.filter(item=>{ 
      if(item.trim() === "") return 
      return item
    })
    return filterStates
  }

  const handleOk = async () => {
    const validate = {}

    if (title == "") {
      validate.errorTitle = "Shipping zone title is required!"
    }
    if (supplier == undefined || supplier == "") {
      validate.errorSupplier = "Please select Supplier!"
    }
    if (!(countryCode.length > 0)) {
      validate.errorCountry = "Please select Country!"
    }

    setError(validate)

    if (
      validate.errorTitle == undefined &&
      validate.errorSupplier == undefined &&
      validate.errorSupplier == undefined
    ) {
      const Payload = {
        title: title,
        supplier: supplier,
        country_code: countryCode,
        description: description,
        state: countryCode.includes("US") ? getListStates(allowStates) : []
      }

      try {
        const response = await updateShippingZoneV2(id, Payload)
        const { data, success, message: error } = response
        if (success) {
          message.success("Update Shipping Zone is success!")
        } else {
          message.error(error)
        }
      } catch (error) {
        message.error(error)
      }
      setTitle("")
      setCountryCode([])
      setDescription("")
      setSupplier("")
      setAllowStates("")
      setShowEdit(false)
      fetchListShippingZones()
    }
  }

  const onchangeInput = (e) => {
    const { value, name } = e.target
    if (name == "title") {
      setTitle(value)
    }
    if (name == "description") {
      setDescription(value)
    }
    if (name == "allow_states") {
      setAllowStates(value)
    }
  }

  const checkCountry = async (value) => {
    try {
      const Payload = {
        supplier: value
      }
      const response = await getListShippingZonesV2(Payload)
      const { data, success, message: err } = response

      if (success) {
        const countryitem = data.zones.reduce((mem, zone)=>{

          return [...mem, ...zone.country_code.map(code=>code)]
          }, [])
          const suppliercountry = countryitem.filter(i => {
            if (!!usedListCountry.current) {
              for (let v of usedListCountry.current) {
                if (i.code === v) return false
              }
              return true
            }
          }).map(item => item._id)

        setSupplierCountryId(suppliercountry)
      } else {
        message.error(err)
      }

    } catch (error) {
      message.error(error)
    }
  }

  useEffect(() => {
    if (!supplier) return
    checkCountry(supplier)


  }, [supplier])

  const onchangeSupplier = (value) => {
    setSupplier(value)
  }

  const newCountry = listCountries.length
    ? listCountries.map((item) => {
      const { country } = item
      return {
        id: country._id,
        title: country.title,
        value: country.code,
      }
    })
    : []

  const onchangeCountry = (value) => {
    if (value.some(i => i === 'all')){

      const newListCountry = newCountry.filter(i=>{
          for (let country of supplierCountryId){
              if(country === i.id) return false
          }
          return true
      })
      return setCountryCode(newListCountry.map(item=>item.value))
      
  } 
    setCountryCode([...value])
  }

  return (
    <>
      <a onClick={showModal} className="edit px-1">
        Edit
      </a>
      <Modal
        visible={showEdit}
        title="Update Shipping Zone"
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <label>Title</label>
        {!!error.errorTitle && (
          <small className="float-right" style={{ color: "red" }}>
            {error.errorTitle}
          </small>
        )}
        <Input
          placeholder="Input Title"
          className="formFilter mb-3"
          value={title}
          allowClear
          name="title"
          onChange={onchangeInput}
        ></Input>

        <label>Supplier</label>
        {!!error.errorSupplier && (
          <small className="float-right" style={{ color: "red" }}>
            {error.errorSupplier}
          </small>
        )}
        <Select
          placeholder={"Choose Supplier" || []}
          className="formFilter w-100 mb-3"
          value={supplier || undefined}
          onChange={onchangeSupplier}
        >
          {listSupplier &&
            listSupplier.map((supplier, index) => (
              <Option value={supplier._id} key={index}>
                {supplier.name}
              </Option>
            ))}
        </Select>

        <label>Choose country</label>
        {!!error.errorCountry && (
          <small className="float-right" style={{ color: "red" }}>
            {error.errorCountry}
          </small>
        )}
        <Select
          placeholder={"Select Country" || []}
          onChange={onchangeCountry}
          mode="multiple"
          className="formFilter w-100 mb-3"
          value={countryCode || []}
          allowClear
          showArrow
        >
          <Option key="all" value="all">
            ---SELECT ALL---
          </Option>
          {newCountry.map((data, index) => (
            <Option key={index} disabled={(supplierCountryId.find(i => i === data.id)) ? true : false} value={data.value}>
              {data.title}
            </Option>
          ))}
        </Select>
        {countryCode.includes('US')&&
          <>
            <label>States</label>
            <TextArea
              placeholder="Input States"
              className="formFilter"
              value={allowStates}
              name="allow_states"
              onChange={onchangeInput}
              rows="6"
              allowClear
            ></TextArea>
          </>
        }

        <label>Description</label>
        <TextArea
          placeholder="Input Description"
          className="formFilter"
          value={description}
          name="description"
          onChange={onchangeInput}
          rows="6"
          allowClear
        ></TextArea>
      </Modal>
    </>
  )
}

export default ShippingZonesEdit
