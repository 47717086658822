import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import ModalInformation from "../../detail-infomation/ModalInformation"

class OrderNumber extends Component {
    render() {
        const {webhook} = this.props
        const {order} = webhook
        let newId = '-', nameSpace = '-', idObject = '#'

        if (order !== null) {
            const {id, namespace, _id} = order
            newId = id
            nameSpace = namespace
            idObject = _id
        }

        return (
            <td className="OrderNumber">
                <div>
                    <Link to={`orders/${idObject}`}>{newId}</Link>
                    <ModalInformation webhook={webhook}/>
                </div>
                <div>
                    <code>{nameSpace}</code>
                </div>
            </td>
        )
    }
}

OrderNumber.propTypes = {
    webhook: PropTypes.object.isRequired,
}
export default OrderNumber
