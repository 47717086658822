import React, {useState, useEffect} from 'react'
import {Button, notification} from 'antd'
import OrderPartnersFilters from './OrderPartnersFilters'
import OrderPartnersTable from './OrderPartnersTable'
import OrderPartnersProvider from "./OrderPartnersProvider"
import UseDebounce from '../helpers/UseDebounce'
import {searchOrdersSupplier} from "../../../services/api/OrdersServices"
import {exportPackageManagementV2} from "../../../services/api/ExportOrderServices"
import {UndoOutlined, CloudDownloadOutlined} from '@ant-design/icons'
import {getFiltersStorage} from "../../shared/ManageFiltersState"
import ExportPackage from "./popup/ExportPackage"
import {setLocalData, getLocalData} from "../../../services/StorageServices"

const OrderPartnersPage = (props) => {
    const filtersStorage = getFiltersStorage('ordersSupplier')
    const {params} = props
    const [page, _setPage] = useState(1)
    const [limit, _setLimit] = useState(50)
    const [loading, _setLoading] = useState(true)
    const [total, _setTotal] = useState(0)
    const [error, _setError] = useState('')
    const [ordersLists, _setOrdersLists] = useState([])

    const [link, setLink] = useState('')
    const [sheetName, setSheetName] = useState('')
    const [errorExport, setErrorExport] = useState('')
    const [loadingExport, setLoadingExport] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const [filters, _setFilters] = useState(Object.keys(params).length === 0 ? filtersStorage : params)
    //setFilterStorage('ordersSupplier', Object.keys(params).length === 0 ? filtersStorage : params)

    const debouncedSearchSupplier = filters.suppliers === [] ? UseDebounce(filters.suppliers, 0) : UseDebounce(filters.suppliers, 500)
    const debouncedSearchCustomReference = filters.custom_references === [] ? UseDebounce(filters.custom_references, 0) : UseDebounce(filters.custom_references, 500)
    const debouncedSearchNamespace = filters.namespaces === [] ? UseDebounce(filters.namespaces, 0) : UseDebounce(filters.namespaces, 500)
    const debouncedSearchPackageName = filters.names === [] ? UseDebounce(filters.names, 0) : UseDebounce(filters.names, 500)

    const _hadleSetState = (filters) => {
        console.log(filters)
        setLocalData('ordersSupplier.FiltersState', filters, false, 'sessionStorage')
        //setFilterStorage('ordersSupplier', filters)
        _setFilters(filters)
        _setPage(1)
    }

    const _clearFilters = () => {
        _setFilters({})
    }

    const _fetchListOrders = async () => {
        try {
            _setLoading(true)
            _setOrdersLists([])
            _setError('')

            const payload = {
                ...filters,
                page,
                limit
            }

            const response = await searchOrdersSupplier(payload)

            const {success, message, data} = response

            if (!success) {
                _setLoading(false)
                return _setError(message)
            }

            const {total, fulfillments: fulfillmentsLists} = data

            _setOrdersLists(fulfillmentsLists)

            _setLoading(false)
            _setError('')
            _setTotal(total)
        } catch (e) {
            _setLoading(false)
            _setError(e.message)
        }
    }

    const _handleSetPage = (page) => {
        _setPage(page)
    }

    const _handleSetSize = (size) => {
        _setLimit(size)
    }

    function _openModal() {
        setIsVisible(true)
    }

    function _closeModal() {
        setIsVisible(false)
    }

    function _handleChangeLink(value) {
        setLink(value)
        setLocalData('export.package.link', value)
    }

    function _handleChangeName(value) {
        setSheetName(value)
        setLocalData('export.package.sheetName', value)
    }

    async function exportPackage() {
        setLoadingExport(true)
        setErrorExport('')

        try {
            const payload = {
                ...filters,
                page,
                limit,
                link,
                sheetName
            }

            const response = await exportPackageManagementV2(payload)

            const {success, message: error} = response

            if (!success) {
                setLoadingExport(false)
                setErrorExport(error)

                return
            }

            setLoadingExport(false)
            _closeModal()

            notification.success({
                message: 'Export success',
                description: `Please check sheet ${sheetName} in link ${link}.`,
            })


        } catch (e) {
            setLoadingExport(false)
            setErrorExport(e.message)
        }
    }

    useEffect(() => {
        _fetchListOrders()
    }, [page, limit, debouncedSearchSupplier, debouncedSearchPackageName, debouncedSearchNamespace, debouncedSearchCustomReference, filters.production_status, filters.shipping_carrier, filters.package_status, filters.tracking_status, filters.delay, filters.delayed_days, filters.pushed_type, filters.pushed_at])

    useEffect(() => {
        const link = getLocalData('export.package.link')
        const name = getLocalData('export.package.sheetName')

        setLink(link)
        setSheetName(name)
    }, [])

    return (
        <OrderPartnersProvider filters={filters} setState={_hadleSetState} loadOrders={_fetchListOrders}>
            <div className="NotificationPage pt-3">
                <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between mt-3 mb-3'>
                    <h1 className="PageTitle">Package Management</h1>
                    <div className="d-flex align-items-center justify-content-end mr-lg-0 mr-3">
                        <div className="total mr-3"><strong>{total} items</strong></div>
                        <Button onClick={_clearFilters}><UndoOutlined/> Clear filter</Button>
                        <Button type="primary" className="ml-3" onClick={_openModal}><CloudDownloadOutlined/> Export
                            package</Button>
                    </div>
                </div>

                <div className='SectionInner'>
                    <div className='filter mb-3 align-items-center'>
                        <div className="d-flex align-items-center justify-content-between">
                            <OrderPartnersFilters/>

                        </div>
                    </div>
                    <div className="table">
                        {error && <div className="text-danger">{error}</div>}
                        <OrderPartnersTable
                            loading={loading}
                            limit={limit}
                            page={page}
                            total={total}
                            orders={ordersLists}
                            onChangeSize={_handleSetSize}
                            onChangePage={_handleSetPage}
                        />
                    </div>
                </div>

                <ExportPackage
                    link={link}
                    sheetName={sheetName}
                    error={errorExport}
                    loading={loadingExport}
                    isVisible={isVisible}
                    onCancel={_closeModal}
                    onChangeLink={_handleChangeLink}
                    onChangeName={_handleChangeName}
                    onUpdate={exportPackage}
                />
            </div>
        </OrderPartnersProvider>
    )
}

export default OrderPartnersPage
