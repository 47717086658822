import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Pagination, message } from "antd"
import UseDebounce from '../helpers/UseDebounce'
import { getLocalData } from '../../../services/StorageServices'
import { getCatalogVariants } from '../../../services/api/MappingVariants'
import CatalogProductVariantsTable from './CatalogProductVariantsTable'
import { searchProducts } from '../../../services/api/ProductsServices'
import MappingVariantsFilter from './MappingVariantsFilter'


const MappingVariantsPage = () => {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [listProductTypeFulfill, setListProductTypeFulfill] = useState([])
    const [listCatalogVariants, setListCatalogVariants] = useState([])
    const [loading, setLoading] = useState(false)
    const [supplier, setSupplier] = useState('')
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [limit, setLimit] = useState(20)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [filters, setFilters] = useState({})

    const _handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const _handleChangeFilters = (field, value) => {
        if (field && (field.updated_at)) {
            setFilters({ ...filters, [Object.keys(field)[0]]: field.updated_at })
            setPage(1)
            return
        }
        if(field === 'searchField'){
            setFilters({ ...filters, search: '', [field]: value })
            setPage(1)
            return
        }
        setFilters({ ...filters, [field]: value })
        setPage(1)
    }

    const _fetchCatalogProductVariants = async () => {

        setLoading(true)
        setError('')
        // const payload = {
        //     catalog_sku: '',
        //     ffm_sku: 'SPUS',
        //     product_title: '',
        //     product_type: '',
        //     store_mode: ['normal'],
        //     supplier: '63bfd5314f6e5419331dde95',
        //     mapping_status: '',
        //     catalog_status: '',
        //     updated_at: {
        //         "from": "15/01/2024",
        //         "to": "23/01/2024"
        //     },
        //     page: 1,
        //     limit: 10
        // }

        const payload = {
            page: page,
            limit: limit
        }

        if(filters.searchField){
            if(filters.searchField === "SKU Variant Merchize" && filters.search) payload.catalog_sku = filters.search
            if(filters.searchField === "SKU Variant Supplier" && filters.search) payload.ffm_sku = filters.search
            if(filters.searchField === "Product Type Fulfill" && filters.search) payload.product_type = filters.search
        }else{
            if(filters.search) payload.catalog_sku = filters.search
        }

        if(filters.mode) payload.store_mode = [filters.mode]

        if(filters.supplier) payload.supplier = filters.supplier

        if(filters.status) payload.mapping_status = filters.status

        if(filters.catalogStatus) payload.catalog_status = filters.catalogStatus

        if(filters.updated_at) payload.updated_at = filters.updated_at

        try {
            const res = await getCatalogVariants(payload)
            const { data, success, message: error } = res

            if (!success) {
                setLoading(false)
                return message.error(error)
            }
            
            // if (success) {
            const { pages, total, catalogProductVariants } = data
            setListCatalogVariants(catalogProductVariants)
            setPages(pages)
            setTotal(total)
            // }

            setLoading(false)
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const _fetchListProductTypeFulfill = async () => {
        try {
            const { data, success } = await searchProducts({ limit: 10000 })
            if (!success) return

            const newListProducts = data.products.map(item => item.type).filter(item => !!item)
            const listProductsFinal = []
            newListProducts.map(item => {
                if(!listProductsFinal.includes(item)){
                    listProductsFinal.push(item)
                }
            })

            const listOptionsProductType = listProductsFinal.map(item => {
                return {
                    label: item,
                    value: item
                }
            })

            setListProductTypeFulfill(listOptionsProductType)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    useEffect(() => {
        _fetchListProductTypeFulfill()
    }, [])

    useEffect(() => {
        _fetchCatalogProductVariants()
    }, [page, limit, filters.search, filters.mode, filters.supplier, filters.status, filters.catalogStatus, filters.updated_at])

    if (error) return <i className='text-danger fs-14'>{error}</i>

    return (
        <div className="MappingVariantsPage">
            <div className="d-flex HeaderPage justify-content-between">
                <h1 className="PageTitle mt-2 mb-2">Mapping Catalog - Product Line</h1>
                {/* {permissionsData.includes('ffm_shipping_fee_create') &&
                    <div className='Create-shippingFee d-flex align-items-center'>
                        <div className='row justify-content-end'>
                            <div className='col-auto ml-3 ml-sm-0 mr-3 mr-lg-0'>
                                <Link to="/a/shipping-fee/new" className="btn btn-primary">Add shipping rate</Link>
                            </div>
                        </div>
                    </div>
                } */}
            </div>

            <div className="SectionInner">
                <div className="filter align-items-center mt-3">
                    <MappingVariantsFilter
                        listProductTypeFulfill={listProductTypeFulfill}
                        listOptionsSearchField={['SKU Variant Merchize', 'SKU Variant Supplier', 'Product Type Fulfill']}
                        handleChangeFilters={_handleChangeFilters}
                        filters={filters}
                    />
                </div>

                {
                    loading && <div>Loading...</div>
                }

                {
                    // !loading && listCatalogVariants.length > 0 &&
                    <div className="mt-3">
                        <div className='mb-2' style={{textAlign: 'right'}}>
                            <label style={{fontWeight: 'bold'}}>Total: </label> {total} items
                        </div>
                        
                        <CatalogProductVariantsTable
                            listCatalogVariants={listCatalogVariants}
                            permissionsData={permissionsData}
                        />
                        <Pagination current={page} total={total} pageSize={limit} onChange={_handlePageChange}
                            className='text-right' showSizeChanger pageSizeOptions={['10', '20', '50', '100']}
                        />
                    </div>
                }

                {
                    !loading && listCatalogVariants.length === 0 && <div>No result</div>
                }

            </div>
        </div>
    )
}

export default MappingVariantsPage