import React, {Component} from 'react'
import {DatePicker} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import defaultLocale from './FilterDate.locale'
import mergeDeep from '../../helpers/mergeDeep'

const {RangePicker} = DatePicker

class FilterDateCustomFormat extends Component {
    _handleDateChange = (date, dateString) => {
        const [from, to] = dateString
        const {field, onDatesChange} = this.props

        onDatesChange({[field]: {from, to}})
    }

    _renderClassName = () => {
        const {className} = this.props
        const strClassName = className + ''

        const trimClassName = strClassName.trim()

        if (!trimClassName) {
            return 'FilterDateRange'
        }

        return `FilterDateRange mr-3 ${trimClassName}`
    }

    render() {
        const {heading, value, showTime, classDrop, loading, locale, rangePickerStyle, style, dateFormat} = this.props
        const DATE_FORMAT = dateFormat ? dateFormat  : 'DD/MM/YYYY'
        console.log('dateFormat: ', DATE_FORMAT)
        const {from, to} = value
        const rangeValue = from && to ? [moment(from, DATE_FORMAT), moment(to, DATE_FORMAT)] : null
        const wrapperClass = this._renderClassName()

        return (
            <div className={wrapperClass} style={style}>
                <div className=' align-items-baseline'>
                    {
                        heading &&
                        <div className='Heading mr-3 mb-1'>{heading}</div>
                    }

                    <RangePicker
                        dropdownClassName={classDrop}
                        ranges={{
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                        }}
                        showTime={showTime ? {
                            hideDisabledOptions: true,
                            format: 'HH:mm',
                            defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
                        } : false}
                        value={rangeValue}
                        format={DATE_FORMAT}
                        onChange={this._handleDateChange}
                        disabled={loading}
                        locale={mergeDeep(defaultLocale, locale)}
                        style={rangePickerStyle}
                    />
                </div>
            </div>
        )
    }
}

FilterDateCustomFormat.defaultProps = {
    showTime: false
}

FilterDateCustomFormat.propTypes = {
    heading: PropTypes.string,
    className: PropTypes.string,
    field: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onDatesChange: PropTypes.func.isRequired,
    showTime: PropTypes.bool,
    dateFormat: PropTypes.string
}

export default FilterDateCustomFormat
