import React, { useState } from 'react'
import 'antd/lib/select/style/index.css'
import { Button, Modal, Spin, Popover, Tabs, Table } from 'antd'
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons'
import { importIssueShipment } from '../../../services/api/PackageIssuesServices'
import FileTemplate from './FileTemplate'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const ModalImportIssue = (props) => {
	const { isOpenImport, onCloseImport, listSuppliers, shipmentIssueTypes } = props
	const [fileName, setFileName] = useState('')
	const [loadingFile, setLoadingFile] = useState(false)
	const [errorFile, setErrorFile] = useState('')
	const [responseFile, setResponseFile] = useState({})

	const renderSupplier = (supId) => {
		if (!listSuppliers || listSuppliers.length === 0) return
		const supplierObj = listSuppliers.find(i => i.value === supId) || {}
		return !!supplierObj.name ? supplierObj.name : supId
	}

	const renderIssueType = (typeId) => {
		if (!shipmentIssueTypes || shipmentIssueTypes.length === 0) return
		const issueTypeObj = shipmentIssueTypes.find(i => i.value === typeId) || {}
		return !!issueTypeObj.name ? issueTypeObj.name : typeId
	}

	const columns = [
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Package name <span className='text-danger'>*</span></span>,
			dataIndex: 'package_name',
			key: 'package_name',
			render: (_, r) => <div style={{ whiteSpace: 'nowrap' }}>{r.package_name}</div>
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Store <span className='text-danger'>*</span></span>,
			dataIndex: 'namespace',
			key: 'namespace',
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Supplier <span className='text-danger'>*</span></span>,
			dataIndex: 'supplier',
			key: 'supplier',
			render: (_, r) => !!r.supplier ? renderSupplier(r.supplier) : ''
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Tracking number <span className='text-danger'>*</span></span>,
			dataIndex: 'tracking_number',
			key: 'tracking_number',
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Shipping carrier <span className='text-danger'>*</span></span>,
			dataIndex: 'shipping_carrier',
			key: 'shipping_carrier',
			render: (_, r) => {
				const { shipment_info = {} } = Object.assign({}, r)
				return shipment_info.shipping_carrier ? shipment_info.shipping_carrier : ''
			}
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Original carrier <span className='text-danger'>*</span></span>,
			dataIndex: 'original_carrier',
			key: 'original_carrier',
			render: (_, r) => {
				const { shipment_info = {} } = Object.assign({}, r)
				return shipment_info.origin_carrier ? shipment_info.origin_carrier : ''
			}
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Type <span className='text-danger'>*</span></span>,
			dataIndex: 'type',
			key: 'type',
			render: (_, r) => !!r.type ? renderIssueType(r.type) : ''
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Solution <span className='text-danger'>*</span></span>,
			dataIndex: 'solution',
			key: 'solution',
		},
		{
			title: "FFM's note",
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: "FFM's proof",
			dataIndex: 'ffm_proof',
			key: 'ffm_proof',
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Partner's confirm <span className='text-danger'>*</span></span>,
			dataIndex: 'factory_info.confirm',
			key: 'factory_info.confirm',
		},
		{
			title: "Partner's note",
			dataIndex: 'factory_info.note',
			key: 'factory_info.note',
		},
	]

	const columnsError = [
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Package name <span className='text-danger'>*</span></span>,
			dataIndex: 'package_name',
			key: 'package_name',
			render: (_, r) => <div style={{ whiteSpace: 'nowrap' }}>{r.package_name}</div>
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Store <span className='text-danger'>*</span></span>,
			dataIndex: 'namespace',
			key: 'namespace',
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Supplier <span className='text-danger'>*</span></span>,
			dataIndex: 'supplier',
			key: 'supplier',
			render: (_, r) => !!r.supplier ? renderSupplier(r.supplier) : ''
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Tracking number <span className='text-danger'>*</span></span>,
			dataIndex: 'tracking_number',
			key: 'tracking_number',
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Shipping carrier <span className='text-danger'>*</span></span>,
			dataIndex: 'shipping_carrier',
			key: 'shipping_carrier',
			render: (_, r) => {
				const { shipment_info = {} } = Object.assign({}, r)
				return shipment_info.shipping_carrier ? shipment_info.shipping_carrier : ''
			}
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Original carrier <span className='text-danger'>*</span></span>,
			dataIndex: 'original_carrier',
			key: 'original_carrier',
			render: (_, r) => {
				const { shipment_info = {} } = Object.assign({}, r)
				return shipment_info.origin_carrier ? shipment_info.origin_carrier : ''
			}
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Type <span className='text-danger'>*</span></span>,
			dataIndex: 'type',
			key: 'type',
			render: (_, r) => !!r.type ? renderIssueType(r.type) : ''
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Solution <span className='text-danger'>*</span></span>,
			dataIndex: 'solution',
			key: 'solution',
		},
		{
			title: "FFM's note",
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: "FFM's proof",
			dataIndex: 'ffm_proof',
			key: 'ffm_proof',
		},
		{
			title: <span style={{ whiteSpace: 'nowrap' }}>Partner's confirm <span className='text-danger'>*</span></span>,
			dataIndex: 'factory_info.confirm',
			key: 'factory_info.confirm',
		},
		{
			title: "Partner's note",
			dataIndex: 'factory_info.note',
			key: 'factory_info.note',
		},
		{
			title: <span className='text-danger font-weight-bold'>Message</span>,
			dataIndex: 'error',
			key: 'error',
			render: (_, r) => (
				<div className="text-center">
					<Popover content={<span className='text-danger'>{r.error}</span>} title="Message" trigger="hover" placement='topRight'>
						<ExclamationCircleOutlined style={{ cursor: 'pointer', color: 'red' }} />
					</Popover>
				</div>
			)
		},
	]

	const { successRows = [], errorRows = [], noUpdateRows = [] } = Object.assign({}, responseFile)
	const isShowTab = !!successRows.length || !!errorRows.length || !!noUpdateRows.length

	const onUpdateImport = async () => {
		setLoadingFile(true)
		setErrorFile('')
		try {
			const formData = new FormData()
			formData.append('file', fileName[0])

			const { data, message: mess, success } = await importIssueShipment(formData)
			if (!success) return setErrorFile(mess)

			setResponseFile(data)

		} catch (error) {
			setErrorFile(error.message)
		} finally {
			setLoadingFile(false)
		}
	}

	const onChangeFile = (e) => {
		e.target.value = ''
	}

	const handleChangeFile = (e) => {
		setErrorFile('')
		setResponseFile({})

		const { files } = e.target
		if (files[0].size > 5242880) {
			setErrorFile('File size is lager than 5M')
		} else {
			setErrorFile('')
			setFileName(files)
		}
	}


	const onDeleteFile = () => {
		setLoadingFile(false)
		setFileName('')
		setErrorFile('')
		setResponseFile({})
	}

	return (
		<Spin spinning={loadingFile} tip='Loading...' size='large' >
			<Modal
				width={isShowTab ? 1700 : 900}
				className="ImportModal"
				visible={isOpenImport}
				title={'Import Issue Shipment'}
				onOk={onUpdateImport}
				onCancel={() => onCloseImport(!!successRows.length)}
				footer={[
					<Button key="back" onClick={() => onCloseImport(!!successRows.length)}>
						<i className="fa fa-times mr-2" />
						Close
					</Button>,

					<Button id="doImport" key="submit" type="primary" className={loadingFile ? 'loading' : ''}
						disabled={!fileName || isShowTab} loading={loadingFile}
						onClick={onUpdateImport}>
						<i className="fa fa-upload mr-2" />
						Import
					</Button>,
				]}
			>
				{
					errorFile &&
					<span className="noti-error mb-0"><WarningOutlined /> {errorFile}</span>
				}

				<div className="ImportIssueShipment">
					<div className='Upload'>
						<div className='d-flex align-items-center'>
							<FileTemplate />
						</div>
					</div>
					<div className="AttachFile">
						<input
							id={`import_select_file`}
							type="file"
							accept=".csv"
							multiple={false}
							onChange={handleChangeFile}
							onClick={onChangeFile}
							disabled={loadingFile}
						/>
						<label htmlFor={`import_select_file`} id={`import_select_file`}
							className="d-block mb-0 cursor-pointer">
							<i className="fa fa-cloud-upload" /> Attach file
						</label>
					</div>

					<div className="ListFileInput">
						{
							!!fileName && !!fileName[0] && !!fileName[0].name && fileName[0].name !== '' &&
							<div className="file-item">
								<div className="Left">
									<span className="FileName">{fileName[0].name}</span>
								</div>

								<DeleteOutlined onClick={onDeleteFile} />
							</div>
						}
					</div>

					{
						isShowTab &&
						<div className="ListRows">
							<Tabs defaultActiveKey="1">
								<Tabs.TabPane tab={<span className='font-weight-bold'>Success ({successRows.length || 0})</span>} key="1">
									<Table
										dataSource={successRows}
										columns={columns}
										pagination={{
											defaultPageSize: 10,
											showSizeChanger: true,
											pageSizeOptions: ['10', '20', '50'],
										}}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab={<span className='font-weight-bold'>Failed ({errorRows.length || 0})</span>} key="2">
									<Table
										dataSource={errorRows}
										columns={columnsError}
										pagination={{
											defaultPageSize: 10,
											showSizeChanger: true,
											pageSizeOptions: ['10', '20', '50'],
										}}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab={<span className='font-weight-bold'>Not updated ({noUpdateRows.length || 0})</span>} key="3">
									<Table
										dataSource={noUpdateRows}
										columns={columns}
										pagination={{
											defaultPageSize: 10,
											showSizeChanger: true,
											pageSizeOptions: ['10', '20', '50'],
										}}
									/>
								</Tabs.TabPane>
							</Tabs>
						</div>
					}
				</div>
			</Modal>
		</Spin>
	)
}

export default ModalImportIssue