import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

class StatusColumn extends Component {
    _mapStatus = () => {
        const {status} = this.props
        if (!status) {
            return null
        }

        let output = ""
        switch (status) {
            case "on_hold":
                output = <span className="badge badge-info">{status}</span>
                break
            case "cancelled":
            case "error":
                output = <span className="badge badge-danger">{status}</span>
                break
            case "completed":
            case "pushed":
                output = <span className="badge badge-success">{status}</span>
                break
            case "picked_up":
                output = <span className="badge badge-warning">{status}</span>
                break
            case "pending":
            case "shipping":
                output = <span className="badge badge-info pending-animation">{status}</span>
                break
            case "in_production":
            case "in_transit":
                output = <span className="badge badge-primary processing-animation">{status}</span>
                break
            case "packaged":
            case "delivered":
                output = <span className="badge badge-primary">{status}</span>
                break
            default:
                output = <span className="badge badge-secondary">{status}</span>
        }

        return output
    }

    render() {
        const status = this._mapStatus()

        return (
            <Fragment>
                {status}
            </Fragment>
        )
    }
}

StatusColumn.propTypes = {
    status: PropTypes.string.isRequired,
}

export default StatusColumn
