import React, { useState } from 'react'
import { Checkbox } from 'antd'
import BulkMarkIssueReplaced from '../BulkActions/BulkMarkIssueReplaced'
import BulkMarkIssueDone from '../BulkActions/BulkMarkIssueDone'
import BulkMarkIssueRefunded from '../BulkActions/BulkMarkIssueRefunded'
import BulkMarkIssueApprove from '../BulkActions/BulkMarkIssueApprove'
import BulkMarkIssuePartnerRefunded from '../BulkActions/BulkMarkIssuePartnerRefunded'

function PackageIssuesHeader(props) {
    const { permissionsData = [], refs, listSelecteds = [], selectedAll, handleChangeSelectAll, listData, refresh, onChangeLoad, ProductionIssueTypes, ShipmentIssueTypes, issueCategory} = { ...props }
    const { col1Ref, col2Ref, col3Ref } = refs
    const canApprove = permissionsData.includes('ffm_approve_issue')
    const canEdit = permissionsData.includes('ffm_upsert_issue')
    const canDelete = permissionsData.includes('ffm_delete_issue')
    const canViewDetails = permissionsData.includes('ffm_issue_list')
    const canMarkDone = permissionsData.includes('ffm_mark_issue_done')
    const canMarkReplaced = permissionsData.includes('ffm_mark_issue_replaced')
    const canMarkRefunded = permissionsData.includes('ffm_mark_issue_refunded')
    const canMarkPartnerRefunded = permissionsData.includes('ffm_mark_issue_partner_refunded')
    const BulkActionsCheck = canMarkDone || canMarkReplaced || canMarkRefunded || canMarkPartnerRefunded

    const [showAction, setShowAction] = useState(false)
    const [isOpenDone, setIsOpenDone] = useState(false)
    const [isOpenReplaced, setIsOpenReplaced] = useState(false)
    const [isOpenRefunded, setIsOpenRefunded] = useState(false)
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [isOpenPartnerRefunded, setIsOpenPartnerRefunded] = useState(false)

    const _toggleAction = () => {
        setShowAction(!showAction)
    }

    const _handleShowModal = (field) => {
        if (field === 'done') setIsOpenDone(true)
        if (field === 'replaced') setIsOpenReplaced(true)
        if (field === 'refunded') setIsOpenRefunded(true)
        if (field === 'approve') setIsOpenApprove(true)
        if (field === 'partner_refunded') setIsOpenPartnerRefunded(true)
    }

    const _handleUnShowModal = (field) => {
        if (field === 'done') setIsOpenDone(false)
        if (field === 'replaced') setIsOpenReplaced(false)
        if (field === 'refunded') setIsOpenRefunded(false)
        if (field === 'approve') setIsOpenApprove(false)
        if (field === 'partner_refunded') setIsOpenPartnerRefunded(false)
        handleChangeSelectAll(false)
        setShowAction(false)
    }

    const col2Left = col1Ref && col1Ref.current ? col1Ref.current.offsetWidth : 135
    const col3Left = col1Ref && col1Ref.current && col2Ref && col2Ref.current ? col1Ref.current.offsetWidth + col2Ref.current.offsetWidth : 301

    return (
        <thead className='PackageIssuesHeader thead-light'>
            <tr>
                {BulkActionsCheck &&
                    <th className="CheckBox fixed-column0" style={{ minWidth: '70px', left: 0 }}>
                        <div className="BulkActions">
                            <Checkbox checked={selectedAll} onChange={(e) => handleChangeSelectAll(e.target.checked)} />
                            <div className="Actions">
                                <i className="ti-angle-down" onClick={_toggleAction} />
                                <ul className={`ListActions ${showAction ? 'd-block' : ''} ${listSelecteds.length > 0 && BulkActionsCheck ? '' : 'Disable'}`}>
                                    {canMarkDone &&
                                        <li className="ActionItem Disable" onClick={() => _handleShowModal('done')}>Mark issues as done</li>
                                    }
                                    {canMarkReplaced &&
                                        <li className="ActionItem" onClick={() => _handleShowModal('replaced')}>Mark issues as replaced</li>
                                    }
                                    {canMarkRefunded &&
                                        <li className="ActionItem" onClick={() => _handleShowModal('refunded')}>Mark issues as refunded</li>
                                    }
                                    
                                    <li className={`ActionItem ${(!issueCategory || issueCategory === "shipment") && 'disableBulk'}`} onClick={() => _handleShowModal('approve')}>Mark issues as approve</li>

                                    {canMarkPartnerRefunded &&
                                        <li className="ActionItem" onClick={() => _handleShowModal('partner_refunded')}>Mark issues as partner refunded</li>
                                    }
                                </ul>
                                {
                                    showAction && <div className="Background" onClick={_toggleAction} />
                                }
                            </div>
                        </div>
                    </th>
                }
                <th className='fixed-column1' style={{ minWidth: '135px', left: (BulkActionsCheck ? 70 : 0) }}>
                    Package name
                </th>
                <th className='fixed-column2' style={{ minWidth: '166px', left: (BulkActionsCheck ? col2Left + 70 : col2Left) }}>Reference number</th>
                <th className='fixed-column3' style={{ minWidth: '127px', left: (BulkActionsCheck ? col3Left + 70 : col3Left) }}>Product Type</th>
                <th style={{ minWidth: '136px' }}>Issue detail</th>
                <th>Note</th>
                <th className='text-center'>Solution</th>
                <th className='text-center'>Status</th>
                <th style={{ minWidth: '186px' }}>Creator - Approver</th>
                <th style={{ minWidth: '165px' }} className='text-center'>Partner's confirm</th>
                <th style={{ minWidth: '140px' }}>Partner's note</th>
                {
                    (canApprove || canEdit || canViewDetails || canDelete) &&
                    <th className='fixed-column-right' style={{ minWidth: '68px' }}>Action</th>
                }
            </tr>
            {isOpenDone &&
                <BulkMarkIssueDone
                    handleUnShowModal={_handleUnShowModal}
                    listSelecteds={listSelecteds}
                    isOpen={isOpenDone}
                    listData={listData}
                    refresh={refresh}
                    onChangeLoad={onChangeLoad}
                />
            }
            {isOpenReplaced &&
                <BulkMarkIssueReplaced
                    handleUnShowModal={_handleUnShowModal}
                    listSelecteds={listSelecteds}
                    isOpen={isOpenReplaced}
                    listData={listData}
                    refresh={refresh}
                    onChangeLoad={onChangeLoad}
                />
            }
            {isOpenRefunded &&
                <BulkMarkIssueRefunded
                    handleUnShowModal={_handleUnShowModal}
                    listSelecteds={listSelecteds}
                    isOpen={isOpenRefunded}
                    listData={listData}
                    refresh={refresh}
                    onChangeLoad={onChangeLoad}
                />
            }

            {isOpenPartnerRefunded &&
                <BulkMarkIssuePartnerRefunded
                    handleUnShowModal={_handleUnShowModal}
                    listSelecteds={listSelecteds}
                    isOpen={isOpenPartnerRefunded}
                    listData={listData}
                    refresh={refresh}
                    onChangeLoad={onChangeLoad}
                />
            }

            {
                isOpenApprove && 
                <BulkMarkIssueApprove
                    handleUnShowModal={_handleUnShowModal}
                    listSelecteds={listSelecteds}
                    isOpen={isOpenApprove}
                    listData={listData}
                    refresh={refresh}
                    onChangeLoad={onChangeLoad}
                    ProductionIssueTypes={ProductionIssueTypes}
                    ShipmentIssueTypes={ShipmentIssueTypes}
                    issueCategory={issueCategory}
                />
            }
        </thead>
    )
}

export default PackageIssuesHeader