import React, {useState} from 'react'
import {Image, Typography, Popover, Button, Row, Col, InputNumber} from 'antd'
import {EditOutlined} from '@ant-design/icons'
import {Draggable} from 'react-beautiful-dnd'
import imageDefault from '../../../../helpers/common/imageDefault'
import classnames from "classnames";
import {useOrderFulfillment} from "../store";

const {Title, Link} = Typography

/**
 * @param {Object} props
 * @param {Object} props.orderItem
 * @param {Number} props.index
 * @returns {JSX.Element}
 */
export default (props) => {
    const [visible, setVisible] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const {state, dispatch} = useOrderFulfillment()
    const {pack, orderItem, index} = props
    
    const _handleSplitQuantity = () => {
        if (quantity !== orderItem.quantity) {
            dispatch({type: 'SPLIT_QUANTITY', payload: {pack, orderItem, quantity}})
        }
        setVisible(false)
    }

    const _handleQuantityChange = (value) => {
        setQuantity(value)
    }

    const _handleVisibleChange = visible => {
        setVisible(visible)
    }

    const displayQuantity = () => {
        if (orderItem.quantity <= 1) {
            return <span className="Value">{orderItem.quantity}</span>
        }

        const popoverContent = (
            <div className="split-quantity">
                <InputNumber min={1} max={orderItem.quantity} defaultValue={quantity} onChange={_handleQuantityChange} />
                <div className="split-quantity__footer">
                    <Button size="small" type="primary" onClick={_handleSplitQuantity}>Split</Button>
                    <Button size="small" onClick={() => _handleVisibleChange(false)} disabled={state.isProcessing}>Cancel</Button>
                </div>
            </div>
        )

        return (
            <>
                <span className="Value">{orderItem.quantity}</span>
                {
                    (state.order.order_type === 'TIKTOK' && pack.shippingPlan.slug === 'shipbytiktok') ? <Button
                    icon={<EditOutlined/>}
                    className="split-quantity__button"
                    size="small"
                    type="link"
                    disabled>
                    Split quantity
                </Button> : <Popover
                    content={popoverContent}
                    title="Split quantity"
                    trigger="click"
                    visible={visible}
                    onVisibleChange={_handleVisibleChange}
                >
                    <Button
                        icon={<EditOutlined/>}
                        className="split-quantity__button"
                        size="small"
                        type="link">
                        Split quantity
                    </Button>
                </Popover> 
                }
                
                
            </>
        )
    }

    const orderItemDisplay = () => (
        <div className="split-package__order-item">
            <div className="split-package__order-item-thumbnail">
                <Link href={orderItem.product.url} target="_blank">
                    <Image
                        width={120}
                        height={120}
                        src={orderItem.product.preview}
                        preview={false}
                        fallback={imageDefault}
                    />
                </Link>
            </div>
            <div className="split-package__order-item-info">
                <Row>
                    <Col span={12}>
                        <ul>
                            <li>
                                <Title level={4}>
                                    <Link href={orderItem.product.url} target="_blank">
                                        {orderItem.product.title}
                                    </Link>
                                </Title>
                            </li>
                            <li>
                                <span className="Label">Price:</span>
                                <span className="Value">{orderItem.price}</span>
                            </li>
                            <li>
                                <span className="Label">Variant:</span>
                                <span
                                    className="Value text-break">{orderItem.variant.title}</span>
                            </li>
                            <li>
                                <span className="Label">Quantity:</span>
                                {displayQuantity()}
                            </li>
                        </ul>
                    </Col>
                    <Col span={12}>
                        <ul>
                            <li>
                                <span className="Label">Product line:</span>
                                <span
                                    className="Value">{orderItem.productLineVariant.product.title}</span>
                            </li>
                            <li>
                                <span className="Label">Product line SKU:</span>
                                <span className="Value">{orderItem.productLineVariant.sku}</span>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </div>
    )

    const orderItemDraggable = () => (
        <Draggable key={`${pack._id}::${orderItem._id}`} draggableId={`${pack._id}::${orderItem._id}`} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classnames({
                        'is-dragging': snapshot.isDragging,
                    })}
                >
                    {orderItemDisplay()}
                </div>
            )}
        </Draggable>
    )

    return state.isProcessing ? orderItemDisplay() : orderItemDraggable()
}
